import { useCallback, useEffect, useMemo, useState } from 'react';

import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RefreshIcon from '@mui/icons-material/Refresh';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import { deepOrange, green, orange } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid-pro';
import { formatEther } from 'ethers';
import moment, { Moment } from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CopyableAddress from '~/components/CopyableAddress';
import CustomCardTable from '~/components/custom-card-table';
import ListTable from '~/components/list-table';
import { ITEMS_PER_PAGE, METHODS_CONTRACT } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { BalanceFluctuationQueryKey, BlockchainTxnType, ContextAPIEnum, MethodsContractEnum } from '~/enum/common';
import {
  Address,
  GetTransactionsHistoryQuery,
  NextPageParams,
  Transaction,
  useGetTransactionsHistoryQuery,
} from '~/graphql/block-explorer/types';
import { useAccount } from '~/hooks/with-account';
import { IGridToolbar, IToolbarSelector } from '~/interfaces/common';
import {
  generateQueryString,
  getLocalStorage,
  setLocalStorageItems,
  verifyOrderKey,
  verifySortKey,
} from '~/utils/common';

interface IBalanceFluctuation {
  id: string;
  hash: string;
  type: string;
  block: number;
  status: string;
  timestamp: Date;
  to: Address | undefined | null;
  from: Address | undefined | null;
  createdContract: Address | undefined | null;
  [BalanceFluctuationQueryKey.FEE]: number;
  [BalanceFluctuationQueryKey.VALUE]: number;
}

enum TxnFilter {
  TO = 'to',
  ALL = 'all',
  FROM = 'from',
}

const initQuery = {
  page: 1,
  filter: TxnFilter.ALL,
  limit: ITEMS_PER_PAGE.BLOCKSCOUT,
  orderBy: verifyOrderKey(getLocalStorage('balance_fluctuation_order')),
  sortBy: verifySortKey(BalanceFluctuationQueryKey, getLocalStorage('balance_fluctuation_sort')),
};

const useStyles = makeStyles()(() => ({
  wrapperContent: {
    '.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const getNextPageParams = (data: GetTransactionsHistoryQuery['getTransactionsHistory'] | undefined) => {
  if (!data || typeof data !== 'object' || !('next_page_params' in data)) {
    return;
  }

  return data.next_page_params;
};

const BalanceFluctuation = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { selectedOrganization } = useAccount();
  const { supportedNetworks } = useSupportedNetworks();

  const [rowCount, setRowCount] = useState(0);
  const [updatedDate, setUpdatedDate] = useState<Moment | null>(null);
  const [pageParams, setPageParams] = useState<Record<number, NextPageParams>>({});
  const [balanceFluctuationQuery, setBalanceFluctuationQuery] = useState(initQuery);

  const latestNetwork = Object.values(supportedNetworks)?.[0];

  const queryParams = generateQueryString({
    sort: balanceFluctuationQuery.sortBy,
    fee: pageParams[balanceFluctuationQuery.page]?.fee,
    hash: pageParams[balanceFluctuationQuery.page]?.hash,
    index: pageParams[balanceFluctuationQuery.page]?.index,
    value: pageParams[balanceFluctuationQuery.page]?.value,
    inserted_at: pageParams[balanceFluctuationQuery.page]?.inserted_at,
    items_count: pageParams[balanceFluctuationQuery.page]?.items_count,
    block_number: pageParams[balanceFluctuationQuery.page]?.block_number,
    order: balanceFluctuationQuery.sortBy ? balanceFluctuationQuery.orderBy : undefined,
    filter: balanceFluctuationQuery.filter === TxnFilter.ALL ? undefined : balanceFluctuationQuery.filter,
  });

  const {
    data: transactionsRes,
    loading: loadingTransactions,
    refetch: refetchTransactions,
  } = useGetTransactionsHistoryQuery({
    fetchPolicy: 'cache-and-network',
    skip: !selectedOrganization.masterWalletAddress,
    variables: {
      queryParams,
      where: {
        address: selectedOrganization.masterWalletAddress!,
      },
    },
    context: {
      blockchain: ContextAPIEnum.BlockExplorer,
      blockExplorerUrl: latestNetwork.blockExplorer,
    },
    onCompleted: (data) => {
      const hasNextPage = !!data.getTransactionsHistory.next_page_params;
      const totalPreviousPages = ITEMS_PER_PAGE.BLOCKSCOUT * (pagination.currentPage - 1);
      const currentPageLength = data.getTransactionsHistory.items.length;
      const estimateNextPage = hasNextPage ? ITEMS_PER_PAGE.BLOCKSCOUT : 0;

      const _rowCount = totalPreviousPages + currentPageLength + estimateNextPage;
      setRowCount(_rowCount);
      setUpdatedDate(moment());
    },
  });

  const nextPageParams = getNextPageParams(transactionsRes?.getTransactionsHistory);

  const listStatus = useMemo(
    () => ({
      success: {
        color: 'success',
        label: t('success'),
        icon: <CheckCircleIcon fontSize="small" />,
      },
      pending: {
        color: 'warning',
        label: t('pending'),
        icon: <BlurCircularIcon fontSize="small" />,
      },
      failed: {
        color: 'error',
        label: t('failed'),
        icon: <CancelIcon fontSize="small" />,
      },
    }),
    [t]
  );

  const filterOptions = useMemo(
    () => [
      {
        label: 'All',
        value: TxnFilter.ALL,
      },
      {
        label: 'In',
        value: TxnFilter.TO,
      },
      {
        label: 'Out',
        value: TxnFilter.FROM,
      },
    ],
    []
  );

  const listTypes = useMemo(
    () => ({
      [BlockchainTxnType.Refund]: {
        color: 'warning',
        label: t('settings.refund'),
      },
      [BlockchainTxnType.Charge]: {
        color: 'success',
        label: t('settings.points.charge'),
      },
      [BlockchainTxnType.TokenMint]: {
        color: 'info',
        label: t('settings.points.token_mint'),
      },
      [BlockchainTxnType.Transaction]: {
        color: 'success',
        label: t('settings.points.transaction'),
      },
      [BlockchainTxnType.ContractCall]: {
        color: 'success',
        label: t('settings.points.contract_call'),
      },
      [BlockchainTxnType.TokenTransfer]: {
        color: 'info',
        label: t('settings.points.token_transfer'),
      },
      [BlockchainTxnType.ContractCreation]: {
        color: 'info',
        label: t('settings.points.contract_creation'),
      },
    }),
    [t]
  );

  const classificationType = useCallback(
    (transaction: Transaction) => {
      const _type = transaction.tx_types?.at(-1);
      const method = !!transaction.method
        ? METHODS_CONTRACT[transaction.method as keyof typeof METHODS_CONTRACT]
        : undefined;
      const from = transaction.from?.hash;
      const isOut = from?.toLowerCase() === selectedOrganization.masterWalletAddress?.toLowerCase();
      switch (_type) {
        case BlockchainTxnType.ContractCall:
        case BlockchainTxnType.ContractCreation:
          return _type;
        case BlockchainTxnType.CoinTransfer:
          if (isOut) {
            return BlockchainTxnType.Refund;
          } else {
            return BlockchainTxnType.Charge;
          }
        case BlockchainTxnType.TokenTransfer:
          if (method === MethodsContractEnum.Mint) {
            return BlockchainTxnType.TokenMint;
          } else if (method === MethodsContractEnum.Transfer) {
            return BlockchainTxnType.TokenTransfer;
          }
          return BlockchainTxnType.Transaction;
        default:
          return BlockchainTxnType.Transaction;
      }
    },
    [selectedOrganization.masterWalletAddress]
  );

  const transactions: IBalanceFluctuation[] = useMemo(() => {
    const _transactions = transactionsRes?.getTransactionsHistory?.items;
    return (
      _transactions?.map((transaction) => ({
        id: transaction.hash || '',
        createdContract: transaction.created_contract,
        to: transaction.to,
        from: transaction.from,
        hash: transaction.hash || '',
        block: transaction.block || 0,
        status: transaction.result || '',
        timestamp: transaction.timestamp || '',
        type: classificationType(transaction) || BlockchainTxnType.Transaction,
        [BalanceFluctuationQueryKey.VALUE]: parseFloat(formatEther(transaction.value || '0')),
        [BalanceFluctuationQueryKey.FEE]: parseFloat(formatEther(transaction.fee?.value || '0')),
      })) || []
    );
  }, [transactionsRes?.getTransactionsHistory, classificationType]);

  const pagination = { currentPage: balanceFluctuationQuery.page, itemsPerPage: ITEMS_PER_PAGE.BLOCKSCOUT };

  const updateBalanceFluctuationQuery = (newValue: any) => {
    const changedPage = 'page' in newValue;
    setBalanceFluctuationQuery((prev) => ({
      ...prev,
      page: 1,
      ...newValue,
    }));
    if (changedPage) {
      const newPage = newValue.page;
      if (!!pageParams[newPage] || !nextPageParams || newPage <= 1) return;
      setPageParams((prev) => ({
        ...prev,
        [newPage]: nextPageParams,
      }));
    } else {
      setPageParams({});
    }
  };

  const handleReload = () => {
    refetchTransactions();
  };

  const renderFromTo = useCallback(
    ({ row }: { row: IBalanceFluctuation }) => {
      const from = row.from;
      const to = row.to;
      const createdContract = row.createdContract;

      const nameFrom = from?.name || '';
      const hashFrom = from?.hash || '';
      const nameTo = (!!createdContract ? createdContract.name : to?.name) || '';
      const hashTo = (!!createdContract ? createdContract.hash : to?.hash) || '';
      const isOut = hashFrom.toLowerCase() === selectedOrganization.masterWalletAddress?.toLowerCase();
      const title = isOut ? nameTo || hashTo : nameFrom || hashFrom;
      const hash = isOut ? hashTo : hashFrom;

      const wallets = [
        <Typography key={0} variant="body2">
          {t('organization')}
        </Typography>,
        <CopyableAddress key={1} title={title} href={`${latestNetwork.blockExplorer}/address/${hash}`} />,
      ];
      return (
        <Stack gap="16px" alignItems="center" flexDirection="row">
          <Stack
            width={24}
            height={24}
            borderRadius="50%"
            alignItems="center"
            justifyContent="center"
            sx={{ backgroundColor: isOut ? orange[50] : green[50] }}
          >
            <ArrowUpwardIcon
              fontSize="small"
              sx={{ transform: `rotate(${isOut ? 180 : 0}deg)`, color: isOut ? deepOrange[800] : green[800] }}
            />
          </Stack>
          <Stack>{isOut ? wallets : wallets.reverse()}</Stack>
        </Stack>
      );
    },
    [latestNetwork.blockExplorer, selectedOrganization.masterWalletAddress, t]
  );

  const handleSelect: IToolbarSelector['onChange'] = (e) => {
    updateBalanceFluctuationQuery({ filter: e.target.value });
  };

  const columns: GridColDef<IBalanceFluctuation>[] = useMemo(
    () => [
      {
        width: 150,
        field: 'hash',
        headerName: t('settings.points.transaction_hash'),
        renderCell: ({ value }) => (
          <CopyableAddress title={value} href={`${latestNetwork.blockExplorer}/tx/${value}`} />
        ),
      },
      {
        width: 150,
        field: 'type',
        sortable: false,
        headerName: t('type'),
        renderCell: ({ value, row }) => {
          const status = row.status;
          const currentType = listTypes[value as keyof typeof listTypes];
          const currentStatus = listStatus[status as keyof typeof listStatus] || listStatus.failed;
          return (
            <Stack alignItems="flex-start" gap="8px">
              <Chip label={currentType.label} color={currentType.color as 'success'} size="medium" />
              <Chip
                size="small"
                icon={currentStatus?.icon}
                label={currentStatus?.label}
                color={currentStatus?.color as 'success'}
              />
            </Stack>
          );
        },
      },
      {
        width: 130,
        field: 'block',
        sortable: false,
        headerName: t('settings.points.block'),
        renderCell: ({ value }) => (
          <Link target="_blank" href={`${latestNetwork.blockExplorer}/block/${value}`}>
            {value}
          </Link>
        ),
      },
      {
        width: 200,
        field: 'from',
        sortable: false,
        headerName: `${t('from')}/${t('to')}`,
        renderCell: renderFromTo,
        valueFormatter: ({ value }) => value?.createdContract?.name || value?.name || value?.hash,
      },
      {
        width: 130,
        align: 'right',
        headerAlign: 'right',
        field: BalanceFluctuationQueryKey.VALUE,
        headerName: t('settings.points.joc_points'),
        valueFormatter: ({ value }) => parseFloat(value.toFixed(4)),
      },
      {
        width: 130,
        align: 'right',
        headerName: t('fee'),
        headerAlign: 'right',
        field: BalanceFluctuationQueryKey.FEE,
        valueFormatter: ({ value }) => parseFloat(value.toFixed(4)),
      },
      {
        width: 150,
        sortable: false,
        field: 'timestamp',
        headerName: t('timestamp'),
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_time_format')) : '-'),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  useEffect(() => {
    setLocalStorageItems({
      balance_fluctuation_sort: balanceFluctuationQuery?.sortBy,
      balance_fluctuation_order: balanceFluctuationQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [balanceFluctuationQuery?.orderBy, balanceFluctuationQuery?.sortBy]);

  const selector: IGridToolbar['selector'] = {
    label: '',
    options: filterOptions,
    value: balanceFluctuationQuery.filter,
    onChange: handleSelect,
  };

  return (
    <CustomCardTable
      cardTitle={t('settings.points.balance_fluctuation')}
      headerAction={
        <Box display="flex" alignItems="center">
          <Typography marginRight="8px">
            {t('my_shop.last_published_date')}:{' '}
            {updatedDate ? moment(updatedDate).format(t('date_time_format')) : '...'}
          </Typography>
          <IconButton size="medium" onClick={handleReload}>
            <RefreshIcon fontSize="inherit" />
          </IconButton>
        </Box>
      }
      cardContent={
        <Box className={classes.wrapperContent}>
          <ListTable
            noBorder
            rowHeight={89}
            toggleableSort
            columns={columns}
            rowCount={rowCount}
            rows={transactions}
            sortingMode="client"
            tableName="balanceFluctuation"
            isLoading={loadingTransactions}
            pageSizeOptions={[ITEMS_PER_PAGE.BLOCKSCOUT]}
            csvOptions={{
              fileName: t('settings.points.balance_fluctuation'),
            }}
            actionsToolbar={{
              selector,
            }}
            sort={{
              sortBy: balanceFluctuationQuery.sortBy,
              orderBy: balanceFluctuationQuery.orderBy,
            }}
            paginationData={pagination}
            onSort={updateBalanceFluctuationQuery}
            onPagination={updateBalanceFluctuationQuery}
          />
        </Box>
      }
    />
  );
};

export default BalanceFluctuation;
