import { SyntheticEvent, useState, useEffect, useMemo } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import Billing from './billing';
import ListTOrganizationUsers from './list-organization-users';
import Operation from './Operation';
import PaymentTab from './payment-tab';
import PlanManagement from './plan-management';
import Points from './points';

import UserLayout from '~/components/app-layout/user-layout';
import HomeBtn from '~/components/home-btn';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { useAccount } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';

const { GENERAL, POINTS, PAYMENT, MEMBER, PLAN_MANAGEMENT } = SCREEN_PERMISSION.SETTING;

const useStyles = makeStyles()(() => ({}));

interface Props extends StyledComponentProps<typeof useStyles> {}

interface TabPanelProps {
  children: JSX.Element;
  value: number;
  index: number;
  style?: React.CSSProperties;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} {...other}>
      {value === index && <Box>{children}</Box>}
    </div>
  );
};

const initSettingTabsList = (t: TFunction) => {
  return [
    {
      label: t('settings.general'),
      component: <Operation />,
      roles: GENERAL.VIEW,
    },
    {
      label: t('settings.billing.title'),
      component: <Billing />,
      roles: POINTS.VIEW,
    },
    {
      label: t('settings.points.title'),
      component: <Points />,
      roles: POINTS.VIEW,
    },
    {
      label: t('settings.payment'),
      component: <PaymentTab />,
      roles: PAYMENT.VIEW,
    },
    {
      label: t('settings.plan_management.title'),
      component: <PlanManagement />,
      roles: PLAN_MANAGEMENT.VIEW,
    },
    {
      label: t('settings.member'),
      component: <ListTOrganizationUsers />,
      roles: MEMBER.VIEW,
    },
  ];
};

const SettingPage: React.FC<Props> = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { selectedOrganization } = useAccount();

  const [value, setValue] = useState(0);

  const handleChange = (_: SyntheticEvent<Element, Event>, newValue: number) => {
    setValue(newValue);
    navigate(`${location.pathname}?tab=${newValue}`);
  };

  const settingTabsList = useMemo(
    () => initSettingTabsList(t).filter((tab) => tab.roles.includes(selectedOrganization.role)),
    [t, selectedOrganization]
  );

  useEffect(() => {
    document.title = t('setting');
  }, [t]);

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const tabFromUrl = urlParams.get('tab');
    if (tabFromUrl) {
      const newTab = Number(tabFromUrl);
      const lengthSettingTabsList = settingTabsList?.length - 1;
      if (lengthSettingTabsList >= newTab) {
        setValue(newTab);
      } else {
        navigate(`${location.pathname}?tab=0`);
      }
    }
  }, [location.search, location.pathname, settingTabsList?.length, navigate]);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.primary">{t('settings.organization_settings')}</Typography>
      </Breadcrumbs>
      <Box sx={{ width: '100%', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs variant="scrollable" value={value} onChange={handleChange}>
          {settingTabsList.map((tab, idx) => (
            <Tab key={idx} label={tab.label} />
          ))}
        </Tabs>
      </Box>
      {settingTabsList.map((tab, idx) => (
        <TabPanel key={idx} style={{ width: '100%' }} value={value} index={idx}>
          {tab.component}
        </TabPanel>
      ))}
    </UserLayout>
  );
};

export default SettingPage;
