import { useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import AddIcon from '@mui/icons-material/Add';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef } from '@mui/x-data-grid';
import { JsonRpcProvider } from 'ethers';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CreateCollectionDialog from '~/components/create-collection-dialog';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { GalleryData } from '~/components/gallery-list';
import HomeBtn from '~/components/home-btn';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import PermissionsView from '~/components/PermissionsView';
import SquareImage from '~/components/SquareImage';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { API_MEDIA, ITEMS_PER_PAGE } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { VIEW_MODE } from '~/enum/common';
import {
  Collection,
  CollectionQueryKey,
  ListCollectionsDocument,
  useListCollectionsQuery,
  useRemoveCollectionMutation,
} from '~/graphql/member/types';
import { useCheckPermissions } from '~/hooks/with-account';
import { COLLECTION_TYPE, SalesMethodEnum } from '~/types/my-shop';
import { CancelPromiseResponse, cancelPromise } from '~/utils/cancelPromise';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';
import getAddressLink from '~/utils/getAddressLink';
import { truncateEthAddress } from '~/utils/string.utils';

const { COLLECTIONS } = SCREEN_PERMISSION;

export type CollectionData = {
  id: string;
  url?: string;
  name: string;
  symbol: string;
  status?: string;
  network: string;
  createdAt?: string;
  ownerAddress?: string;
  contractAddress: string;
  salesMethod?: SalesMethodEnum;

  //new key
  [CollectionQueryKey.Name]?: string;
  [CollectionQueryKey.Symbol]?: string;
  [CollectionQueryKey.Network]?: string;
  [CollectionQueryKey.CreatedAt]?: string;
  [CollectionQueryKey.OwnerAddress]?: string;
  [CollectionQueryKey.ContractAddress]?: string;
};

const useStyles = makeStyles()(() => ({
  content: {
    minHeight: 0,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: '60px',
    '& > .MuiTypography-h5': {
      fontSize: '24px',
      fontWeight: 400,
      marginBottom: '16px',
    },
    '& > div': {
      minHeight: 0,
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

export const typesList = (t: TFunction) => ({
  [COLLECTION_TYPE.MEMBERSHIP]: {
    label: t('collection_screen.membership_type'),
    description: t('collection_screen.membership_description'),
    value: COLLECTION_TYPE.MEMBERSHIP,
  },
  [COLLECTION_TYPE.PROMOTION]: {
    label: t('collection_screen.promotion_type'),
    description: t('collection_screen.promotion_description'),
    value: COLLECTION_TYPE.PROMOTION,
  },
  [COLLECTION_TYPE.DIGITAL_COLLECTIVE]: {
    label: t('collection_screen.digital_collective_type'),
    description: t('collection_screen.digital_description'),
    value: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
  },
});

let getCollectionsPromise: CancelPromiseResponse;

const clearState = () => {
  window.history.replaceState(null, '');
};

const CollectionsPage: React.FC = () => {
  const supportedNetworksContext = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;
  const supportedNetworks = supportedNetworksContext?.supportedNetworks;

  const navigate = useNavigate();
  const { classes } = useStyles();
  const location = useLocation();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [rows, setRows] = useState<CollectionData[]>([]);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [selectedItem, setSelectedItem] = useState<CollectionData>();
  const [isLoadingCollections, setIsLoadingCollection] = useState(true);

  const [openRemoveCollectionDialog, setOpenRemoveCollectionDialog] = useState(false);
  const [openCreateCollectionDialog, setOpenCreateCollectionDialog] = useState(false);

  const [removeCollection] = useRemoveCollectionMutation({
    refetchQueries: [ListCollectionsDocument],
    onCompleted: () => updateCollectionsQuery({ page: 1 }),
  });

  const [collectionsQuery, setCollectionsQuery] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('collection_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage('collection_list_order')),
  });

  const updateCollectionsQuery = (newValue: any) => setCollectionsQuery((value: any) => ({ ...value, ...newValue }));

  useEffect(() => {
    setLocalStorageItems({
      collection_list_sort: collectionsQuery?.sortBy,
      collection_list_order: collectionsQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsQuery?.orderBy, collectionsQuery?.sortBy]);

  useEffect(() => {
    if (location.state) {
      setOpenCreateCollectionDialog(true);
    }
  }, [location.state]);

  const { data: dataListCollections, loading } = useListCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    variables: collectionsQuery,
  });

  const { items, pagination } = useMemo(() => {
    const items = dataListCollections?.listCollections?.items || [];
    const pagination: ListTablePagination = dataListCollections?.listCollections?.pagination || {};
    return { items, pagination };
  }, [dataListCollections]);

  const onOpenCreateCollectionDialog = useCallback(() => {
    setOpenCreateCollectionDialog(true);
  }, []);

  const onCloseCreateCollectionDialog = (params?: { newCollection?: Collection }) => {
    setOpenCreateCollectionDialog(false);
    if (params?.newCollection) {
      navigate(AppRouteEnum.CollectionDetail.replace(/:collectionId/g, params.newCollection.uuid));
    }
  };

  const handleRedirectToDetail = useCallback(
    (id: string) => {
      navigate(AppRouteEnum.CollectionDetail.replace(/:collectionId/g, id || ''));
    },
    [navigate]
  );

  const columns: GridColDef<CollectionData>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage src={value} onClick={() => handleRedirectToDetail(row.id)} />
            </Box>
          );
        },
      },
      {
        field: CollectionQueryKey.Name,
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: CollectionQueryKey.Symbol,
        headerName: t('symbol'),
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        field: CollectionQueryKey.OwnerAddress,
        getApplyQuickFilterFn: undefined,
        headerName: t('owner_address'),
        width: JSON.parse(columnsSize).ownerAddress || 150,
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            {truncateEthAddress(row.ownerAddress!)}
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        field: CollectionQueryKey.ContractAddress,
        getApplyQuickFilterFn: undefined,
        headerName: t('contract_address'),
        width: JSON.parse(columnsSize).contractAddress || 150,
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            <Link
              target="_blank"
              href={getAddressLink(supportedNetworks?.[row.network]?.blockExplorer, formattedValue)}
            >
              {truncateEthAddress(formattedValue)}
            </Link>
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        field: CollectionQueryKey.Network,
        headerName: t('network'),
        width: JSON.parse(columnsSize).network || 150,
        valueGetter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: CollectionQueryKey.CreatedAt,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: (params) => {
          return [
            <IconButton onClick={handleClickMoreIcon(params.row)} style={{ display: 'flex', alignItems: 'center' }}>
              <MoreVertIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
            </IconButton>,
          ];
        },
      },
    ];
  }, [supportedNetworks, t, handleRedirectToDetail]);

  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const handleClickGalleryCard = (item: GalleryData) => {
    navigate(AppRouteEnum.Collection + `/${item?.id}`);
  };

  const handleClickMoreIcon = (collection: CollectionData) => (e: any) => {
    setSelectedItem(collection);
    setMenuAnchorEl(e.currentTarget);
  };

  const redirectToDetail = () => {
    navigate(AppRouteEnum.CollectionDetail.replace(/:collectionId/g, selectedItem?.id || ''));
    onCloseMenu();
  };

  const onOpenRemoveCollectionDialog = () => {
    setMenuAnchorEl(undefined);
    setOpenRemoveCollectionDialog(true);
  };

  const onCloseRemoveCollectionDialog = () => {
    setOpenRemoveCollectionDialog(false);
  };

  const onConfirmRemoveCollection = async () => {
    try {
      await removeCollection({ variables: { collectionUuid: selectedItem?.id || '' } });

      enqueueSnackbar(t('toast_message.deleted_successfully'), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    document.title = t('collections');
  }, [t]);

  const setDataGridRows = useCallback(async () => {
    try {
      if (getCollectionsPromise?.cancelCallback) {
        getCollectionsPromise.cancelCallback();
      }
      getCollectionsPromise = cancelPromise(async () => {
        try {
          setIsLoadingCollection(true);
          let newCollections: CollectionData[] = [];
          if (items && !loading) {
            newCollections = await Promise.all(
              items.map(async ({ uuid, name, symbol, images, network, createdAt, contractAddress }) => {
                const provider = new JsonRpcProvider(supportedNetworks?.[network]?.rpcUrl);
                const contractNotSigner = ERC721G__factory.connect(contractAddress, provider as any);
                const ownerAddress = await contractNotSigner.owner();

                return {
                  name,
                  symbol,
                  network,
                  id: uuid,
                  createdAt,
                  ownerAddress,
                  contractAddress,
                  url: API_MEDIA + (images?.[0] || ''),

                  //new key
                  [CollectionQueryKey.Name]: name,
                  [CollectionQueryKey.Symbol]: symbol,
                  [CollectionQueryKey.CreatedAt]: createdAt,
                  [CollectionQueryKey.OwnerAddress]: ownerAddress || undefined,
                  [CollectionQueryKey.ContractAddress]: contractAddress,
                  [CollectionQueryKey.Network]: network,
                };
              })
            );
            setIsLoadingCollection(false);
          }
          return newCollections;
        } catch (err: any) {
          enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
          return [];
        }
      });
      const newRows = await getCollectionsPromise.awaitCallback;
      setRows(newRows as CollectionData[]);
    } catch (err: any) {}
  }, [items, loading, supportedNetworks, enqueueSnackbar, t]);

  useEffect(() => {
    setDataGridRows();
  }, [setDataGridRows]);

  useEffect(() => {
    window.addEventListener('beforeunload', clearState);
    return () => {
      window.removeEventListener('beforeunload', clearState);
    };
  }, []);

  const loadingData = loading || isLoadingCollections;

  const [isCanViewBtnAdd] = useCheckPermissions([COLLECTIONS.ADD]);

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.secondary"> {t('collections')}</Typography>
      </Breadcrumbs>
      <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
        <MenuItem onClick={redirectToDetail}>{t('show_details')}</MenuItem>
        <PermissionsView roles={COLLECTIONS.DELETE}>
          <MenuItem onClick={onOpenRemoveCollectionDialog}>{t('delete')}</MenuItem>
        </PermissionsView>
      </Menu>
      <ConfirmationDialog
        open={openRemoveCollectionDialog}
        content={t('my_shop.message.confirm_delete_collection')}
        title={t('collection_screen.delete_collection')}
        onConfirm={onConfirmRemoveCollection}
        onClose={onCloseRemoveCollectionDialog}
      />
      <Box className={classes.content}>
        <CustomCardTable
          cardTitle={t('collections')}
          headerAction={
            <PermissionsView roles={COLLECTIONS.ADD}>
              <HeaderAction
                menus={[{ title: t('create_collection_dialog.title'), onClick: onOpenCreateCollectionDialog }]}
              />
            </PermissionsView>
          }
          cardContent={
            <ListTable
              rows={rows}
              columns={columns}
              noDataProps={{
                title: t('create_new_collection'),
                buttonTitle: t('create_collection_dialog.title'),
                description: t('no_data.collection'),
                onClick: isCanViewBtnAdd ? onOpenCreateCollectionDialog : undefined,
              }}
              isMenu
              noBorder
              isCollectionScreen
              tableName="collections"
              isLoading={loadingData}
              onlyMode={VIEW_MODE.LIST}
              searchLabel={t('my_shop.collection_name')}
              onClickImage={handleClickGalleryCard}
              // new
              search={collectionsQuery.searchText}
              onSearch={(v) => updateCollectionsQuery({ page: 1, searchText: v || '' })}
              paginationData={pagination}
              onPagination={updateCollectionsQuery}
              sort={{
                sortBy: collectionsQuery.sortBy,
                orderBy: collectionsQuery.orderBy,
              }}
              onSort={(value = {}) => updateCollectionsQuery(value)}
            />
          }
        />
        <CreateCollectionDialog open={openCreateCollectionDialog} onClose={onCloseCreateCollectionDialog} />
      </Box>
      <PermissionsView roles={COLLECTIONS.ADD}>
        <Box className={classes.addBtn}>
          <Button
            color="primary"
            variant="contained"
            className="add-btn"
            data-testid="create-collection"
            onClick={onOpenCreateCollectionDialog}
          >
            <AddIcon />
          </Button>
        </Box>
      </PermissionsView>
    </UserLayout>
  );
};

export default CollectionsPage;
