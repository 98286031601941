import { FC, ReactNode } from 'react';

import AddIcon from '@mui/icons-material/Add';
import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Fab from '@mui/material/Fab';
import { makeStyles } from 'tss-react/mui';

interface WrapperWithFabProps {
  hidden?: Boolean;
  children: ReactNode;
  isLoading?: boolean;
  onClick: () => void;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '60px',
  },
  fab: {
    position: 'fixed',
    right: '16px',
    bottom: '16px',
  },
}));

const WrapperWithFab: FC<WrapperWithFabProps> = ({ hidden, isLoading, children, onClick }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      {children}
      {!hidden && (
        <Fab className={classes.fab} color="primary" onClick={onClick}>
          {isLoading ? <CircularProgress size={24} sx={{ color: 'white' }} /> : <AddIcon />}
        </Fab>
      )}
    </Box>
  );
};

export default WrapperWithFab;
