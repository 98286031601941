import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';

import { useTranslation } from 'react-i18next';

import RequiredPaymentMethodDialog from '../components/dialog/required-payment-method-dialog';

import { useGetPaymentMethodsLazyQuery } from '~/graphql/member/types';

interface IDialogInfo {
  title?: string;
  description: string;
}
export interface PaymentMethodRequiredContextValue {
  close: () => void;
  show: (info: IDialogInfo) => Promise<boolean>;
}

export const PaymentMethodRequiredContext = createContext<PaymentMethodRequiredContextValue>({} as any);

export const usePaymentMethodRequired = () => useContext(PaymentMethodRequiredContext);

const PaymentMethodRequired: FC<PropsWithChildren> = ({ children }) => {
  const { t } = useTranslation();

  const [dialogInfo, setDialogInfo] = useState<IDialogInfo>();

  const [getPaymentMethods] = useGetPaymentMethodsLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  const handleOpenDialog = async (info: IDialogInfo) => {
    const paymentMethodsRes = await getPaymentMethods();
    const notExist = !paymentMethodsRes.data?.getPaymentMethods?.length;
    if (notExist) {
      setDialogInfo(info);
    }
    return notExist;
  };

  const handleClose = () => {
    setDialogInfo(undefined);
  };

  return (
    <PaymentMethodRequiredContext.Provider value={{ show: handleOpenDialog, close: handleClose }}>
      {children}
      <RequiredPaymentMethodDialog
        open={!!dialogInfo}
        description={t(dialogInfo?.description || '')}
        title={t(dialogInfo?.title || 'payment_method_not_registered')}
        onClose={handleClose}
      />
    </PaymentMethodRequiredContext.Provider>
  );
};

export default PaymentMethodRequired;
