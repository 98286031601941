export const roundUpDecimals = (number: number, decimals: number) => {
  const factor = 10 ** decimals;
  return Math.ceil(number * factor) / factor;
};

export const formatLocaleString = (value: number | undefined, decimal?: number) => {
  if (!value) return '0';
  let result = value;
  if (typeof decimal === 'number') {
    result = Number(value.toFixed(decimal));
  }
  return result.toLocaleString();
};
