import { FC, useState } from 'react';

import { CircularProgress } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Control, Controller, FieldErrorsImpl, SubmitHandler, UseFormHandleSubmit } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '~/components/dialog/custom-dialog';
import ImageUploadInput from '~/components/image-upload-input';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { FormValuesImageNFT } from '~/pages/collection/CollectionDetail/details/MintOnPurchase/LazyMintCollectionDetail';
import { TYPE_IMAGE } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

interface ImageNFTDialogProps {
  isDirty: boolean;
  control: Control<FormValuesImageNFT, any>;
  errors: Partial<
    FieldErrorsImpl<{
      imageName: string;
      maxMint: number;
      imageFiles: NonNullable<
        | FileList
        | {
            file: File;
            type: TYPE_IMAGE;
            url: string;
          }[]
        | undefined
      >;
      imageMock: string;
    }>
  >;
  isDialogOpen: boolean;
  isEdit?: boolean;
  onAddImage?: (data: FormValuesImageNFT) => Promise<void>;
  onEditImage?: (data: FormValuesImageNFT) => Promise<void>;
  onCloseDialog: () => void;
  handleSubmit: UseFormHandleSubmit<FormValuesImageNFT>;
}

const useStyles = makeStyles()((theme) => ({
  wrapperContent: {
    '.image-dialog': {
      '&-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
        [theme.breakpoints.down('sm')]: {
          fontSize: '20px',
          lineHeight: 'unset',
        },
      },
    },
    '.MuiFormLabel-asterisk': {
      color: '#da0202de',
    },
  },
}));

const ImageNFTDialog: FC<ImageNFTDialogProps> = (props) => {
  const {
    control,
    errors,
    isDirty,
    isDialogOpen,
    isEdit = false,
    onAddImage,
    onEditImage,
    onCloseDialog,
    handleSubmit,
  } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAction =
    (callback: (data: FormValuesImageNFT) => Promise<void>): SubmitHandler<FormValuesImageNFT> =>
    async (data) => {
      setIsSubmitting(true);
      await callback(data);
      setIsSubmitting(false);
    };

  return (
    <CustomDialog
      width="md"
      open={isDialogOpen}
      onClose={onCloseDialog}
      dialogTitle={
        isEdit ? t('collection_screen.edit_lazy_mint_image') : t('collection_screen.add_new_issue_nft_image')
      }
      dialogContent={
        <Box className={classes.wrapperContent}>
          <ImageUploadInput<FormValuesImageNFT>
            required
            type="image"
            height="156px"
            name="imageFiles"
            control={control}
            label={t('image')}
            className="imageUpload"
            disabled={isSubmitting}
            defaultImageUrlName="imageMock"
            error={!!errors.imageFiles?.message}
            helperText={t(errors.imageFiles?.message as any, {
              size: 10,
            })}
          />
          <Controller
            name="imageName"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                margin="normal"
                id="outlined-basic"
                label={t('name.thing')}
                variant="outlined"
                required
                disabled={isSubmitting}
                error={!!errors.imageName?.message}
                helperText={getErrorText(errors.imageName?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            name="maxMint"
            control={control}
            render={({ field }) => (
              <NumberStringTextField
                required
                fullWidth
                margin="dense"
                variant="outlined"
                id="outlined-basic"
                disabled={isSubmitting}
                error={!!errors.maxMint?.message}
                label={t('collection_screen.max_mint')}
                helperText={getErrorText(errors.maxMint?.message as any, t)}
                {...field}
              />
            )}
          />
        </Box>
      }
      actions={[
        <Button variant="outlined" color="primary" onClick={onCloseDialog}>
          {t('cancel')}
        </Button>,
        isEdit ? (
          <Button
            variant="contained"
            onClick={handleSubmit(handleAction(onEditImage!))}
            disabled={!isDirty || isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          >
            {t('save')}
          </Button>
        ) : (
          <Button
            variant="contained"
            onClick={handleSubmit(handleAction(onAddImage!))}
            disabled={isSubmitting}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          >
            {t('add')}
          </Button>
        ),
      ]}
    />
  );
};

export default ImageNFTDialog;
