import { FC } from 'react';

import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Tooltip from '@mui/material/Tooltip';

import { IconBtnCopy } from './IconBtnCopy';

import { truncateEthAddress } from '~/utils/string.utils';

interface IAddressWithTooltip {
  href: string;
  title: string;
}

const CopyableAddress: FC<IAddressWithTooltip> = ({ title, href }) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <Tooltip
        arrow
        title={title}
        placement="bottom"
        PopperProps={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, -5],
              },
            },
          ],
        }}
      >
        <Link target="_blank" href={href}>
          {truncateEthAddress(title)}
        </Link>
      </Tooltip>
      <IconBtnCopy text={title} />
    </Stack>
  );
};

export default CopyableAddress;
