import moment from 'moment';

import { fetchWithTimeout } from './common';
import { NFTToken } from './fetch-nfts';

import { TokenQuery } from '~/graphql/subgraph/types';
import { MetadataJson } from '~/types/my-shop';

export const getNFTMetadata = async (
  collectionUuid: string,
  tokenInfo: TokenQuery['token'],
  ipfsGateways: string[] | undefined
) => {
  const key = `${collectionUuid}-${parseInt(tokenInfo?.tokenID, 10) - 1}`;
  let metadata: NFTToken = {
    collectionUuid: collectionUuid!,
    createdAt: moment.unix(tokenInfo?.mintTime).toString(),
    description: '',
    metadataContent: {
      image: '',
      name: '',
      animation_url: '',
      description: '',
    },
    metadataUrl: tokenInfo?.tokenURI ?? '',
    name: '',
    ownerAddress: tokenInfo?.owner.id ?? '',
    tokenId: tokenInfo?.tokenID,
    uuid: key,
  };
  let cachedToken: NFTToken | undefined;
  try {
    cachedToken = JSON.parse(localStorage.getItem(key) || '');
  } catch (err: any) {}
  if (cachedToken) {
    metadata.metadataContent = {
      animation_url: '',
      image: cachedToken.metadataContent.image,
      name: cachedToken.metadataContent?.name || '',
      description: cachedToken.metadataContent?.description || '',
    };
    cachedToken.ownerAddress = tokenInfo?.owner.id ?? cachedToken.ownerAddress;
    localStorage.setItem(key, JSON.stringify(cachedToken));
  } else {
    try {
      const metadataRes = await fetchMetadata(ipfsGateways, tokenInfo?.tokenURI);
      metadata.metadataContent = {
        animation_url: '',
        image: metadataRes?.image ? metadataRes.image : '/images/gu-logo.svg',
        name: metadataRes?.name || '',
        description: metadataRes?.description || '',
      };
      localStorage.setItem(key, JSON.stringify(metadata));
    } catch (err) {}
  }
  return metadata;
};

const fetchMetadata = async (ipfsGateways?: string[], URI?: string, currentIndex = 0): Promise<MetadataJson> => {
  if (!URI || URI.includes('undefined') || !ipfsGateways?.length) {
    throw new Error();
  }
  const length = ipfsGateways.length;
  if (currentIndex >= length) {
    throw new Error();
  }
  try {
    const currentGateway = ipfsGateways[currentIndex];
    const url = URI.replace('ipfs://', currentGateway) || '';
    const data = await fetchWithTimeout(url, 10000);
    const metadataRes: MetadataJson = await data?.json();
    return metadataRes;
  } catch (err) {
    return await fetchMetadata(ipfsGateways, URI, currentIndex + 1);
  }
};
