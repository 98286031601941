import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'blockExplorer' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** Date custom scalar type */
  Date: any;
};

export type Address = {
  ens_domain_name?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  implementation_name?: Maybe<Scalars['String']>;
  is_contract?: Maybe<Scalars['Boolean']>;
  is_verified?: Maybe<Scalars['Boolean']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  private_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  public_tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  watchlist_names?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Fee = {
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type NextPageParams = {
  block_number: Scalars['Int'];
  fee: Scalars['String'];
  hash: Scalars['String'];
  index: Scalars['Int'];
  inserted_at: Scalars['Date'];
  items_count: Scalars['Int'];
  value: Scalars['String'];
};

export type NextPageParamsInput = {
  block_number: Scalars['Int'];
  fee: Scalars['String'];
  hash: Scalars['String'];
  index: Scalars['Int'];
  inserted_at: Scalars['Date'];
  items_count: Scalars['Int'];
  value: Scalars['String'];
};

export type Query = {
  getTransactionsHistory: TransactionHistoryResponse;
};

export type QueryGetTransactionsHistoryArgs = {
  queryParams?: InputMaybe<Scalars['String']>;
  where: TransactionHistoryFilter;
};

export type Transaction = {
  actions?: Maybe<Array<Maybe<Scalars['String']>>>;
  base_fee_per_gas?: Maybe<Scalars['String']>;
  block?: Maybe<Scalars['Int']>;
  confirmation_duration?: Maybe<Array<Maybe<Scalars['Int']>>>;
  confirmations?: Maybe<Scalars['Int']>;
  created_contract?: Maybe<Address>;
  decoded_input?: Maybe<Scalars['String']>;
  exchange_rate?: Maybe<Scalars['String']>;
  fee?: Maybe<Fee>;
  from?: Maybe<Address>;
  gas_limit?: Maybe<Scalars['String']>;
  gas_price?: Maybe<Scalars['String']>;
  gas_used?: Maybe<Scalars['String']>;
  has_error_in_internal_txs?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  max_fee_per_gas?: Maybe<Scalars['String']>;
  max_priority_fee_per_gas?: Maybe<Scalars['String']>;
  method?: Maybe<Scalars['String']>;
  nonce?: Maybe<Scalars['Int']>;
  position?: Maybe<Scalars['Int']>;
  priority_fee?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['String']>;
  revert_reason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['Date']>;
  to?: Maybe<Address>;
  token_transfers?: Maybe<Scalars['String']>;
  token_transfers_overflow?: Maybe<Scalars['String']>;
  tx_burnt_fee?: Maybe<Scalars['String']>;
  tx_tag?: Maybe<Scalars['String']>;
  tx_types?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['String']>;
};

export type TransactionHistoryFilter = {
  address: Scalars['String'];
};

export type TransactionHistoryResponse = {
  items: Array<Transaction>;
  next_page_params: NextPageParams;
};

export type GetTransactionsHistoryQueryVariables = Exact<{
  queryParams?: InputMaybe<Scalars['String']>;
  where: TransactionHistoryFilter;
}>;

export type GetTransactionsHistoryQuery = {
  getTransactionsHistory: {
    items: Array<{
      type?: number | null;
      hash?: string | null;
      value?: string | null;
      block?: number | null;
      nonce?: number | null;
      result?: string | null;
      status?: string | null;
      method?: string | null;
      tx_tag?: string | null;
      actions?: Array<string | null> | null;
      tx_types?: Array<string | null> | null;
      gas_used?: string | null;
      position?: number | null;
      gas_limit?: string | null;
      timestamp?: any | null;
      gas_price?: string | null;
      priority_fee?: string | null;
      tx_burnt_fee?: string | null;
      exchange_rate?: string | null;
      confirmations?: number | null;
      decoded_input?: string | null;
      revert_reason?: string | null;
      token_transfers?: string | null;
      max_fee_per_gas?: string | null;
      base_fee_per_gas?: string | null;
      confirmation_duration?: Array<number | null> | null;
      token_transfers_overflow?: string | null;
      max_priority_fee_per_gas?: string | null;
      has_error_in_internal_txs?: string | null;
      fee?: { type?: string | null; value?: string | null } | null;
      to?: {
        hash?: string | null;
        name?: string | null;
        metadata?: string | null;
        is_contract?: boolean | null;
        is_verified?: boolean | null;
        public_tags?: Array<string | null> | null;
        private_tags?: Array<string | null> | null;
        watchlist_names?: Array<string | null> | null;
        ens_domain_name?: string | null;
        implementation_name?: string | null;
      } | null;
      from?: {
        hash?: string | null;
        name?: string | null;
        metadata?: string | null;
        is_contract?: boolean | null;
        is_verified?: boolean | null;
        public_tags?: Array<string | null> | null;
        private_tags?: Array<string | null> | null;
        watchlist_names?: Array<string | null> | null;
        ens_domain_name?: string | null;
        implementation_name?: string | null;
      } | null;
      created_contract?: {
        ens_domain_name?: string | null;
        hash?: string | null;
        implementation_name?: string | null;
        is_contract?: boolean | null;
        is_verified?: boolean | null;
        metadata?: string | null;
        name?: string | null;
        private_tags?: Array<string | null> | null;
        public_tags?: Array<string | null> | null;
        watchlist_names?: Array<string | null> | null;
      } | null;
    }>;
    next_page_params: {
      fee: string;
      hash: string;
      value: string;
      index: number;
      items_count: number;
      inserted_at: any;
      block_number: number;
    };
  };
};

export const GetTransactionsHistoryDocument = gql`
  query getTransactionsHistory($queryParams: String = "", $where: TransactionHistoryFilter!) {
    getTransactionsHistory(queryParams: $queryParams, where: $where)
      @rest(
        type: "GetTransactionsHistoryResult"
        path: "/api/v2/addresses/{args.where.address}/transactions{args.queryParams}"
      ) {
      items {
        type
        hash
        value
        block
        nonce
        result
        status
        method
        tx_tag
        actions
        tx_types
        gas_used
        position
        gas_limit
        timestamp
        gas_price
        priority_fee
        tx_burnt_fee
        exchange_rate
        confirmations
        decoded_input
        revert_reason
        token_transfers
        max_fee_per_gas
        base_fee_per_gas
        confirmation_duration
        token_transfers_overflow
        max_priority_fee_per_gas
        has_error_in_internal_txs
        fee {
          type
          value
        }
        to {
          hash
          name
          metadata
          is_contract
          is_verified
          public_tags
          private_tags
          watchlist_names
          ens_domain_name
          implementation_name
        }
        from {
          hash
          name
          metadata
          is_contract
          is_verified
          public_tags
          private_tags
          watchlist_names
          ens_domain_name
          implementation_name
        }
        created_contract {
          ens_domain_name
          hash
          implementation_name
          is_contract
          is_verified
          metadata
          name
          private_tags
          public_tags
          watchlist_names
        }
      }
      next_page_params {
        fee
        hash
        value
        index
        items_count
        inserted_at
        block_number
      }
    }
  }
`;

/**
 * __useGetTransactionsHistoryQuery__
 *
 * To run a query within a React component, call `useGetTransactionsHistoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTransactionsHistoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTransactionsHistoryQuery({
 *   variables: {
 *      queryParams: // value for 'queryParams'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useGetTransactionsHistoryQuery(
  baseOptions: Apollo.QueryHookOptions<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>(
    GetTransactionsHistoryDocument,
    options
  );
}
export function useGetTransactionsHistoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetTransactionsHistoryQuery, GetTransactionsHistoryQueryVariables>(
    GetTransactionsHistoryDocument,
    options
  );
}
export type GetTransactionsHistoryQueryHookResult = ReturnType<typeof useGetTransactionsHistoryQuery>;
export type GetTransactionsHistoryLazyQueryHookResult = ReturnType<typeof useGetTransactionsHistoryLazyQuery>;
export type GetTransactionsHistoryQueryResult = Apollo.QueryResult<
  GetTransactionsHistoryQuery,
  GetTransactionsHistoryQueryVariables
>;
