import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';

const DialogCloseButton = styled(IconButton)(({ theme }) => ({
  zIndex: 1,
  color: '#444',
  position: 'absolute',
  top: theme.spacing(1.5),
  right: theme.spacing(2),
}));

export default DialogCloseButton;
