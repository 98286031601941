export enum ContextAPIEnum {
  Subgraph = 'subgraph',
  BlockExplorer = 'blockExplorer',
}

export enum TransferOwnershipDepositStatus {
  ACCEPTED = 'ACCEPTED',
  PENDING = 'PENDING',
  CANCELED = 'CANCELED',
}

export enum MethodEnum {
  NoAPIKey = 'noAPIKey',
  WithAPIKey = 'withAPIKey',
  MetadataUri = 'metadataUri',
}

export enum TemplateEnum {
  Image = 'image',
  Video = 'video',
}

export enum MethodsContractEnum {
  Mint = 'mint',
  Transfer = 'transfer',
}

export enum BalanceFluctuationQueryKey {
  FEE = 'fee',
  VALUE = 'value',
}

export enum VIEW_MODE {
  LIST = 'LIST',
  GALLERY = 'GALLERY',
}

export enum BlockchainTxnType {
  Refund = 'refund',
  Charge = 'charge',
  TokenMint = 'token_mint',
  Transaction = 'transaction',
  ContractCall = 'contract_call',
  CoinTransfer = 'coin_transfer',
  TokenTransfer = 'token_transfer',
  ContractCreation = 'contract_creation',
}

export enum InvoiceType {
  PurchasePlan = 'PURCHASE_PLAN',
  RevenueShare = 'REVENUE_SHARE',
  PurchasePoint = 'PURCHASE_POINT',
  PurchaseShopLicense = 'PURCHASE_SHOP_LICENSE',
  PurchaseUserLicense = 'PURCHASE_USER_LICENSE',
  PurchaseMemberSiteLicense = 'PURCHASE_MEMBER_SITE_LICENSE',
  PurchaseMemberPerSiteLicense = 'PURCHASE_MEMBER_PER_SITE_LICENSE',
}
