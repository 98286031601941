import { forwardRef, memo, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import EditNavi from './EditNavi';
import EditStyle from './EditStyle';
import EditTheme from './EditTheme';
import MenuList from './MenuList';

import { EDIT_SHOP_HEADER_HEIGHT } from '~/constants/layout';
import { ReactComponent as NaviIcon } from '~/icons/images/navi-icon.svg';
import { ReactComponent as ThemaIcon } from '~/icons/images/thema-icon.svg';
import { ReactComponent as ThemeIcon } from '~/icons/images/theme-icon.svg';
import { useEditShop } from '~/pages/edit-shop';

enum MENU {
  THEME = 'THEME',
  STYLE = 'STYLE',
  NAVI = 'NAVI',
}

const useStyles = makeStyles()((theme) => ({
  rootDrawer: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
    },
    height: '100%',
    borderRight: '1px solid #D7D7D7',
  },
  navBtn: {
    height: 'unset',
    display: 'flex',
    marginBottom: 8,
    flexDirection: 'column',
  },
  navBtnText: {
    width: 65,
    marginTop: 4,
    fontWeight: 400,
    fontSize: '12px',
    color: '#1C1B1F',
    fontStyle: 'normal',
  },
  drawerMainMenu: {
    width: '72px',
    backgroundColor: '#3E3E3E',
    '.MuiList-root': {
      padding: 0,
    },
    '.MuiButtonBase-root': {
      display: 'flex',
      color: '#ffffff',
      flexDirection: 'column',
      '.MuiTypography-root': {
        fontSize: 12,
        fontWeight: 400,
      },
      '&.Mui-selected': {
        background: '#0367DF !important',
      },
    },
    '.MuiListItemButton-root': {
      alignItems: 'center',
      justifyContent: 'center',
    },
    '.MuiListItemIcon-root svg': {
      color: '#ffffff',
    },
    '.MuiListItemIcon-root': {
      minWidth: 'unset',
    },
  },
  drawerSubMenu: {
    width: 280,
    display: 'flex',
    flexDirection: 'column',
    maxHeight: `calc(100vh - ${EDIT_SHOP_HEADER_HEIGHT}px)`,
    '.sub-menu-title': {
      display: 'flex',
      borderBottom: '1px solid #D7D7D7',
      padding: '12px 16px',
      height: '56px',
      backgroundColor: '#F5F5F5',
      '.sub-menu-title-text': {
        fontSize: 20,
        fontWeight: 500,
      },
    },
    '.sub-menu-content': {
      padding: '12px 16px',
      overflow: 'auto',
      backgroundColor: '#FCFCFC',
      flex: 1,
    },
    '.sub-menu-description': {
      marginTop: 16,
      marginBottom: 8,
    },
  },
}));

const SideBarEditDesign = forwardRef<HTMLDivElement>((_, ref) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { handleSubmit } = useEditShop();

  const [menu, setMenu] = useState<MENU>(MENU.THEME);

  const listMenuOption = useMemo(
    () => ({
      [MENU.THEME]: {
        value: MENU.THEME,
        label: t('my_shop.edit_design.theme'),
        sublabel: t('my_shop.edit_design.theme'),
        icon: <ThemaIcon />,
        component: <EditTheme />,
      },
      [MENU.STYLE]: {
        value: MENU.STYLE,
        label: t('my_shop.edit_design.style'),
        sublabel: t('my_shop.edit_design.style'),
        icon: <ThemeIcon />,
        component: <EditStyle ref={ref} />,
      },
      [MENU.NAVI]: {
        value: MENU.NAVI,
        label: t('details'),
        sublabel: t('details'),
        icon: <NaviIcon />,
        component: <EditNavi />,
      },
    }),
    [t, ref]
  );

  const handleChange = (newMenu: MENU) => {
    handleSubmit(() => setMenu(newMenu))();
  };

  return (
    <div className={classes.rootDrawer}>
      <Box className={classes.drawerMainMenu}>
        <MenuList<MENU> currMenu={menu} menuList={Object.values(listMenuOption)} onChange={handleChange} />
      </Box>
      <Box className={classes.drawerSubMenu}>
        <div className="sub-menu-title">
          <div className="sub-menu-title-text">{listMenuOption?.[menu]?.sublabel}</div>
        </div>
        <div className="sub-menu-content">{listMenuOption?.[menu]?.component}</div>
      </Box>
    </div>
  );
});

export default memo(SideBarEditDesign);
