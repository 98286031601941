import { ChangeEventHandler, ComponentProps, ForwardedRef, forwardRef } from 'react';

import TextField from '@mui/material/TextField';

import { formatNumberWithCommas } from '~/utils/common';

export interface INumberStringTextField extends ComponentProps<typeof TextField> {
  isDecimal?: boolean;
  onChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}

export const NumberStringTextField = forwardRef((props: INumberStringTextField, ref: ForwardedRef<HTMLDivElement>) => {
  const { value, isDecimal, onChange, ...others } = props;

  const handleChange: ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> = async (event) => {
    const newValue = event.target.value;
    const removeComma = newValue.replace(/,/g, '');
    const regexPattern = isDecimal ? /^\d*\.?\d*$/ : /^\d*$/;
    if (!regexPattern.test(removeComma)) {
      return;
    }
    const removeNegative = removeComma.toString().replace(/-/g, '');
    const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
    event.target.value = removeZero;
    onChange(event);
  };

  return (
    <TextField
      fullWidth
      ref={ref}
      value={formatNumberWithCommas(value as string)}
      {...others}
      onChange={handleChange}
    />
  );
});
