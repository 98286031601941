import { FC, memo, useCallback, useMemo, useState } from 'react';

import { useAccount } from '@gusdk/gu-wallet-connector';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as GlobalOutlinedIcon } from 'src/icons/images/GlobalOutlined.svg';
import { ReactComponent as WalletOutlinedIcon } from 'src/icons/images/WalletOutlined.svg';
import LanguageMenu from '~/components/app-layout/user-layout/header-bar/language-menu';
import { colors } from '~/constants/colors';
import { env } from '~/env';
import { useEditShop } from '~/pages/edit-shop';
import { TEMPLATE_OPTIONS } from '~/types/my-shop';

interface StyleParams {
  spacing: number;
  spacingSm: number;
  headerTextColor: string;
  headerColor?: string | null;
  isHeaderImageStyle2: boolean;
}

const useStyles = makeStyles<StyleParams>()(
  (theme, { spacing, spacingSm, headerColor, headerTextColor, isHeaderImageStyle2 }) => {
    headerColor = headerColor || colors.white;
    headerTextColor = headerTextColor || colors.black;
    const sm = theme.breakpoints.up('sm').replace('@media', '@container');
    const smDown = theme.breakpoints.down('sm').replace('@media', '@container');

    const bannerCss = isHeaderImageStyle2
      ? {
          margin: 'auto',
          maxWidth: '1200px',
          overflow: 'hidden',
          aspectRatio: '4 / 1',
          borderRadius:
            '0px 0px max(0px, min(8px, (-100% - 4px + 100vw) * 9999)) max(0px, min(8px, (-100% - 4px + 100vw) * 9999)) / 0px 0px 8px 8px',
        }
      : {
          height: '160px',
          [sm]: {
            height: '240px',
          },
        };

    return {
      wrapper: {
        '.MuiTypography-h1': {
          fontWeight: 400,
          fontSize: '34px',
          lineHeight: '42px',
          marginBottom: '16px',
        },
        span: {
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
        },
        '.MuiButton-root': {
          color: headerTextColor,
          textTransform: 'initial',
          '.icon': {
            width: '24px',
            height: '24px',
            backgroundColor: headerTextColor,
          },
          '&:hover': {
            backgroundColor: 'initial',
          },
        },
      },
      navbar: {
        backgroundColor: headerColor,
        gap: '16px',
        width: '100%',
        height: '56px',
        display: 'flex',
        padding: '0 16px',
        alignItems: 'center',
        justifyContent: 'space-between',
        [sm]: {
          gap: '24px',
          padding: '0 40px',
          justifyContent: 'flex-end',
        },
        '& .MuiButtonBase-root': {
          padding: 0,
          minWidth: 0,
          fontWeight: 400,
          fontSize: '16px',
          lineHeight: '24px',
          letterSpacing: '0.5px',
          p: {
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '24px',
            letterSpacing: '0.5px',
          },
          '.onlyDesktop': {
            '&.label': {
              marginLeft: '8px',
            },
            '&.endIcon': {
              marginLeft: '2px',
            },
            [smDown]: {
              display: 'none',
            },
          },
          '.MuiButton-startIcon>*:nth-of-type(1)': {
            fontSize: '24px',
          },
          '.MuiButton-endIcon': {
            marginLeft: '2px',
          },
        },
      },
      shopTitle: {
        width: '100%',
        '.banner': {
          width: '100%',
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          ...bannerCss,
          img: {
            width: '100%',
            height: '100%',
            objectFit: 'cover',
            objectPosition: 'center',
          },
          '.MuiSvgIcon-root': {
            color: 'white',
            fontSize: '49px',
          },
          '.MuiSkeleton-root': {
            zIndex: -1,
          },
        },
        '.title': {
          margin: '0 auto',
          maxWidth: '780px',
          textAlign: 'center',
          padding: `${spacingSm || 20}px 16px`,
          [sm]: {
            padding: `${spacing || 40}px 16px`,
          },
          a: {
            '&:any-link': {
              color: 'inherit',
            },
          },
        },
      },
      menu: {
        '.MuiMenu-paper': {
          backgroundColor: headerColor,
        },
      },
      paper: {
        backgroundColor: headerColor,
      },
    };
  }
);

const transformString = (inputString: string) => {
  if (inputString?.length <= 8) {
    return inputString;
  } else {
    const prefix = inputString.substring(0, 6);
    const suffix = inputString.substring(inputString.length - 4);
    const transformedString = `${prefix}...${suffix}`;
    return transformedString;
  }
};

const ShopHeader: FC = () => {
  const { control } = useEditShop();

  const walletAccount = useAccount();
  const { t, i18n } = useTranslation();
  const [loadingBanner, setLoadingBanner] = useState(true);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();
  const [languageMenuAnchorEl, setLanguageMenuAnchorEl] = useState<HTMLElement | null>(null);

  const [
    spacing,
    template,
    spacingSm,
    pageTitle,
    pageTitleJa,
    headerColor,
    headerTextColor,
    description,
    bannerImage,
    descriptionJa,
    bannerImageFile,
    ratioBannerImage,
    ratioBannerImageFile,
  ] = useWatch({
    control,
    name: [
      'style.spacing',
      'thema.template',
      'style.spacingSm',
      'navi.pageTitle',
      'navi.pageTitleJa',
      'style.headerColor',
      'style.headerTextColor',
      'navi.description',
      'thema.bannerImage',
      'navi.descriptionJa',
      'thema.bannerImageFile',
      'thema.ratioBannerImage',
      'thema.ratioBannerImageFile',
    ],
  });

  const LANGS = useMemo(
    () => ({
      en: t('english'),
      ja: t('japanese'),
    }),
    [t]
  );

  const bannerUrl = useMemo(
    () => (bannerImageFile?.[0] ? URL.createObjectURL(bannerImageFile?.[0]!) : ''),
    [bannerImageFile]
  );

  const ratioBannerUrl = useMemo(
    () => (ratioBannerImageFile?.[0] ? URL.createObjectURL(ratioBannerImageFile?.[0]!) : ''),
    [ratioBannerImageFile]
  );

  const isSimpleTemplate = template === TEMPLATE_OPTIONS.SIMPLE_SITE;
  const isHeaderImageStyle2 = template === TEMPLATE_OPTIONS.HEADER_IMAGE_STYLE_2;
  const bannerImageWithoutRatio = bannerUrl
    ? bannerUrl
    : bannerImage
    ? env.REACT_APP_API_MEDIA + '/' + bannerImage
    : '';
  const bannerImageWithRatio = ratioBannerUrl
    ? ratioBannerUrl
    : ratioBannerImage
    ? env.REACT_APP_API_MEDIA + '/' + ratioBannerImage
    : '';

  const { classes, cx } = useStyles({
    spacing,
    spacingSm,
    headerColor,
    headerTextColor,
    isHeaderImageStyle2,
  });

  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const onOpenLanguageMenu = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      setLanguageMenuAnchorEl(event.currentTarget);
    },
    [setLanguageMenuAnchorEl]
  );

  const onCloseLanguageMenu = useCallback(() => {
    setLanguageMenuAnchorEl(null);
  }, [setLanguageMenuAnchorEl]);

  const title = useMemo(() => {
    if (i18n.language === 'en') {
      return pageTitle || pageTitleJa || '';
    }
    return pageTitleJa || pageTitle || '';
  }, [pageTitle, pageTitleJa, i18n.language]);

  const caption = useMemo(() => {
    if (i18n.language === 'en') {
      return description || descriptionJa || '';
    }
    return descriptionJa || description || '';
  }, [description, descriptionJa, i18n.language]);

  const handleLoadedBanner = () => {
    setLoadingBanner(false);
  };

  const showWithRatio = isHeaderImageStyle2 && bannerImageWithRatio;
  const showWithoutRatio = !isHeaderImageStyle2 && bannerImageWithoutRatio;

  return (
    <Box className={cx(classes.wrapper, isSimpleTemplate && 'simple')}>
      <Box className={classes.navbar} sx={{ borderBottom: isSimpleTemplate ? '1px solid #D7D7D7' : 'none' }}>
        {walletAccount.status === 'disconnected' && (
          <Button variant="text" color="inherit" startIcon={<WalletOutlinedIcon />}>
            {t('connect')}
          </Button>
        )}
        {walletAccount.status === 'connected' && (
          <Box>
            <Button variant="text" startIcon={<WalletOutlinedIcon />} onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
              {transformString(walletAccount?.account)}
            </Button>
            <Menu open={!!menuAnchorEl} anchorEl={menuAnchorEl} className={classes.menu} onClose={onCloseMenu}>
              <MenuItem sx={{ color: headerTextColor || colors.black }}>{t('disconnect')}</MenuItem>
            </Menu>
          </Box>
        )}
        <Button
          color="inherit"
          className="language"
          startIcon={<GlobalOutlinedIcon />}
          endIcon={<ArrowDropDownIcon className="onlyDesktop endIcon" />}
          onClick={onOpenLanguageMenu}
        >
          {LANGS[i18n.language as 'en' | 'ja']}
        </Button>
      </Box>
      <Box className={classes.shopTitle}>
        {!isSimpleTemplate && (
          <Box className="banner">
            {showWithRatio && (
              <img key="withRatio" alt="banner" src={bannerImageWithRatio} onLoad={handleLoadedBanner} />
            )}
            {showWithoutRatio && (
              <img key="withoutRatio" alt="banner" src={bannerImageWithoutRatio} onLoad={handleLoadedBanner} />
            )}
            {((!showWithoutRatio && !showWithRatio) || loadingBanner) && (
              <Skeleton
                width="100%"
                height="100%"
                animation="pulse"
                variant="rectangular"
                sx={{ position: 'absolute' }}
              />
            )}
            {!showWithoutRatio && !showWithRatio && <PermMediaOutlinedIcon />}
          </Box>
        )}
        <Box className="title">
          <Typography variant="h1" color="titleColor">
            {title}
          </Typography>
          {!!caption && (
            <Box
              color="descriptionColor"
              className="quillPreview description"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          )}
        </Box>
      </Box>
      <LanguageMenu
        classes={classes}
        anchorEl={languageMenuAnchorEl}
        open={Boolean(languageMenuAnchorEl)}
        color={headerTextColor || colors.black}
        onClose={onCloseLanguageMenu}
      />
    </Box>
  );
};

export default memo(ShopHeader);
