import { FC, ForwardedRef, forwardRef, memo, useCallback, useMemo, useState } from 'react';

import { WalletConnectorDialog, useAccount } from '@gusdk/gu-wallet-connector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { InputProps } from '@mui/material/Input';
import Link from '@mui/material/Link';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { GridColDef, GridRenderCellParams, GridRenderEditCellParams, useGridApiContext } from '@mui/x-data-grid-pro';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';

import { DisplayedCollectionsList } from '.';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import EditCollectionFromShopDialog, {
  IEditCollectionFromShopField,
} from '~/components/dialog/edit-collection-from-shop-dialog';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { ListTablePagination } from '~/components/list-table';
import SquareImage from '~/components/SquareImage';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { VIEW_MODE } from '~/enum/common';
import { SalesCollectionsList } from '~/enum/tableFields';
import {
  GetMyShopDocument,
  ListMyShopCollectionsDocument,
  ListMyShopCollectionsFilter,
  MyShopCollectionQueryKey,
  OrderBy,
  useUnAttachMyShopCollectionsMutation,
  useUpdateMyShopCollectionMutation,
  useUpdateMyShopMutation,
} from '~/graphql/member/types';
import { COLLECTION_TYPE, MEMBER_SITE_NFT_STATUS, STATUS, SalesMethodEnum } from '~/types/my-shop';
import getAddressLink from '~/utils/getAddressLink';
import { truncateEthAddress } from '~/utils/string.utils';

interface SalesCollectionsTabProps {
  loadingData: boolean;
  rows: DisplayedCollectionsList[];
  pagination: ListTablePagination;
  queryData: {
    page?: number;
    limit?: number;
    orderBy?: OrderBy;
    searchText?: string;
    sortBy?: MyShopCollectionQueryKey;
    where?: ListMyShopCollectionsFilter;
  };
  updateQuery: (params: {
    page?: number;
    limit?: number;
    orderBy?: OrderBy;
    searchText?: string;
    sortBy?: MyShopCollectionQueryKey;
    where?: ListMyShopCollectionsFilter;
  }) => void;
  openAttachCollectionDialog: () => void;
}

export interface CustomAnchorElProps {
  id: string;
  anchorEl: null | HTMLElement;
}

export const salesMethod = (t: TFunction) => ({
  [SalesMethodEnum.NFT_LIST_ONLY]: {
    title: t('my_shop.nft_list_only'),
    value: SalesMethodEnum.NFT_LIST_ONLY,
  },
  [SalesMethodEnum.ADVANCED_ONLY]: {
    title: t('my_shop.advanced_only'),
    value: SalesMethodEnum.ADVANCED_ONLY,
  },
  [SalesMethodEnum.NFT_LIST_AND_ADVANCED]: {
    title: t('my_shop.nft_list_and_advanced'),
    value: SalesMethodEnum.NFT_LIST_AND_ADVANCED,
  },
});

export const collectionStatus = (t: TFunction<'translation', undefined>) => {
  return {
    [STATUS.NOW_ON_SALE]: {
      title: t('my_shop.now_on_sale'),
      value: STATUS.NOW_ON_SALE,
    },
    [STATUS.SOLD_OUT]: {
      title: t('my_shop.sold_out'),
      value: STATUS.SOLD_OUT,
    },
    [STATUS.NOT_SELECTED]: {
      title: t('my_shop.not_selected'),
      value: STATUS.NOT_SELECTED,
    },
    [STATUS.SUSPENSION]: {
      title: t('suspension'),
      value: STATUS.SUSPENSION,
    },
    [MEMBER_SITE_NFT_STATUS.INVALID]: {
      title: t('member_site.invalid'),
      value: MEMBER_SITE_NFT_STATUS.INVALID,
    },
    [MEMBER_SITE_NFT_STATUS.VALID]: {
      title: t('member_site.validity'),
      value: MEMBER_SITE_NFT_STATUS.VALID,
    },
    [MEMBER_SITE_NFT_STATUS.EXPIRED]: {
      title: t('member_site.expired'),
      value: MEMBER_SITE_NFT_STATUS.EXPIRED,
    },
  };
};

export const collectionType = (t: TFunction<'translation', undefined>) => {
  return {
    [COLLECTION_TYPE.DIGITAL_COLLECTIVE]: {
      title: t('digital_collective'),
      value: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
    },
    [COLLECTION_TYPE.MEMBERSHIP]: {
      title: t('membership'),
      value: COLLECTION_TYPE.MEMBERSHIP,
    },
    [COLLECTION_TYPE.PROMOTION]: {
      title: t('promotion'),
      value: COLLECTION_TYPE.PROMOTION,
    },
  };
};

export const renderSelectEditInputCell = (params: GridRenderEditCellParams) => {
  return <TextEditInputCell extendProps={params} />;
};

const TextEditInputCell = forwardRef(
  (props: { extendProps: GridRenderCellParams }, ref: ForwardedRef<HTMLDivElement>) => {
    const { extendProps, ...others } = props;
    const { id, value, field } = extendProps;
    const apiRef = useGridApiContext();

    const handleChange: InputProps['onChange'] = async (event) => {
      const newValue = event.target.value;
      const regexPattern = /^(?!0$)\d*$/;
      if (!regexPattern.test(newValue)) {
        return;
      }
      const removeNegative = newValue.toString().replace(/-/g, '');
      const removeZero = removeNegative.replace(/^0\d+/, removeNegative.slice(1));
      await apiRef.current.setEditCellValue({ id, field, value: removeZero || null });
    };

    return (
      <TextField
        fullWidth
        ref={ref}
        {...others}
        InputProps={{
          sx: {
            height: '80px',
          },
        }}
        value={value !== undefined && value !== null ? `${value}` : ''}
        onChange={handleChange}
      />
    );
  }
);

const SalesCollectionTab: FC<SalesCollectionsTabProps> = (props) => {
  const { rows, loadingData, pagination, queryData, updateQuery, openAttachCollectionDialog } = props;
  const { data: myShop } = useShopDetail();
  const { supportedNetworks } = useSupportedNetworks();

  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { account } = useAccount();
  const { enqueueSnackbar } = useSnackbar();

  const [openWalletDialog, setOpenWalletDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [selectedCollection, setSelectedCollection] = useState<DisplayedCollectionsList>();
  const [openRemoveAttachCollectionDialog, setOpenRemoveAttachCollectionDialog] = useState(false);
  const [openEditCollectionNameDialog, setOpenEditCollectionNameDialog] = useState<boolean>(false);

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });
  const [updateMyShopCollection] = useUpdateMyShopCollectionMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
  });
  const [unAttachCollectionShop] = useUnAttachMyShopCollectionsMutation({
    refetchQueries: [GetMyShopDocument, ListMyShopCollectionsDocument],
    onCompleted: () => updateQuery({ page: 1 }),
  });

  // Handle Edit Collection Name Dialog
  const onOpenEditCollectionName = () => {
    handleCloseMenu();
    setOpenEditCollectionNameDialog(true);
  };
  const onCloseEditCollectionName = () => {
    setOpenEditCollectionNameDialog(false);
  };

  // Handle Remove Collection Dialog
  const handleOpenRemoveCollectionDialog = () => {
    setOpenRemoveAttachCollectionDialog(true);
    handleCloseMenu();
  };
  const onCloseRemoveMyShopDialog = useCallback(async () => {
    setOpenRemoveAttachCollectionDialog(false);
  }, []);

  const handleEditCollectionName = async (data: IEditCollectionFromShopField) => {
    try {
      await updateMyShopCollection({
        variables: {
          input: {
            name: data.name,
            desc: data.desc,
            nameJa: data.nameJa,
            descJa: data.descJa,
            uuid: selectedCollection?.id || '',
          },
        },
      });
      enqueueSnackbar(t('toast_message.update_caption_successfully'), { variant: 'success' });
      setOpenEditCollectionNameDialog(false);
    } catch (err: any) {
      enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
    }
  };

  const handleCloseMenu = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleShowCollectionDetail = useCallback(
    (specifiedId?: string) => () => {
      navigate(
        AppRouteEnum.ShopCollectionDetail.replace(/:id/g, id || '').replace(
          /:collectionId/g,
          specifiedId || selectedCollection?.[MyShopCollectionQueryKey.CollectionUuid] || ''
        )
      );
      handleCloseMenu();
    },
    [navigate, id, selectedCollection, handleCloseMenu]
  );

  const columns: GridColDef<DisplayedCollectionsList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        sortable: false,
        resizable: false,
        headerName: t('image'),
        field: SalesCollectionsList.URL,
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage
                src={value}
                onClick={handleShowCollectionDetail(row[MyShopCollectionQueryKey.CollectionUuid])}
              />
            </Box>
          );
        },
      },
      {
        width: 100,
        editable: true,
        sortable: false,
        resizable: false,
        field: SalesCollectionsList.ORDER,
        headerName: t('my_shop.order_index'),
        renderEditCell: renderSelectEditInputCell,
      },
      {
        field: MyShopCollectionQueryKey.Name,
        headerName: t('my_shop.collection_caption_en'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        field: MyShopCollectionQueryKey.NameJa,
        headerName: t('my_shop.collection_caption_ja'),
        width: JSON.parse(columnsSize).nameJa || 150,
      },
      {
        headerName: t('my_shop.collection_name'),
        width: JSON.parse(columnsSize).nameJa || 150,
        field: MyShopCollectionQueryKey.CollectionName,
      },
      {
        field: MyShopCollectionQueryKey.CollectionSymbol,
        headerName: t('symbol'),
        width: JSON.parse(columnsSize).symbol || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionContractAddress,
        headerName: t('contract_address'),
        renderCell: ({ formattedValue, row }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                <Link
                  target="_blank"
                  href={getAddressLink(
                    supportedNetworks?.[row[MyShopCollectionQueryKey.CollectionNetwork]]?.blockExplorer,
                    formattedValue
                  )}
                >
                  {truncateEthAddress(formattedValue)}
                </Link>
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).contractAddress || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionOwnerAddress,
        headerName: t('owner_address'),
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {formattedValue ? (
              <>
                {truncateEthAddress(formattedValue)}
                <IconBtnCopy text={formattedValue} />
              </>
            ) : (
              '-'
            )}
          </Box>
        ),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 150,
      },
      {
        field: MyShopCollectionQueryKey.CollectionNetwork,
        headerName: t('network'),
        valueFormatter: ({ value }) => (value ? supportedNetworks?.[value]?.name : '-'),
        width: JSON.parse(columnsSize).network || 150,
      },
      {
        type: 'date',
        headerName: t('created_at'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        field: MyShopCollectionQueryKey.CollectionCreatedAt,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <IconButton onClick={(event) => handleClickMenu(event, row)}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
  }, [supportedNetworks, t, handleShowCollectionDetail]);

  const handleClickGalleryCard = (collection: any) => {
    navigate(
      AppRouteEnum.ShopCollectionDetail.replace(/:id/g, id || '').replace(
        /:collectionId/g,
        collection?.[MyShopCollectionQueryKey.CollectionUuid] || ''
      )
    );
  };

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>, row: DisplayedCollectionsList) => {
    setSelectedCollection(row);
    setAnchorEl(event.currentTarget);
  };

  const handleDeleteCollectionFromShop = async () => {
    try {
      await unAttachCollectionShop({
        variables: {
          input: {
            shopUuid: myShop?.uuid,
            collectionUuids: [selectedCollection?.[MyShopCollectionQueryKey.CollectionUuid]] as string[],
          },
        },
      });
      setAnchorEl(null);
      setOpenRemoveAttachCollectionDialog(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handleCloseWalletDialog = () => {
    setOpenWalletDialog(false);
  };

  const handleOpenAttachDialog = () => {
    if (account) {
      openAttachCollectionDialog();
    } else {
      setOpenWalletDialog(true);
    }
  };

  const handleUpdateSort = async (value: any) => {
    const sortBy = value.sortBy || MyShopCollectionQueryKey.CollectionCreatedAt;
    const orderBy = value.orderBy || OrderBy.Desc;
    updateQuery({ sortBy, orderBy });
    if (sortBy !== myShop?.shopInformation?.sortBy || orderBy !== myShop?.shopInformation?.orderBy) {
      await updateMyShop({
        variables: {
          input: { uuid: id ?? '', shopInformation: { sortBy, orderBy } },
        },
      });
    }
  };

  const handleNext = () => {
    if (!openRemoveAttachCollectionDialog) {
      openAttachCollectionDialog();
    }
  };

  const handleProcessRowUpdate = async (newRow: DisplayedCollectionsList, oldRow: DisplayedCollectionsList) => {
    try {
      if (newRow.order === oldRow.order) {
        return newRow;
      }
      await updateMyShopCollection({
        variables: {
          input: {
            uuid: newRow.id,
            order: newRow.order ? parseInt(newRow.order.toString(), 10) : newRow.order,
          },
        },
      });
    } catch (err: any) {}
    return newRow;
  };

  return (
    <>
      <ListTable
        isMenu
        noBorder
        rows={rows}
        columns={columns}
        isLoading={loadingData}
        onlyMode={VIEW_MODE.LIST}
        search={queryData.searchText}
        tableName="shop_collection_list"
        searchLabel={t('my_shop.collection_name')}
        noDataProps={{
          title: t('attach_collection'),
          description: t('no_data.attach_collection'),
          buttonTitle: t('my_shop.attach_new_collection'),
          onClick: handleOpenAttachDialog,
        }}
        onSearch={(v) => updateQuery({ page: 1, searchText: v || '' })}
        onRowUpdate={handleProcessRowUpdate}
        onClickImage={handleClickGalleryCard}
        noRowsMessage={t('my_shop.message.no_attached_collection')}
        paginationData={pagination}
        onPagination={updateQuery}
        sort={{
          sortBy: queryData.sortBy,
          orderBy: queryData.orderBy,
        }}
        onSort={handleUpdateSort}
      />
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem onClick={handleShowCollectionDetail()}>{t('my_shop.show_detail')}</MenuItem>
        <MenuItem onClick={onOpenEditCollectionName}>{t('my_shop.edit_collection_in_shop')}</MenuItem>
        <MenuItem onClick={handleOpenRemoveCollectionDialog}>{t('my_shop.remove_from_shop')}</MenuItem>
      </Menu>
      <EditCollectionFromShopDialog
        open={openEditCollectionNameDialog}
        title={t('my_shop.edit_collection_in_shop')}
        defaultData={{
          name: selectedCollection?.[MyShopCollectionQueryKey.Name] || '',
          desc: selectedCollection?.[MyShopCollectionQueryKey.Desc] || '',
          nameJa: selectedCollection?.[MyShopCollectionQueryKey.NameJa] || '',
          descJa: selectedCollection?.[MyShopCollectionQueryKey.DescJa] || '',
        }}
        onEdit={handleEditCollectionName}
        onClose={onCloseEditCollectionName}
      />
      <WalletConnectorDialog open={openWalletDialog} onNext={handleNext} onClose={handleCloseWalletDialog} />
      <ConfirmationDialog
        open={openRemoveAttachCollectionDialog}
        title={t('my_shop.message.remove_shop_message')}
        content={t('my_shop.message.confirm_remove_shop')}
        confirmTitle={t('remove')}
        onClose={onCloseRemoveMyShopDialog}
        onConfirm={handleDeleteCollectionFromShop}
      />
    </>
  );
};

export default memo(SalesCollectionTab);
