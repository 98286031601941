import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { CODE } from '~/constants/code';

export const useNotify = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const showError = (err: any, defaultMessage?: string) => {
    const message =
      err.code === CODE.ACTION_REJECTED
        ? 'form_validation.user_denied_signature'
        : err.code === CODE.CALL_EXCEPTION
        ? 'toast_message.network_not_match'
        : err?.graphQLErrors?.[0]?.extensions?.exception?.code === CODE.INSUFFICIENT_FUNDS
        ? 'toast_message.insufficient_funds_for_gas'
        : defaultMessage || err?.shortMessage || err.message || 'my_shop.message.error';
    const errorCode = err?.graphQLErrors?.[0]?.extensions?.originalError?.error;
    const errorMessage = t(`api_messages.${errorCode}`, t(message)).toString();

    enqueueSnackbar(errorMessage, { variant: 'error' });
  };
  const showErrorByKey = (messageKey: string) => {
    const message = t(messageKey);

    enqueueSnackbar(message, { variant: 'error' });
  };

  const showSuccess = (messageKey: string) => {
    const message = t(messageKey);
    enqueueSnackbar(message, { variant: 'success' });
  };

  return { showError, showSuccess, showErrorByKey };
};
