import Stack from '@mui/material/Stack';

import TransactionHistory from './TransactionHistory';
import UnpaidInvoices from './UnpaidInvoices';

const Billing = () => {
  return (
    <Stack gap="16px">
      <UnpaidInvoices />
      <TransactionHistory />
    </Stack>
  );
};

export default Billing;
