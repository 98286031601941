import { FC, useCallback, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import ImageNFTList from './ImageNFTList';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import PermissionsView from '~/components/PermissionsView';
import WrapperWithFab from '~/components/WrapperWithFab';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { MAX_FILE_SIZE, MAX_NUMBER } from '~/constants/common';
import { Collection } from '~/graphql/member/types';
import { useCheckPermissions } from '~/hooks/with-account';
import { CustomAnchorElProps } from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { GRANT_PERMISSION } from '~/types/my-shop';

export type CollectionData = {
  id: string;
  name: string;
  contractAddress: string;
  createdAt?: string;
  symbol: string;
  network: string;
};

export type MembershipData = {
  id: string;
  name: string;
  completionDate: string;
  timeLimit?: string;
  mintStatus: string;
};

interface LazyMintCollectionDetailProps {
  collectionData: (Collection & { status?: GRANT_PERMISSION }) | undefined;
}

const schema = yup.object({
  maxMint: yup.string().minNumber(1).maxNumber(MAX_NUMBER).required(),
  imageName: yup.string().max(100).required(),
  imageFiles: yup.mixed<FileList>().when('imageMock', {
    is: (value: string) => !value,
    then: yup
      .mixed<FileList>()
      .test('required', 'form_validation.isNotEmpty', (value) => (value ? Array.from(value).length !== 0 : false))
      .test('fileSize', 'form_validation.max_file_size', (value) => {
        if (value) {
          const files = Array.from(value);
          return files.every((file) => file.size < MAX_FILE_SIZE);
        }
        return true;
      }),
  }),
  imageMock: yup.string().notRequired().nullable(),
});

export interface FormValuesImageNFT extends yup.InferType<typeof schema> {}

const LazyMintCollectionDetail: FC<LazyMintCollectionDetailProps> = ({ collectionData }) => {
  const { t } = useTranslation();

  const [isEditImage, setIsEditImage] = useState<boolean>(false);
  const [openImageDialog, setOpenImageListDialog] = useState<boolean>(false);
  const [customAnchorEl, setCustomAnchorEl] = useState<CustomAnchorElProps>();

  useEffect(() => {
    document.title = t('collections');
  }, [t]);

  const {
    control,
    formState: { dirtyFields, errors },
    handleSubmit,
    reset,
  } = useForm<FormValuesImageNFT>({
    defaultValues: {
      maxMint: '',
      imageName: '',
      imageMock: undefined,
      imageFiles: undefined,
    },
    resolver: yupResolver(schema),
  });

  const onCloseDataGridMenu = useCallback(() => {
    setCustomAnchorEl(undefined);
  }, []);

  const onShowNFTDetail = useCallback(() => {
    setCustomAnchorEl(undefined);
  }, []);

  const onOpenImageDialog = useCallback(() => {
    reset();
    setIsEditImage(false);
    setOpenImageListDialog(true);
  }, [reset]);

  const [isCanViewBtnCreate] = useCheckPermissions([SCREEN_PERMISSION.COLLECTIONS.MINT_ON_PURCHASE.ADD]);

  return (
    <WrapperWithFab onClick={onOpenImageDialog} hidden={!isCanViewBtnCreate}>
      <CustomCardTable
        cardTitle={t('mint_on_purchase')}
        headerAction={
          <PermissionsView roles={SCREEN_PERMISSION.COLLECTIONS.MINT_ON_PURCHASE.ADD}>
            <HeaderAction menus={[{ title: t('collection_screen.add_image'), onClick: onOpenImageDialog }]} />
          </PermissionsView>
        }
        cardContent={
          <ImageNFTList
            errors={errors}
            control={control}
            isEditImage={isEditImage}
            collectionInfo={collectionData}
            openImageDialog={openImageDialog}
            isDirty={!!Object.keys(dirtyFields).length}
            reset={reset}
            handleSubmit={handleSubmit}
            setIsEditImage={setIsEditImage}
            onOpenImageDialog={onOpenImageDialog}
            setOpenImageListDialog={setOpenImageListDialog}
          />
        }
      />
      <Menu anchorEl={customAnchorEl?.anchorEl} open={!!customAnchorEl?.anchorEl} onClose={onCloseDataGridMenu}>
        <MenuItem onClick={onShowNFTDetail}>{t('collection_screen.show_nft_detail')}</MenuItem>
      </Menu>
    </WrapperWithFab>
  );
};

export default LazyMintCollectionDetail;
