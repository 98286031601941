import { FC, useState } from 'react';

import AddIcon from '@mui/icons-material/Add';
import { Card, CardContent } from '@mui/material';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

import PublicLayout from '../app-layout/public-layout';
import CreateOrganizationDialog from '../create-organization-dialog';
import ScreenLoading from '../screen-loading';

import OrganizationCard from './OrganizationCard';
import { useSelectOrganizationStyles } from './styles';

import { OrganizationRoleUser } from '~/graphql/member/types';

interface ISelectOrganization {
  organizations: OrganizationRoleUser[];
  onSelectedOrganization: (uuid: string, isCreatingOrg?: boolean) => void;
}

const SelectOrganization: FC<ISelectOrganization> = ({ organizations, onSelectedOrganization }) => {
  const { t } = useTranslation();
  const { classes } = useSelectOrganizationStyles();

  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false);

  const onCloseCreateOrganizationDialog = () => {
    setOpenCreateOrganizationDialog(false);
  };

  const onOpenCreateOrganizationDialog = async () => {
    setOpenCreateOrganizationDialog(true);
  };

  if (organizations.length === 1) {
    const firstOrg = organizations[0];
    onSelectedOrganization(firstOrg.uuid);
    return <ScreenLoading />;
  }

  return (
    <PublicLayout>
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <div className={classes.header}>
            <Typography variant="h6" marginBottom="8px">
              {t('select_your_organization')}
            </Typography>
            <Typography variant="body2">{t('select_your_organization_desc')}</Typography>
          </div>
          <Stack gap="20px">
            <Box gap="20px" maxHeight="265px" overflow="auto">
              {organizations.map((org) => (
                <OrganizationCard key={org.uuid} organization={org} onSelectedOrganization={onSelectedOrganization} />
              ))}
            </Box>
            <Card className={classes.orgCard} onClick={onOpenCreateOrganizationDialog}>
              <CardContent>
                <Box className={classes.selectOrgIcon}>
                  <AddIcon />
                </Box>
                <Typography variant="body1">{t('create_organization_dialog.title')}</Typography>
              </CardContent>
            </Card>
          </Stack>
        </Paper>
        <CreateOrganizationDialog
          open={openCreateOrganizationDialog}
          onClose={onCloseCreateOrganizationDialog}
          onSelectedOrganization={onSelectedOrganization}
        />
      </Box>
    </PublicLayout>
  );
};

export default SelectOrganization;
