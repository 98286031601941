import { FC, PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

import { ApolloQueryResult } from '@apollo/client';
import Box from '@mui/material/Box';
import { Outlet, useNavigate, useParams, useLocation } from 'react-router-dom';

import UserLayout from '~/components/app-layout/user-layout';
import LoaderCenter from '~/components/loader-center';
import { MemberSiteFormEnum, ShopFormEnum } from '~/enum/pages/my-shop';
import {
  Currency,
  ExchangeCurrencyQuery,
  GetMyShopQuery,
  GetPublishShopQuery,
  useExchangeCurrencyQuery,
  useGetMyShopQuery,
  useGetPublishShopQuery,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { SHOP_TYPE } from '~/types/my-shop';
import { notFound } from '~/utils/not-found';

export type MyShopResponse = GetMyShopQuery[keyof GetMyShopQuery];

export interface ShopDetailContextValue {
  loading: boolean;
  isMemberSite: boolean;
  exchangeRate?: number;
  data: GetMyShopQuery['getMyShop'];
  editingAt: ShopFormEnum | MemberSiteFormEnum | undefined;
  publishShopData: GetPublishShopQuery['getPublishShop'] | undefined;
  refetchExchangeRate: () => Promise<ApolloQueryResult<ExchangeCurrencyQuery>>;
  handleEditingAt: (tab: ShopFormEnum | MemberSiteFormEnum | undefined) => void;
}

const ShopDetailContext = createContext<ShopDetailContextValue>({} as any);
export const useShopDetail = () => useContext(ShopDetailContext);

const ShopDetailWrapper = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { isLocked } = useAccount();
  const { showErrorByKey } = useNotify();

  const [editingAt, setEditingAt] = useState<ShopFormEnum | MemberSiteFormEnum | undefined>();

  const { data: myShopDataRes, loading: loadingMyShop } = useGetMyShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
    onError: (err) => {
      notFound(Object.values(err)?.[0]?.[0]?.extensions?.code, navigate);
    },
  });
  const { data: publishShopRes } = useGetPublishShopQuery({
    fetchPolicy: 'no-cache',
    variables: {
      myShopUuid: id ?? '',
    },
  });
  const { data: exchangeRateRes, refetch: refetchExchangeRate } = useExchangeCurrencyQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        from: Currency.Jpy,
        to: Currency.Usd,
      },
    },
  });

  const handleEditingAt = (tab: ShopFormEnum | MemberSiteFormEnum | undefined) => {
    if (isLocked && !!tab) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }
    setEditingAt(tab);
  };

  const exchangeRate = exchangeRateRes?.exchangeCurrency;
  const publishShopData = publishShopRes?.getPublishShop;
  const myShop = useMemo(() => myShopDataRes?.getMyShop, [myShopDataRes?.getMyShop]);

  return (
    <Wrapper>
      <ShopDetailContext.Provider
        value={{
          editingAt,
          exchangeRate,
          data: myShop!,
          publishShopData,
          isMemberSite: myShop?.shopType === SHOP_TYPE.MEMBER,
          loading: loadingMyShop,
          handleEditingAt,
          refetchExchangeRate,
        }}
      >
        {loadingMyShop ? (
          <Box width="100%" height="100%">
            <LoaderCenter />
          </Box>
        ) : (
          <Outlet />
        )}
      </ShopDetailContext.Provider>
    </Wrapper>
  );
};

const Wrapper: FC<PropsWithChildren> = ({ children }) => {
  const location = useLocation();

  const isEditShop = /edit-(shop|member-site)$/.test(location.pathname);

  if (isEditShop && !!children) {
    return <>{children}</>;
  }

  return <UserLayout>{children}</UserLayout>;
};

export default ShopDetailWrapper;
