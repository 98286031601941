import { useEffect } from 'react';

import { useRoutes, useLocation, RouteObject } from 'react-router-dom';

import PermissionsView from '../components/PermissionsView';

import { SCREEN_PERMISSION } from '~/config/roleConfig';
import MyShopFormWrapper from '~/contexts/MyShopFormWrapper';
import PrivateWrapper from '~/contexts/PrivateWrapper';
import ShopDetailWrapper from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { WithFirebaseUser } from '~/hooks/with-firebase-auth';
import AboutPage from '~/pages/about';
import CollectionsPage from '~/pages/collection';
import CollectionDetail from '~/pages/collection/CollectionDetail';
import EditShop from '~/pages/edit-shop';
import ErrorPage from '~/pages/error';
import HomePage from '~/pages/home';
import MyShop from '~/pages/my-shop';
import CreateMemberSite from '~/pages/my-shop/create-member-site';
import CreateShop from '~/pages/my-shop/create-shop';
import MemberSiteDetail from '~/pages/my-shop/member-site-detail';
import WrapperMemberSiteCollectionDetail from '~/pages/my-shop/member-site-detail/components/CollectionDetail/WrapperMemberSiteCollectionDetail';
import ShopDetail from '~/pages/my-shop/shop-detail';
import ShopCollectionDetail from '~/pages/my-shop/shop-detail/components/CollectionDetail/ShopCollectionDetail';
import Plans from '~/pages/plans';
import SettingPage from '~/pages/setting';
import SignInPage from '~/pages/sign-in';

const { SHOPS, MEMBER_SITES, SETTING, PLANS } = SCREEN_PERMISSION;

export const routerConfig: RouteObject[] = [
  {
    path: '/about',
    element: <AboutPage />,
  },
  {
    path: AppRouteEnum.SignIn,
    element: (
      <WithFirebaseUser>
        <SignInPage />
      </WithFirebaseUser>
    ),
  },
  {
    path: AppRouteEnum.Home,
    element: <PrivateWrapper />,
    children: [
      {
        index: true,
        element: <HomePage />,
      },
      {
        path: AppRouteEnum.Plans.replace(/\//g, ''),
        element: (
          <PermissionsView roles={PLANS.VIEW} children403={<ErrorPage code="403" />}>
            <Plans />
          </PermissionsView>
        ),
      },
      {
        path: AppRouteEnum.MyShop.replace(/\//g, ''),
        element: <PermissionsView roles={SHOPS.VIEW} children403={<ErrorPage code="403" />} />,
        children: [
          {
            index: true,
            element: <MyShop />,
          },
          {
            path: AppRouteEnum.CreateShop.replace(/\/my-shop\//g, ''),
            element: (
              <MyShopFormWrapper>
                <CreateShop />
              </MyShopFormWrapper>
            ),
          },
          {
            path: AppRouteEnum.ShopDetail.replace(/\/my-shop\//g, ''),
            element: <ShopDetailWrapper />,
            children: [
              {
                index: true,
                element: (
                  <MyShopFormWrapper>
                    <ShopDetail />
                  </MyShopFormWrapper>
                ),
              },
              {
                path: AppRouteEnum.EditShop.replace(/\/my-shop\/:id\//g, ''),
                element: <EditShop />,
              },
              {
                path: AppRouteEnum.ShopCollectionDetail.replace(/\/my-shop\/:id\/collection\/:collection-id\//g, ''),
                element: <ShopCollectionDetail />,
              },
            ],
          },
        ],
      },
      {
        path: AppRouteEnum.MemberSite.replace(/\//g, ''),
        element: <PermissionsView roles={MEMBER_SITES.VIEW} children403={<ErrorPage code="403" />} />,
        children: [
          {
            index: true,
            element: <MyShop />,
          },
          {
            path: AppRouteEnum.CreateMemberSite.replace(/\/member-site\//g, ''),
            element: (
              <MyShopFormWrapper>
                <CreateMemberSite />
              </MyShopFormWrapper>
            ),
          },
          {
            path: AppRouteEnum.MemberSiteDetail.replace(/\/member-site\//g, ''),
            element: <ShopDetailWrapper />,
            children: [
              {
                index: true,
                element: (
                  <MyShopFormWrapper>
                    <MemberSiteDetail />
                  </MyShopFormWrapper>
                ),
              },
              {
                path: AppRouteEnum.EditMemberSite.replace(/\/member-site\/:id\//g, ''),
                element: <EditShop />,
              },
              {
                path: AppRouteEnum.MemberSiteCollectionDetail.replace(
                  /\/member-site\/:id\/collection\/:collection-id\//g,
                  ''
                ),
                element: <WrapperMemberSiteCollectionDetail />,
              },
            ],
          },
        ],
      },
      {
        path: AppRouteEnum.Collection.replace(/\//g, ''),
        children: [
          {
            index: true,
            element: <CollectionsPage />,
          },
          {
            path: AppRouteEnum.CollectionDetail.replace(/\/collection\//g, ''),
            element: <CollectionDetail />,
          },
        ],
      },
      {
        path: AppRouteEnum.Setting.replace(/\//g, ''),
        element: (
          <PermissionsView roles={SETTING.VIEW} children403={<ErrorPage code="403" />}>
            <SettingPage />
          </PermissionsView>
        ),
      },
    ],
  },
  {
    path: '*',
    element: <ErrorPage code="404" />,
  },
];

const AppRouter = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return useRoutes(routerConfig);
};

export default AppRouter;
