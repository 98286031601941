import { FC, Fragment, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import LinkButton from '~/components/link-button';
import { colors } from '~/constants/colors';
import { CARD_IMAGE } from '~/constants/common';
import { Currency } from '~/graphql/member/types';
import { CustomInvoiceType } from '~/types/billing';
import { priceWithSymbol, replaceLicenseName } from '~/utils/common';

interface CollapsibleRowProps {
  row: CustomInvoiceType;
  listStatus: Record<string, { color: string | undefined; label: string; icon: JSX.Element }>;
}

const useStyles = makeStyles()(() => ({
  invoiceDetail: {
    padding: '0px 0px 20px',
    '.MuiTableCell-root': {
      fontSize: '15px',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
}));

const CollapsibleRow: FC<CollapsibleRowProps> = ({ row, listStatus }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [open, setOpen] = useState(false);

  const status = listStatus[row.status as keyof typeof listStatus] || listStatus.processing;
  const paymentMethod = row.paymentIntent?.paymentMethod;
  const path = CARD_IMAGE[paymentMethod?.brand as keyof typeof CARD_IMAGE];

  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell>{priceWithSymbol(row.total, row.currency as Currency, 2)}</TableCell>
        <TableCell>
          <Chip icon={status?.icon} label={status?.label} color={status?.color as 'success'} />
        </TableCell>
        <TableCell>
          <Box display="flex" gap="8px" alignItems="center">
            {path ? <img width="30px" alt="" src={path} /> : null}
            <Typography>**** {paymentMethod?.last4}</Typography>
          </Box>
        </TableCell>
        <TableCell>{row.createdAt ? moment(row.createdAt).format(t('date_time_format')) : '-'}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: 0 }} colSpan={2} />
        <TableCell sx={{ padding: 0 }} colSpan={4}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box className={classes.invoiceDetail}>
              <Table size="small" aria-label="purchases" sx={{ fontSize: '16px' }}>
                <TableHead>
                  <TableRow>
                    <TableCell width="50%">{t('description')}</TableCell>
                    <TableCell>{t('unit')}</TableCell>
                    <TableCell>{t('quantity')}</TableCell>
                    <TableCell align="right">{t('settings.amount')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.items.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell component="th" scope="row">
                        {replaceLicenseName(item.description, t)}
                      </TableCell>
                      <TableCell>
                        {priceWithSymbol(item.unitAmountExcludingTax, item.currency as Currency, 2)}
                      </TableCell>
                      <TableCell>{item.quantity}</TableCell>
                      <TableCell align="right">
                        {priceWithSymbol(item.amountExcludingTax, item.currency as Currency, 2)}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell colSpan={3} sx={{ fontWeight: 700 }}>
                      {t('subtotal')}
                    </TableCell>
                    <TableCell align="right" sx={{ fontWeight: 700 }}>
                      {priceWithSymbol(row.subtotalExcludingTax, row.currency as Currency, 2)}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3}>{t('tax')}</TableCell>
                    <TableCell align="right">{priceWithSymbol(row.tax, row.currency as Currency, 2)}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={3} sx={{ fontWeight: 700, color: colors.primary }}>
                      {t('total')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 700, color: colors.primary }} align="right">
                      {priceWithSymbol(row.total, row.currency as Currency, 2)}
                    </TableCell>
                  </TableRow>
                  {(row.invoicePdf || row.receiptUrl) && (
                    <TableRow>
                      <TableCell colSpan={4} align="right" sx={{ borderBottom: 'none' }}>
                        {row.invoicePdf && (
                          <LinkButton
                            size="small"
                            variant="contained"
                            href={row.invoicePdf}
                            download="invoice.pdf"
                            startIcon={<DownloadIcon />}
                          >
                            {t('settings.billing.invoice_pdf')}
                          </LinkButton>
                        )}
                        {row.receiptUrl && (
                          <LinkButton
                            size="small"
                            variant="contained"
                            download="invoice.pdf"
                            sx={{ marginLeft: 2 }}
                            startIcon={<DownloadIcon />}
                            href={row.receiptUrl.replace(/(\?s=ap)$/, '/pdf$1')}
                          >
                            {t('settings.billing.receipt_pdf')}
                          </LinkButton>
                        )}
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

export default CollapsibleRow;
