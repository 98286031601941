export enum ShopFormEnum {
  BASIC = 'BASIC',
  LEGAL = 'LEGAL',
  ADVANCED = 'ADVANCED',
  COLLECTION = 'COLLECTION',
}

export enum MemberSiteFormEnum {
  TERMS = 'TERMS',
  BASIC = 'BASIC',
  ADVANCED = 'ADVANCED',
  COLLECTION = 'COLLECTION',
  REGISTRATION = 'REGISTRATION',
}
