import { FC } from 'react';

import Box from '@mui/material/Box';

import BaseCurrency from './BaseCurrency';
import Payment from './Payment';
import PaymentMethods from './PaymentMethods';

const PaymentTab: FC = () => {
  return (
    <Box gap="24px" display="flex" flexDirection="column">
      <PaymentMethods />
      <BaseCurrency />
      <Payment />
    </Box>
  );
};

export default PaymentTab;
