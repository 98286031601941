import {
  ChangeEvent,
  FC,
  MouseEventHandler,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef, jaJP, GridSortModel, GridRenderCellParams, GridTreeNodeWithRender } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import AddUserDialog from '~/components/add-user-dialog';
import ChangeUserRole from '~/components/change-user-role';
import CustomCardTable from '~/components/custom-card-table';
import CustomGridToolbarSearchByAPI from '~/components/custom-grid-toolbar-search-by-api';
import CustomDataGrid from '~/components/CustomDataGrid';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import InsufficientLicenseDialog from '~/components/dialog/insufficient-license-dialog';
import LicenseStatementDialog from '~/components/dialog/license-statement-dialog';
import CustomNodata from '~/components/no-data';
import WrapperWithFab from '~/components/WrapperWithFab';
import { MEMBER_ACTIONS, SCREEN_PERMISSION } from '~/config/roleConfig';
import { usePaymentMethodRequired } from '~/contexts/PaymentMethodRequired';
import {
  LicenseType,
  OrganizationUser,
  OrganizationUserRole,
  useGetInfoUsageLazyQuery,
  useListOrganizationUsersQuery,
  useRemoveOrganizationUserMutation,
} from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { StyledComponentProps } from '~/types/material-ui';

const { MEMBER } = SCREEN_PERMISSION.SETTING;

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiDataGrid-root': {
      border: 'none',
    },
    '.MuiDataGrid-toolbarContainer': {
      padding: '0',
      margin: '0',
    },
  },
}));

export interface IOrganizationUser extends OrganizationUser {
  id: string;
}

interface Props extends StyledComponentProps<typeof useStyles> {}

const rolesList = (t: TFunction) => ({
  [OrganizationUserRole.Member]: {
    label: t('role_options.member'),
    value: OrganizationUserRole.Member,
  },
  [OrganizationUserRole.Admin]: {
    label: t('role_options.admin'),
    value: OrganizationUserRole.Admin,
  },
  [OrganizationUserRole.Owner]: {
    label: t('role_options.owner'),
    value: OrganizationUserRole.Owner,
  },
  [OrganizationUserRole.Viewer]: {
    label: t('role_options.viewer'),
    value: OrganizationUserRole.Viewer,
  },
});

const ListTOrganizationUsers: React.FC<Props> = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { account, selectedOrganization } = useAccount();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 1000);
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [selectedUser, setSelectedUser] = useState<IOrganizationUser>();
  const [openRemoveUserDialog, setOpenRemoveUserDialog] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openChangeUserRoleDialog, setOpenChangeUserRoleDialog] = useState(false);

  const [editable, deletable] = useCheckPermissions([MEMBER.EDIT, MEMBER.DELETE]);

  const {
    data: listOrganizationUsersData,
    loading: loadingListOrganizationUsers,
    refetch,
  } = useListOrganizationUsersQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [removeOrganizationUser] = useRemoveOrganizationUserMutation();

  const rows: IOrganizationUser[] = useMemo(
    () =>
      (listOrganizationUsersData?.listOrganizationUsers || [])
        .map((user) => ({
          email: user?.email,
          id: user?.uid || '',
          uid: user?.uid || '',
          displayName: user?.displayName,
          role: user?.role || OrganizationUserRole.Member,
        }))
        .filter((i) => (i.displayName || '').toLowerCase().includes((debounceValue || '').toLowerCase())),
    [listOrganizationUsersData, debounceValue]
  );

  const handleClickMoreMenu = useCallback(
    (row: IOrganizationUser): MouseEventHandler<HTMLButtonElement> =>
      (event) => {
        setAnchorEl(event.currentTarget);
        setSelectedUser(row);
      },
    []
  );

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    localStorage.setItem('memberRegisted', JSON.stringify(model));
  };

  const checkDisabled = useCallback(
    (user: OrganizationUser) => {
      if (user.email === account.email) return true;

      // Check if the current role has permissions for this user
      return (
        !MEMBER_ACTIONS.EDIT[selectedOrganization.role]?.includes(user.role) &&
        !MEMBER_ACTIONS.DELETE[selectedOrganization.role]?.includes(user.role)
      );
    },
    [account, selectedOrganization?.role]
  );

  useEffect(() => {
    const storedSortModel = localStorage.getItem('memberRegisted');
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  const columns = useMemo<GridColDef<IOrganizationUser>[]>(
    () => [
      {
        field: 'displayName',
        headerName: t('full_name'),
        minWidth: 200,
      },
      {
        width: 100,
        field: 'role',
        headerName: t('role'),
        renderCell: (params) => <Typography>{rolesList(t)[params.row.role as OrganizationUserRole]?.label}</Typography>,
      },
      {
        width: 300,
        flex: 1,
        field: 'email',
        headerName: t('email_address'),
      },
      ...(editable || deletable
        ? [
            {
              width: 50,
              field: t('actions'),
              type: 'actions',
              disableReorder: true,
              headerName: '',
              renderCell: (params: GridRenderCellParams<IOrganizationUser, any, any, GridTreeNodeWithRender>) => {
                return [
                  <IconButton
                    key="1"
                    size="small"
                    disabled={checkDisabled(params.row)}
                    onClick={handleClickMoreMenu(params.row)}
                  >
                    <MoreVertIcon />
                  </IconButton>,
                ];
              },
            },
          ]
        : []),
    ],
    [t, editable, deletable, checkDisabled, handleClickMoreMenu]
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleOpenRemoveUseDialog = () => {
    handleCloseMenu();
    setOpenRemoveUserDialog(true);
  };

  const onCloseRemoveUserDialog = useCallback(async () => {
    setOpenRemoveUserDialog(false);
  }, []);

  const handleOpenChangeUseRoleDialog = () => {
    handleCloseMenu();
    setOpenChangeUserRoleDialog(true);
  };

  const handleCloseChangeUseRoleDialog = useCallback(async () => {
    setOpenChangeUserRoleDialog(false);
  }, []);

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const toolbarProps = {
    search,
    searchLabel: t('full_name'),
    handleSearch,
  };

  const handleConfirm = async () => {
    try {
      await removeOrganizationUser({
        variables: {
          input: {
            uid: selectedUser?.uid ?? '',
          },
        },
      });
      enqueueSnackbar(t('toast_message.removed_member'), {
        variant: 'success',
      });
      refetch();
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    return () => {
      setSearch('');
    };
  }, [i18n.language]);

  return (
    <Box className={classes.wrapper}>
      <Menu
        id="basic-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {selectedUser && MEMBER_ACTIONS.DELETE[selectedOrganization.role]?.includes(selectedUser.role) && (
          <MenuItem onClick={handleOpenRemoveUseDialog} data-testid="DeleteMenu">
            {t('delete')}
          </MenuItem>
        )}
        {selectedUser && MEMBER_ACTIONS.EDIT[selectedOrganization.role]?.includes(selectedUser.role) && (
          <MenuItem onClick={handleOpenChangeUseRoleDialog} data-testid="ChangeRoleMenu">
            {t('settings.change_role')}
          </MenuItem>
        )}
      </Menu>
      <CheckMemberRole>
        <CustomCardTable
          cardTitle={t('settings.member')}
          cardContent={
            <CustomDataGrid
              autoHeight
              pagination
              rows={rows}
              columns={columns}
              loading={loadingListOrganizationUsers}
              initialState={{
                pagination: {
                  paginationModel: { pageSize: 5, page: 0 },
                },
              }}
              sortModel={sortModel}
              onSortModelChange={handleSortModelChange}
              pageSizeOptions={[5]}
              disableVirtualization
              disableRowSelectionOnClick
              slotProps={{
                toolbar: toolbarProps,
                noRowsOverlay: {
                  message: t('no_data_available'),
                },
              }}
              localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
              slots={{
                toolbar: CustomGridToolbarSearchByAPI,
                noRowsOverlay: CustomNodata,
                noResultsOverlay: CustomNodata,
              }}
            />
          }
        />
      </CheckMemberRole>
      <ChangeUserRole
        userInfo={selectedUser}
        open={openChangeUserRoleDialog}
        onClose={handleCloseChangeUseRoleDialog}
      />
      <ConfirmationDialog
        open={openRemoveUserDialog}
        title={t('delete_member.title')}
        onConfirm={handleConfirm}
        onClose={onCloseRemoveUserDialog}
      />
    </Box>
  );
};

const CheckMemberRole: FC<PropsWithChildren> = ({ children }) => {
  const { show } = usePaymentMethodRequired();
  const { plan: currentPlan, selectedOrganization } = useAccount();

  const [isChecking, setIsChecking] = useState(false);
  const [openWarningDialog, setOpenWarningDialog] = useState(false);
  const [openAddUserDialog, setOpenAddUserDialog] = useState(false);
  const [openLicenseStatement, setOpenLicenseStatement] = useState(false);

  const [getInfoUsage] = useGetInfoUsageLazyQuery({
    fetchPolicy: 'cache-and-network',
  });

  // Handle Open Warning Dialog
  const handleOpenWarningDialog = () => {
    setOpenWarningDialog(true);
  };
  const handleCloseWarningDialog = async () => {
    setOpenWarningDialog(false);
  };

  // Handle Open License Statement
  const handleOpenLicenseStatement = () => {
    handleCloseWarningDialog();
    setOpenLicenseStatement(true);
  };
  const handleCloseLicenseStatement = async () => {
    setOpenLicenseStatement(false);
  };

  const onOpenAddUserDialog = async () => {
    setIsChecking(true);
    const infoUsageRes = await getInfoUsage();
    const infoUsage = infoUsageRes.data?.getInfoUsage;
    if (!infoUsage) {
      setIsChecking(false);
      return;
    }
    const enoughLicenses = infoUsage.numberUser < infoUsage.userLicenses;
    if (enoughLicenses) {
      setOpenAddUserDialog(true);
      setIsChecking(false);
      return;
    }
    const notEnoughFreeLicenses = infoUsage.userLicenses >= (currentPlan?.numberOfAdminUsers || 0);
    if (notEnoughFreeLicenses) {
      const openSuccess = await show({
        title: 'license_limit_reached',
        description: 'users_reached_limit_miss_payment',
      });
      if (openSuccess) {
        setIsChecking(false);
        return;
      }
    }
    handleOpenWarningDialog();
    setIsChecking(false);
  };

  const onCloseAddUserDialog = useCallback(() => {
    setOpenAddUserDialog(false);
  }, []);

  const addable = MEMBER_ACTIONS.ADD[selectedOrganization.role].length > 0;

  const licenseInfo = {
    quantity: 1,
    type: LicenseType.AdditionalUserFee,
  };

  return (
    <>
      <WrapperWithFab hidden={!addable} isLoading={isChecking} onClick={onOpenAddUserDialog}>
        {children}
      </WrapperWithFab>
      <AddUserDialog open={openAddUserDialog} onClose={onCloseAddUserDialog} />
      <InsufficientLicenseDialog
        open={openWarningDialog}
        type={LicenseType.AdditionalUserFee}
        onClose={handleCloseWarningDialog}
        onSubmit={handleOpenLicenseStatement}
      />
      <LicenseStatementDialog
        license={licenseInfo}
        open={openLicenseStatement}
        onNext={onOpenAddUserDialog}
        onClose={handleCloseLicenseStatement}
      />
    </>
  );
};

export default ListTOrganizationUsers;
