import { useMemo, useRef, useState } from 'react';

import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { MenuItem, Select, SelectChangeEvent, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import MonthlyTransactions from './MonthlyTransactions';
import UpcomingInvoice from './UpcomingInvoice';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import { INVOICE_ITEM_NAME } from '~/constants/common';
import { InvoiceType } from '~/enum/common';
import { useGetNextInvoiceQuery, useGetPlanLazyQuery, useListAllInvoicesLazyQuery } from '~/graphql/member/types';
import useExportCSV from '~/hooks/useExportCSV';
import { useNotify } from '~/hooks/useNotify';
import { IPageModel, ITransactionHistoryRef } from '~/interfaces/billing';

const useStyles = makeStyles()(({ breakpoints }) => ({
  wrapper: {
    '.MuiCardHeader-action': {
      gap: '8px',
      display: 'flex',
      alignItems: 'center',
      '.MuiInputBase-root': {
        height: '38px',
      },
      [breakpoints.down('sm')]: {
        width: '100%',
        '.MuiTypography-root': {
          display: 'none',
        },
        '.MuiInputBase-root': {
          flex: 1,
        },
      },
    },
  },
  wrapperContent: {
    '.MuiDataGrid-root .MuiDataGrid-footerContainer .MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const initPageModel = {
  page: 0,
  pageParams: {},
};

const TransactionHistory = () => {
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { t, i18n } = useTranslation();
  const { exportToCSV } = useExportCSV();

  const [pageModel, setPageModel] = useState<IPageModel>(initPageModel);
  const [monthlyFilter, setMonthlyFilter] = useState<string>(moment().format('YYYY/MM'));

  const ref = useRef<ITransactionHistoryRef>(null);

  const { data: upcomingInvoiceRes } = useGetNextInvoiceQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [getPlan] = useGetPlanLazyQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [listAllInvoices] = useListAllInvoicesLazyQuery({
    fetchPolicy: 'cache-and-network',
    variables: {
      input: {
        createdGte: moment(monthlyFilter, 'YYYY/MM').toDate(),
        createdLte: moment(monthlyFilter, 'YYYY/MM').endOf('month').toDate(),
      },
    },
  });

  const upcomingInvoice = upcomingInvoiceRes?.getNextInvoice;

  const monthOptions = useMemo(() => {
    const result = !upcomingInvoice?.length
      ? []
      : [
          <MenuItem key="upcoming-invoice" value="upcoming-invoice">
            {t('settings.billing.upcoming_invoice')}
          </MenuItem>,
        ];
    const monthlyList = Array.from({ length: 13 }, (_, i) => {
      const value = moment().subtract(i, 'months').format('YYYY/MM');
      return (
        <MenuItem key={value} value={value}>
          {value}
        </MenuItem>
      );
    });
    return result.concat(monthlyList);
  }, [upcomingInvoice, t]);

  const handleSelect = (event: SelectChangeEvent<string>) => {
    setPageModel(initPageModel);
    setMonthlyFilter(event.target.value);
  };

  const handleExportMonth = async () => {
    try {
      const allInvoicesRes = await listAllInvoices();
      const allInvoices = allInvoicesRes.data?.listAllInvoices || [];
      const exportData = [];
      const plansList: Record<string, string> = {};
      for (let invoice of allInvoices) {
        const metadata = invoice.metadata;
        const type = metadata?.type as InvoiceType;
        let name = t(INVOICE_ITEM_NAME[type]);

        if (type === InvoiceType.PurchasePlan && !!metadata?.uuid) {
          if (!plansList[metadata.uuid]) {
            const planRes = await getPlan({
              variables: {
                uuid: metadata.uuid,
              },
            });
            plansList[metadata.uuid] = planRes.data?.getPlan.planName || '';
          }
          const planName = plansList[metadata.uuid];
          if (!!planName) {
            moment.locale(i18n.language);
            const nextMonth = moment().add(1, 'month').format('MMMM');
            const isRenew = invoice.billingReason === 'subscription_cycle';

            name = isRenew
              ? t('settings.billing.renew_plan_desc', { planName, month: nextMonth })
              : t('settings.billing.register_plan', { planName });

            moment.locale('en');
          }
        }
        exportData.push({
          'Invoice Number': invoice.number,
          Description: name,
          Status: invoice.status,
          Currency: invoice.currency,
          Total: invoice.total,
          Tax: invoice.tax,
          Date: moment(invoice.createdAt).format(t('date_time_format')),
        });
      }
      exportToCSV(exportData, `all-invoices-${moment(monthlyFilter).format('MM-YYYY')}.csv`);
    } catch (err) {
      showError(err);
    }
  };

  const handleExportCurrentPage = () => {
    if (ref.current) {
      ref.current.handleExportCurrentPage();
    }
  };

  return (
    <Box className={classes.wrapper}>
      <CustomCardTable
        cardTitle={t('settings.billing.transaction_history')}
        headerAction={
          <>
            <Typography>{t('month')}:</Typography>
            <Select id="selector" value={monthlyFilter} onChange={handleSelect}>
              {monthOptions}
            </Select>
            <HeaderAction
              icon={<FileDownloadIcon />}
              menus={
                monthlyFilter === 'upcoming-invoice'
                  ? [{ title: t('settings.billing.export_upcoming_invoice'), onClick: handleExportCurrentPage }]
                  : [
                      { title: t('settings.billing.export_current_page'), onClick: handleExportCurrentPage },
                      { title: t('settings.billing.export_month'), onClick: handleExportMonth },
                    ]
              }
            />
          </>
        }
        cardContent={
          <Box className={classes.wrapperContent}>
            {monthlyFilter === 'upcoming-invoice' ? (
              <UpcomingInvoice ref={ref} upcomingInvoices={upcomingInvoice!} />
            ) : (
              <MonthlyTransactions
                ref={ref}
                pageModel={pageModel}
                filterValue={monthlyFilter}
                onPageModel={setPageModel}
              />
            )}
          </Box>
        }
      />
    </Box>
  );
};

export default TransactionHistory;
