import { SyntheticEvent, useState } from 'react';

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import TextField from '@mui/material/TextField';
import { Controller, Path } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import 'react-quill/dist/quill.snow.css';

import TextEditor from '~/components/TextEditor';
import { MAX_LENGTH } from '~/constants/common';
import { FormEditShopValues, useEditShop } from '~/pages/edit-shop';

interface InputWithLanguageProps<T extends FormEditShopValues> {
  show: boolean;
  pageTitle: Path<T>;
  description: Path<T>;
}

enum LANGUAGE {
  ENGLISH = 'ENGLISH',
  JAPANESE = 'JAPANESE',
}

const useStyles = makeStyles()(() => ({
  naviSection: {
    marginBottom: 16,
  },
}));

const EditNavi: React.FC = () => {
  const { handleSubmit } = useEditShop();
  const { t } = useTranslation();

  const [tab, setTab] = useState(LANGUAGE.ENGLISH);

  const handleChangeTab = (_: SyntheticEvent<Element, Event>, tab: any) => {
    handleSubmit(() => setTab(tab))();
  };

  return (
    <div>
      <Box sx={{ marginBottom: '16px', borderBottom: 1, borderColor: 'divider' }}>
        <Tabs value={tab} onChange={handleChangeTab}>
          <Tab label={t('english')} value={LANGUAGE.ENGLISH} />
          <Tab label={t('japanese')} value={LANGUAGE.JAPANESE} />
        </Tabs>
      </Box>
      <Box>
        <InputWithLanguage pageTitle="navi.pageTitle" description="navi.description" show={tab === LANGUAGE.ENGLISH} />
        <InputWithLanguage
          pageTitle="navi.pageTitleJa"
          description="navi.descriptionJa"
          show={tab === LANGUAGE.JAPANESE}
        />
      </Box>
    </div>
  );
};

const InputWithLanguage = <T extends FormEditShopValues>(props: InputWithLanguageProps<T>) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { control, errors } = useEditShop();

  if (!props.show) {
    return <></>;
  }

  const pageTitleError = () => {
    const separation = props.pageTitle.split('.');
    return errors?.[separation[0] as 'navi']?.[separation[1] as 'pageTitle']?.message;
  };

  const descriptionError = () => {
    const separation = props.description.split('.');
    return errors?.[separation[0] as 'navi']?.[separation[1] as 'description']?.message;
  };

  return (
    <>
      <Box className={classes.naviSection}>
        <Controller
          control={control}
          name={props.pageTitle}
          render={({ field }) => (
            <TextField
              required
              rows={3}
              fullWidth
              multiline
              margin="dense"
              label={t('my_shop.edit_design.title_page_title')}
              error={!!pageTitleError()}
              placeholder={t('my_shop.edit_design.title_page_title')}
              helperText={t(pageTitleError() as any)}
              {...field}
            />
          )}
        />
      </Box>
      <Box className={classes.naviSection}>
        <TextEditor
          control={control}
          heightEditor={150}
          maxLength={MAX_LENGTH}
          name={props.description}
          label={t('description')}
          error={descriptionError()}
        />
      </Box>
    </>
  );
};

export default EditNavi;
