import { FC, memo, MouseEventHandler, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useIpfsGateways } from '@gu-corp/react-ipfs-media';
import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount as useWalletAccount, WalletConnectorDialog } from '@gusdk/gu-wallet-connector';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridColDef } from '@mui/x-data-grid';
import { JsonRpcProvider } from 'ethers';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import AddNFTDialog from '~/components/add-nft-dialog';
import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import MintedNFTDialog from '~/components/dialog/minted-nft-dialog';
import { GalleryData } from '~/components/gallery-list';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import ListTable, { IHandleListTable } from '~/components/list-table';
import PermissionsView from '~/components/PermissionsView';
import SquareImage from '~/components/SquareImage';
import WrapperWithFab from '~/components/WrapperWithFab';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { VIEW_MODE, ContextAPIEnum } from '~/enum/common';
import { Collection } from '~/graphql/member/types';
import { _SubgraphErrorPolicy_, Token_OrderBy, TokensQuery, useTokensQuery } from '~/graphql/subgraph/types';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { CustomAnchorElProps } from '~/pages/my-shop/shop-detail/components/SalesCollection/SalesCollectionTab';
import { GRANT_PERMISSION } from '~/types/my-shop';
import { ITokensSubgraphQuery } from '~/types/token';
import {
  convertOrderToAPI,
  convertOrderToSubgraph,
  getLocalStorage,
  setLocalStorageItems,
  verifySortKey,
  verifySubgraphOrderKey,
} from '~/utils/common';
import { NFTToken } from '~/utils/fetch-nfts';
import { getNFTMetadata } from '~/utils/getNFTMetadata';
import { truncateEthAddress } from '~/utils/string.utils';

interface ExistingCollectionDetailProps {
  collectionData: Collection & { status?: GRANT_PERMISSION };
}

interface ITableTokensList extends NFTToken {
  id: string;
  url: string;
  name: string;
  description: string;
  networkSymbol: string;
  [Token_OrderBy.Owner]: string;
  [Token_OrderBy.MintTime]: Date;
  [Token_OrderBy.TokenId]: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    height: '100%',
  },
  wrapperTable: {
    width: '100%',
    '& .MuiDataGrid-root': {
      '& .MuiDataGrid-cell': {
        borderBottom: 'none',
      },
    },
    '.MuiTypography-subtitle1': {
      fontSize: '20px',
      fontWeight: 400,
      letterSpacing: 0.5,
      marginBottom: '12px',
    },
  },
}));

const getInitQuery = (contractAddress?: string): ITokensSubgraphQuery => {
  return {
    where: {
      contract_: {
        id: contractAddress,
      },
      tokenID: undefined,
    },
    first: 1000,
    subgraphError: _SubgraphErrorPolicy_.Deny,
    orderBy: verifySortKey(Token_OrderBy, getLocalStorage('nft_list_sort'), Token_OrderBy.MintTime),
    orderDirection: verifySubgraphOrderKey(getLocalStorage('nft_list_order')),
  };
};

const ExistingCollectionDetail: FC<ExistingCollectionDetailProps> = ({ collectionData }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { collectionId } = useParams();
  const { enqueueSnackbar } = useSnackbar();
  const { ipfsGateways } = useIpfsGateways();
  const { selectedOrganization } = useAccount();
  const { account: ethAddress } = useWalletAccount();

  const [openAddNFTDialog, setOpenAddNFTDialog] = useState(false);
  const [openDialogInfo, setOpenDialogInfo] = useState<NFTToken>();
  const [openRequiredWallet, setOpenRequiredWallet] = useState(false);
  const [tokensList, setTokensList] = useState<ITableTokensList[]>([]);
  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [loadingTokensList, setLoadingTokensList] = useState<boolean>(true);
  const [nftListMenuAnchorEl, setNftListMenuAnchorEl] = useState<CustomAnchorElProps | null>(null);

  const [customAnchorEl, setCustomAnchorEl] = useState<{
    info: GalleryData;
    anchorEl: null | HTMLElement;
  } | null>(null);

  const [tokensQuery, setTokensQuery] = useState(getInitQuery(collectionData.contractAddress.toLowerCase()));

  const formatTokensList = async (data: TokensQuery) => {
    setLoadingTokensList(true);
    const items = await Promise.all(
      data.tokens.map(async (token) => {
        const metadata = await getNFTMetadata(collectionData.uuid, token, ipfsGateways);
        return {
          ...metadata,
          id: metadata.tokenId,
          tokenId: metadata.tokenId,
          name: metadata.metadataContent.name,
          [Token_OrderBy.Owner]: token.owner.id,
          [Token_OrderBy.TokenId]: token.tokenID,
          description: metadata.metadataContent.description,
          [Token_OrderBy.MintTime]: moment.unix(token.mintTime).toDate(),
          networkSymbol: supportedNetworks?.[collectionData.network]?.tokenSymbol!,
          url: metadata.metadataContent.image || metadata.metadataContent.animation_url,
        };
      })
    );
    setTokensList(items);
    setLoadingTokensList(false);
  };

  const { loading: fetchingTokens, refetch: refetchingTokens } = useTokensQuery({
    fetchPolicy: 'cache-and-network',
    context: {
      blockchain: ContextAPIEnum.Subgraph,
      subgraphUrl: supportedNetworks?.[collectionData.network]?.subgraphUrl,
    },
    variables: tokensQuery,
    onCompleted: formatTokensList,
    onError: () => setTokensList([]),
  });

  const isLoading = fetchingTokens || loadingTokensList;

  const updateTokensQuery = (newValue: IHandleListTable) => {
    const orderBy = newValue.sortBy ? { orderBy: newValue.sortBy as Token_OrderBy } : {};
    const orderDirection = newValue.orderBy ? { orderDirection: convertOrderToSubgraph(newValue.orderBy) } : {};
    const searchText = (value: ITokensSubgraphQuery) =>
      typeof newValue.searchText === 'string'
        ? { where: { ...value.where, tokenID: newValue.searchText.trim() || undefined } }
        : {};
    return setTokensQuery((value) => ({
      ...value,
      ...searchText(value),
      ...orderBy,
      ...orderDirection,
    }));
  };

  useEffect(() => {
    setLocalStorageItems({
      nft_list_sort: tokensQuery?.orderBy,
      nft_list_order: tokensQuery?.orderDirection,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tokensQuery?.orderBy, tokensQuery?.orderDirection]);

  const openNFTDialog = useCallback(
    (id: string) => {
      setCustomAnchorEl(null);
      setIsOpenNFTDialog(true);
      setNftListMenuAnchorEl(null);
      setOpenDialogInfo((tokensList || []).find((item: any) => item.id === id));
    },
    [tokensList]
  );

  const columns: GridColDef<ITableTokensList>[] = useMemo(() => {
    const columnsSize = localStorage.getItem('columnsSize') || '{}';
    return [
      {
        width: 84,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ row }) => <SquareImage isNFT onClick={() => openNFTDialog(row.id)} src={row.url} />,
      },
      {
        field: 'name',
        sortable: false,
        headerName: t('my_shop.nft_name'),
        width: JSON.parse(columnsSize).name || 150,
      },
      {
        headerName: t('token_id'),
        field: Token_OrderBy.TokenId,
        width: JSON.parse(columnsSize).tokenId || 150,
      },
      {
        sortable: false,
        field: 'description',
        headerName: t('description'),
        width: JSON.parse(columnsSize).description || 150,
      },
      {
        ellipsis: true,
        sortable: false,
        field: Token_OrderBy.Owner,
        headerName: t('owner_address'),
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).ownerAddress || 200,
        renderCell: ({ formattedValue }) => (
          <Box display="flex" alignItems="center">
            {truncateEthAddress(formattedValue)}
            <IconBtnCopy text={formattedValue} />
          </Box>
        ),
      },
      {
        type: 'date',
        headerName: t('created_at'),
        field: Token_OrderBy.MintTime,
        getApplyQuickFilterFn: undefined,
        width: JSON.parse(columnsSize).createdAt || 115,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        align: 'center',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getApplyQuickFilterFn: undefined,
        getActions: ({ row }) => [
          <IconButton
            onClick={(event) => {
              setNftListMenuAnchorEl({ id: row.id, anchorEl: event.currentTarget });
            }}
          >
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ];
  }, [t, openNFTDialog]);

  const onCloseNFTDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const onOpenAddNFTDialog = () => {
    setOpenAddNFTDialog(true);
  };

  const onCloseAddNFTDialog = useCallback(
    async (params?: { isCreated?: boolean }) => {
      setOpenAddNFTDialog(false);
      if (collectionData && params?.isCreated) {
        updateTokensQuery({ page: 1, searchText: '' });
        refetchingTokens();
      }
    },
    [collectionData, refetchingTokens]
  );

  const handleOpenRequiredWallet = () => {
    setOpenRequiredWallet(true);
  };

  const handleCloseRequiredWallet = () => {
    setOpenRequiredWallet(false);
  };
  const handleClickMenu =
    (info: GalleryData): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      const newAnchorEl = {
        info,
        anchorEl: event.currentTarget,
      };
      setCustomAnchorEl(newAnchorEl);
      setNftListMenuAnchorEl({ id: newAnchorEl.info.id as string, anchorEl: event.currentTarget });
    };

  const handleOpenCreateNFT = async () => {
    if (ethAddress) {
      const provider = new JsonRpcProvider(supportedNetworks?.[collectionData.network]?.rpcUrl);
      const contract = ERC721G__factory.connect(collectionData.contractAddress, provider as any);
      const ownerAddress = await contract.owner();
      const currentWallet = ethAddress.toLowerCase();
      const ownerLowerCase = ownerAddress.toLowerCase();
      const masterWalletLowerCase = selectedOrganization.masterWalletAddress?.toLowerCase();
      if ([currentWallet, masterWalletLowerCase].includes(ownerLowerCase)) {
        onOpenAddNFTDialog();
      } else {
        enqueueSnackbar(t('you_are_not_the_owner_of_this_collection'), { variant: 'error' });
      }
    } else {
      handleOpenRequiredWallet();
    }
  };

  const onCloseNftListMenu = () => {
    setNftListMenuAnchorEl(null);
    setCustomAnchorEl(null);
  };

  const [isCanViewBtnCreate] = useCheckPermissions([SCREEN_PERMISSION.COLLECTIONS.MINTED_NFT.ADD]);

  return (
    <Box className={classes.wrapper}>
      <Box className={classes.wrapperTable}>
        <WrapperWithFab onClick={handleOpenCreateNFT} hidden={!isCanViewBtnCreate}>
          <CustomCardTable
            cardTitle={t('minted_nft_title')}
            headerAction={
              <PermissionsView roles={SCREEN_PERMISSION.COLLECTIONS.MINTED_NFT.ADD}>
                <HeaderAction menus={[{ title: t('create_nft'), onClick: handleOpenCreateNFT }]} />
              </PermissionsView>
            }
            cardContent={
              <ListTable
                noBorder
                columns={columns}
                rows={tokensList}
                pagination={false}
                isLoading={isLoading}
                onlyMode={VIEW_MODE.LIST}
                searchLabel={t('token_id')}
                tableName="collection_minted"
                search={tokensQuery.where.tokenID}
                noDataProps={{
                  title: t('create_new_nft'),
                  description: t('my_shop.message.no_nft'),
                  buttonTitle: t('create_nft'),
                  onClick: isCanViewBtnCreate ? handleOpenCreateNFT : undefined,
                }}
                noRowsMessage={t('my_shop.message.no_nft')}
                sort={{
                  sortBy: tokensQuery.orderBy,
                  orderBy: convertOrderToAPI(tokensQuery.orderDirection),
                }}
                onSort={updateTokensQuery}
                onClickMenu={handleClickMenu}
                onSearch={(v) => updateTokensQuery({ searchText: v || '' })}
              />
            }
          />
        </WrapperWithFab>
      </Box>
      <MintedNFTDialog
        open={isOpenNFTDialog}
        nftInfo={openDialogInfo!}
        collectionInfo={collectionData!}
        onClose={onCloseNFTDialog}
      />
      <Menu open={!!nftListMenuAnchorEl} anchorEl={nftListMenuAnchorEl?.anchorEl} onClose={onCloseNftListMenu}>
        <MenuItem onClick={() => openNFTDialog(nftListMenuAnchorEl?.id || '')}>{t('my_shop.show_detail')}</MenuItem>
      </Menu>
      <Menu open={!!customAnchorEl} anchorEl={customAnchorEl?.anchorEl} onClose={onCloseNftListMenu}>
        <MenuItem onClick={() => openNFTDialog(nftListMenuAnchorEl?.id || '')}>{t('my_shop.show_detail')}</MenuItem>
      </Menu>
      <AddNFTDialog collectionId={collectionId || ''} open={openAddNFTDialog} onClose={onCloseAddNFTDialog} />
      <WalletConnectorDialog
        open={openRequiredWallet}
        onNext={onOpenAddNFTDialog}
        onClose={handleCloseRequiredWallet}
      />
    </Box>
  );
};

export default memo(ExistingCollectionDetail);
