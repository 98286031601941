import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';

import { useApolloClient } from '@apollo/client';
import { WalletConnectorDialog, useDisconnect, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import AddIcon from '@mui/icons-material/Add';
import ApartmentIcon from '@mui/icons-material/Apartment';
import LogoutIcon from '@mui/icons-material/Logout';
import Avatar from '@mui/material/Avatar';
import Divider from '@mui/material/Divider';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as GlobalOutlinedIcon } from 'src/icons/images/WalletOutlined.svg';
import CreateOrganizationDialog from '~/components/create-organization-dialog';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useLogout } from '~/hooks/use-logout';
import { useAccount } from '~/hooks/with-account';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';
import { StyledComponentProps } from '~/types/material-ui';

const useStyles = makeStyles()((theme) => ({
  paper: {
    width: 400,
  },
  walletAddress: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
}));

interface Props extends StyledComponentProps<typeof useStyles> {
  open: boolean;
  onClose: () => void;
  anchorEl: MenuProps['anchorEl'];
  setAnchorEl: Dispatch<SetStateAction<HTMLElement | null>>;
}

const UserPopover: React.FC<Props> = (props) => {
  const { open, onClose, anchorEl, setAnchorEl } = props;
  const { classes } = useStyles(undefined, { props: { classes: props.classes } });
  const [openCreateOrganizationDialog, setOpenCreateOrganizationDialog] = useState(false);
  const [openWalletConnectorDialog, setOpenWalletConnectorDialog] = useState(false);
  const [openConfirmDisconnectDialog, setOpenConfirmDisconnectDialog] = useState(false);
  const [openConfirmLogoutDialog, setOpenConfirmLogoutDialog] = useState(false);
  const firebaseUser = useFirebaseUser();
  const { account, setSelectedOrganization, selectedOrganization } = useAccount();
  const { t } = useTranslation();
  const logout = useLogout();
  const { account: ethAddress } = useWalletAccount();
  const { disconnect } = useDisconnect();
  const client = useApolloClient();
  const navigate = useNavigate();

  const onOpenWalletConnectorDialog = useCallback(() => {
    setAnchorEl(null);
    setOpenWalletConnectorDialog(true);
  }, [setAnchorEl]);

  const onCloseWalletConnectorDialog = useCallback(() => {
    setOpenWalletConnectorDialog(false);
  }, []);

  const renderOrganizations = useMemo(
    () =>
      account.organizations.map((organization) => (
        <MenuItem
          key={organization.uuid}
          selected={organization.uuid === selectedOrganization.uuid}
          onClick={() => {
            setSelectedOrganization(organization.uuid);
            navigate(AppRouteEnum.Home);
            client.refetchQueries({ include: 'active' });
          }}
        >
          <ListItemIcon>
            <ApartmentIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText primary={organization.name} sx={{ whiteSpace: 'pre-wrap' }} />
        </MenuItem>
      )),
    [account.organizations, selectedOrganization.uuid, setSelectedOrganization, client, navigate]
  );

  const onCloseCreateOrganizationDialog = useCallback(() => {
    setOpenCreateOrganizationDialog(false);
  }, []);

  const onOpenCreateOrganizationDialog = useCallback(async () => {
    setAnchorEl(null);
    setOpenCreateOrganizationDialog(true);
  }, [setAnchorEl]);

  const handleOpenConfirmDisconnect = () => {
    setOpenConfirmDisconnectDialog(true);
  };

  const handleCloseConfirmDisconect = () => {
    setOpenConfirmDisconnectDialog(false);
  };

  const handleDisconnect = useCallback(async () => {
    disconnect();
    setAnchorEl(null);
  }, [disconnect, setAnchorEl]);

  const handleOpenLogoutDialog = () => {
    setOpenConfirmLogoutDialog(true);
  };

  const handleCloseLogoutDialog = () => {
    setOpenConfirmLogoutDialog(false);
  };

  return (
    <>
      <WalletConnectorDialog open={openWalletConnectorDialog} onClose={onCloseWalletConnectorDialog} />
      <Menu
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={onClose}
        anchorEl={anchorEl}
        classes={{ paper: classes.paper }}
      >
        <MenuItem>
          <ListItemAvatar>
            <Avatar variant="rounded" src={firebaseUser?.photoURL || ''} />
          </ListItemAvatar>
          <ListItemText primary={firebaseUser?.displayName} secondary={firebaseUser?.email} />
        </MenuItem>
        <Divider />

        <MenuItem onClick={ethAddress ? handleOpenConfirmDisconnect : onOpenWalletConnectorDialog}>
          <ListItemIcon>
            <GlobalOutlinedIcon />
          </ListItemIcon>
          {ethAddress ? t('disconnect') : t('connect')}
        </MenuItem>
        <Divider />
        {renderOrganizations}
        <MenuItem onClick={onOpenCreateOrganizationDialog}>
          <ListItemIcon>
            <AddIcon fontSize="small" />
          </ListItemIcon>
          {t('create')}
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleOpenLogoutDialog}>
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          {t('logout')}
        </MenuItem>
      </Menu>
      <CreateOrganizationDialog
        open={openCreateOrganizationDialog}
        onClose={onCloseCreateOrganizationDialog}
        onSelectedOrganization={setSelectedOrganization}
      />
      <ConfirmationDialog
        onConfirm={handleDisconnect}
        title={t('disconnect_wallet')}
        confirmTitle={t('disconnect')}
        open={openConfirmDisconnectDialog}
        onClose={handleCloseConfirmDisconect}
        content={t('disconnect_wallet_confirm')}
      />
      <ConfirmationDialog
        onConfirm={logout}
        title={t('logout')}
        confirmTitle={t('logout')}
        content={t('logout_confirm')}
        open={openConfirmLogoutDialog}
        onClose={handleCloseLogoutDialog}
      />
    </>
  );
};

export default UserPopover;
