import { FC, PropsWithChildren, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import CustomDialog from '../custom-dialog';

import { OrganizationUserRole, useCreateCheckoutSessionMutation } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

interface IRequiredPaymentMethodDialog extends PropsWithChildren {
  open: boolean;
  title: string;
  successUrl?: string;
  description: string;
  onClose: () => void;
}

const RequiredPaymentMethodDialog: FC<IRequiredPaymentMethodDialog> = ({
  open,
  title,
  successUrl,
  description,
  onClose,
}) => {
  const { t, i18n } = useTranslation();
  const { selectedOrganization } = useAccount();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const isOwner = selectedOrganization.role === OrganizationUserRole.Owner;

  const [createCheckoutSession] = useCreateCheckoutSessionMutation();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const { data } = await createCheckoutSession({
        variables: {
          input: {
            locale: i18n.language,
            cancelUrl: window.location.href,
            successUrl: successUrl || window.location.href,
          },
        },
      });
      const sessionId = data?.createCheckoutSession.sessionId;
      const publishableKey = data?.createCheckoutSession?.publishableKey;
      if (publishableKey && sessionId) {
        const stripe = await loadStripe(publishableKey);
        await stripe?.redirectToCheckout({ sessionId });
      }
    } catch {}
    setIsSubmitting(false);
  };

  return (
    <CustomDialog
      width="sm"
      open={open}
      onClose={onClose}
      dialogTitle={title}
      dialogContent={
        <>
          <Typography>{description}</Typography>
          {!isOwner && (
            <Typography variant="body2" marginTop="8px" color="error">
              *{t('payment_method_required.required_contact_owner')}
            </Typography>
          )}
        </>
      }
      actions={
        isOwner
          ? [
              <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
                {t('cancel')}
              </Button>,
              <Button
                variant="contained"
                disabled={isSubmitting}
                endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                onClick={handleSubmit}
              >
                {t('register')}
              </Button>,
            ]
          : [
              <Button variant="contained" disabled={isSubmitting} onClick={onClose}>
                {t('cancel')}
              </Button>,
            ]
      }
    />
  );
};

export default RequiredPaymentMethodDialog;
