import { ERC721G, ERC721G__factory } from '@gusdk/erc721g';
import { Provider, Signer } from 'ethers';

const getERC721G = (signerOrProvider: Signer | Provider, contractAddress: string) =>
  ERC721G__factory.connect(contractAddress, signerOrProvider);

const checkMinter = async (contract: ERC721G, wallet: string) => {
  const adminRole = await contract.ADMIN_ROLE();
  const minterRole = await contract.MINTER_ROLE();
  const hasAdminRole = await contract.hasRole(adminRole, wallet);
  const hasMinterRole = await contract.hasRole(minterRole, wallet);
  return hasAdminRole || hasMinterRole;
};

const grantMinterRole = async (contract: ERC721G, wallet: string, isGrant: boolean = true) => {
  const minterRole = await contract.MINTER_ROLE();
  if (isGrant) {
    return contract.grantRole(minterRole, wallet);
  }
  return contract.revokeRole(minterRole, wallet);
};

export { getERC721G, checkMinter, grantMinterRole };
