import { useState } from 'react';

import { useNotify } from './useNotify';

// Define the structure of the hook's return value
interface ExportCSVResult {
  exportToCSV: (data: Record<string, any>[], filename?: string) => void;
  isExporting: boolean;
}

// Custom hook for exporting data to CSV
const useExportCSV = (): ExportCSVResult => {
  const { showSuccess, showErrorByKey } = useNotify();

  // State to manage the exporting status
  const [isExporting, setIsExporting] = useState<boolean>(false);

  /**
   * Function to export data to a CSV file
   * @param data - Array of objects representing the data
   * @param filename - Name of the CSV file (default is 'data.csv')
   */
  const exportToCSV = (data: Record<string, any>[], filename: string = 'data.csv') => {
    // Check if data is available
    if (!data || data.length === 0) {
      showErrorByKey('toast_message.no_data_available_for_export');
      return;
    }

    // Set exporting status to true
    setIsExporting(true);

    try {
      // Extract headers from the first object keys
      const headers = Object.keys(data[0]);
      const csvRows: string[] = [];

      // Add headers to the CSV
      csvRows.push(headers.join(','));

      // Add each row of data to the CSV
      data.forEach((row) => {
        const values = headers.map((header) => {
          // Escape double quotes in values
          const escaped = `${row[header]}`.replace(/"/g, '""');
          return `"${escaped}"`;
        });
        csvRows.push(values.join(','));
      });

      // Combine all rows into a single CSV string
      const csvString = csvRows.join('\n');

      // Create a Blob from the CSV string
      const blob = new Blob([csvString], { type: 'text/csv' });

      // Create a temporary link to trigger the download
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = filename;

      // Append the link to the document and trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by removing the link
      document.body.removeChild(link);

      // Show success notification
      showSuccess('toast_message.exported_successfully');
    } catch (error) {
      // Log and show error notification
      console.error('Error exporting CSV:', error);
      showErrorByKey('toast_message.export_failed');
    } finally {
      // Reset exporting status
      setIsExporting(false);
    }
  };

  // Return the export function and exporting status
  return { exportToCSV, isExporting };
};

export default useExportCSV;
