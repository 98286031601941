import { ChangeEvent, MouseEventHandler, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import {
  DataGridProProps,
  GridColDef,
  GridCsvExportOptions,
  GridEventListener,
  GridPaginationModel,
  GridRowsProp,
  GridSortItem,
  GridSortModel,
  GridValidRowModel,
  jaJP,
} from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomGridToolbarSearchByAPI from '../custom-grid-toolbar-search-by-api';
import CustomDataGrid from '../CustomDataGrid';
import GalleryList, { GalleryData } from '../gallery-list';
import CustomHeaderGallery from '../gallery-list/CustomHeaderGallery';
import CustomNodata from '../no-data';
import NoDataWithCreateButton, { NoDataWithCreateButtonProps } from '../no-data/NoDataWithCreateButton';

import { ITEMS_PER_PAGE } from '~/constants/common';
import { OrderByGrid, ReverseOrderByGrid } from '~/constants/tableKeys';
import { VIEW_MODE } from '~/enum/common';
import { OrderBy } from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { IGridToolbar, IToolbarSelector } from '~/interfaces/common';

declare module '@mui/x-data-grid-pro' {
  interface NoRowsOverlayPropsOverrides {
    message: string;
  }
}

export interface ListTablePagination {
  totalItems?: number;
  itemsPerPage?: number;
  totalPages?: number;
  currentPage?: number;
}
export interface SortItem {
  sortBy?: string;
  orderBy?: string;
}

export interface IHandleListTable extends SortItem {
  page?: number;
  limit?: number;
  searchText?: string;
}

type DataGridProComponent<R extends GridValidRowModel = any> = DataGridProProps<R> & {
  sort?: SortItem;
  search?: string;
  isMenu?: boolean;
  isLoading: boolean;
  noBorder?: boolean;
  tableName?: string;
  notSquare?: boolean;
  onlyMode?: VIEW_MODE;
  searchLabel?: string;
  isFiltering?: boolean;
  isShopScreen?: boolean;
  noRowsMessage?: string;
  orderPriority?: boolean;
  toggleableSort?: boolean;
  columns: GridColDef<R>[];
  isCollectionScreen?: boolean;
  rows: GridRowsProp<R> | GalleryData[];
  noDataProps?: NoDataWithCreateButtonProps;
  csvOptions?: GridCsvExportOptions & { getAll?: () => Promise<any[]> };
  actionsToolbar?: {
    selector?: IGridToolbar['selector'];
    menuItems?: IGridToolbar['menuItems'];
  };
  onSearch?: (value: string) => void;
  onClickMenu?: (info: GalleryData) => MouseEventHandler<HTMLButtonElement>;
  onClickImage?: (item: GalleryData) => void;
  onRowUpdate?: (newRow: R, oldRow: R) => R | Promise<R>;
  onColumnOrderChange?: GridEventListener<'columnOrderChange'>;
  paginationData?: ListTablePagination;
  onSort?: (sort: IHandleListTable) => void;
  onViewMode?: (view: VIEW_MODE) => void;
  onPagination?: (paging: { page: number; limit: number }) => void;
};

const useStyles = makeStyles<{
  noBorder?: boolean;
}>()((theme, { noBorder }) => ({
  wrapperListTable: {
    backgroundColor: theme.palette.common.white,
    '.MuiDataGrid-root': {
      borderRadius: '8px',
      border: noBorder ? 'none' : '',
      '.MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-iconButtonContainer .MuiBadge-root .MuiBadge-badge': {
        display: 'none',
      },
    },
    '.MuiDataGrid-toolbarContainer': noBorder
      ? {
          padding: '0',
          margin: '0',
        }
      : {},
    '.MuiBox-root': {
      border: noBorder ? 'none' : '',
    },
    '::-webkit-scrollbar': {
      display: 'none',
    },
  },
}));

const orderSort: GridSortItem = { field: 'order', sort: 'asc' };

const ListTable = <R extends GridValidRowModel = any>({
  sort,
  rows,
  slots,
  search,
  isMenu,
  columns,
  onlyMode,
  noBorder,
  notSquare,
  tableName,
  isLoading,
  csvOptions,
  searchLabel,
  isFiltering,
  noDataProps,
  isShopScreen,
  noRowsMessage,
  orderPriority,
  actionsToolbar,
  rowHeight = 84,
  paginationData,
  isCollectionScreen,
  toggleableSort = false,
  onSearch,
  onViewMode,
  onRowUpdate,
  onClickMenu,
  onSort = () => {},
  onColumnOrderChange,
  onClickImage = () => {},
  onPagination = () => {},
  ...others
}: DataGridProComponent<R>): JSX.Element => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles({ noBorder });

  const [valueSearch, setValueSearch] = useState(search);
  const debounceValue = useDebounce(valueSearch, 1000);

  useEffect(() => {
    if (search !== debounceValue && !!onSearch) {
      onSearch(debounceValue || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue]);

  useEffect(() => {
    if (search !== valueSearch) {
      setValueSearch(search?.trim() || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const [viewMode, setViewMode] = useState(onlyMode ?? VIEW_MODE.LIST);

  const handleMode = (mode: VIEW_MODE) => {
    if (!onViewMode) return;
    setViewMode(mode);
    onViewMode(mode);
  };

  const sortParams = useMemo<GridSortModel>(() => {
    const _s = orderPriority ? [orderSort] : [];
    if (sort && sort.sortBy)
      _s.push({
        field: sort.sortBy,
        sort: ReverseOrderByGrid[(sort.orderBy as OrderBy) || OrderBy.Desc],
      });
    return _s;
  }, [orderPriority, sort]);

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setValueSearch(e.target.value);
  };

  const toolbarProps = {
    csvOptions,
    searchLabel,
    mode: viewMode,
    search: valueSearch,
    ...(actionsToolbar ? actionsToolbar : {}),
    handleSearch: !onSearch ? undefined : handleSearch,
    handleMode: !!onlyMode || !onViewMode ? undefined : handleMode,
  };

  const handleSortModelChange = async (model: GridSortModel) => {
    if (!model[0]) {
      if (toggleableSort) {
        onSort({ sortBy: undefined, orderBy: undefined });
        return;
      }
      if (columns.find((i) => i.field === sort?.sortBy)) {
        const orderBy = OrderByGrid[sort?.orderBy?.toLowerCase?.() === 'desc' ? 'asc' : 'desc'] || OrderByGrid.desc;
        onSort({ sortBy: sort?.sortBy, orderBy });
      }
    } else {
      const orderBy = OrderByGrid[model[0]?.sort || 'desc'] || OrderByGrid.desc;
      onSort({ sortBy: model[0].field, orderBy });
    }
  };

  const handlePagination = async (model: GridPaginationModel) => {
    await onPagination({ page: model.page + 1, limit: model.pageSize });
  };

  const handleProcessRowUpdate = !!onRowUpdate ? (newRow: R, oldRow: R) => onRowUpdate(newRow, oldRow) : undefined;

  isFiltering = !!search || isFiltering;
  const isNoData = !isLoading && noDataProps && rows.length === 0 && !isFiltering;

  return (
    <Box className={classes.wrapperListTable}>
      {isNoData ? (
        <NoDataWithCreateButton
          title={noDataProps.title}
          buttonTitle={noDataProps.buttonTitle}
          description={noDataProps.description}
          onClick={noDataProps.onClick}
        />
      ) : viewMode === VIEW_MODE.GALLERY ? (
        <GalleryList
          isMenu={isMenu}
          loading={isLoading}
          notSquare={!!notSquare}
          isSearching={isFiltering}
          isShopScreen={isShopScreen}
          data={rows as GalleryData[]}
          noRowsMessage={noRowsMessage}
          isCollectionScreen={isCollectionScreen}
          totalItems={paginationData?.totalItems || 0}
          paginationModel={{
            page: (paginationData?.currentPage || 1) - 1,
            pageSize: paginationData?.itemsPerPage || ITEMS_PER_PAGE.LIST,
          }}
          rowsPerPage={ITEMS_PER_PAGE.GALLERY}
          components={{ Header: <CustomHeaderGallery {...toolbarProps} /> }}
          componentsProps={{ Card: { onClick: onClickImage } }}
          onClickMenu={onClickMenu}
          onPaginationModelChange={handlePagination}
        />
      ) : (
        <CustomDataGrid
          pagination
          rows={rows}
          columns={columns}
          disableColumnMenu
          loading={isLoading}
          rowHeight={rowHeight}
          tableName={tableName}
          sortModel={sortParams}
          rowCount={paginationData?.totalItems || 0}
          paginationModel={{
            page: (paginationData?.currentPage || 1) - 1,
            pageSize: paginationData?.itemsPerPage || ITEMS_PER_PAGE.LIST,
          }}
          pageSizeOptions={[ITEMS_PER_PAGE.LIST]}
          sortingMode={orderPriority ? 'client' : 'server'}
          paginationMode={orderPriority ? 'client' : 'server'}
          isRowSelectable={isRowSelectable}
          onColumnOrderChange={onColumnOrderChange}
          onSortModelChange={handleSortModelChange}
          processRowUpdate={handleProcessRowUpdate}
          onPaginationModelChange={handlePagination}
          slots={{
            noRowsOverlay: CustomNodata,
            noResultsOverlay: CustomNodata,
            toolbar: CustomGridToolbarSearchByAPI,
            ...(!!slots ? slots : {}),
          }}
          slotProps={{
            toolbar: toolbarProps,
            noRowsOverlay: { message: isFiltering ? t('no_data_available') : noRowsMessage },
          }}
          localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
          {...others}
        />
      )}
    </Box>
  );
};

const isRowSelectable = () => false;

export default ListTable;
