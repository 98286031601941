import { FC, PropsWithChildren, useState } from 'react';

import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomDialog from '../custom-dialog';

import { colors } from '~/constants/colors';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { formatLocaleString } from '~/utils/number.util';

export enum InvoiceCurrency {
  USD = 'USD',
  JPY = 'JPY',
  JOC = 'JOC',
  JOCT = 'JOCT',
}

export interface IInvoice {
  items: {
    name: string;
    price: number;
    total: number;
    quantity: number;
  }[];
  tax?: number;
  total: number;
  currency: InvoiceCurrency;
}

interface IInvoiceDialog extends PropsWithChildren {
  open: boolean;
  title?: string;
  data: IInvoice | undefined;
  onClose: () => void;
  onSubmit: () => Promise<boolean>;
}

const currencies = {
  [InvoiceCurrency.JPY]: '¥',
  [InvoiceCurrency.USD]: '$',
  [InvoiceCurrency.JOC]: 'JOC',
  [InvoiceCurrency.JOCT]: 'JOCT',
};

const useStyles = makeStyles()(() => ({
  wrapperTable: {
    '.MuiTableCell-root': {
      padding: '6px 16px',
    },
  },
}));

const InvoiceDialog: FC<IInvoiceDialog> = ({ open, data, title, onSubmit, onClose }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { selectedOrganization } = useAccount();
  const { showError, showSuccess } = useNotify();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const state = await onSubmit();
      if (state) {
        showSuccess('toast_message.payment_successful');
      }
    } catch (error) {
      showError(error, 'toast_message.payment_failed');
    }
    setIsSubmitting(false);
    onClose();
  };

  const convertPrice = (price: number) => {
    const currency = data ? currencies[data?.currency] : '';
    const isCryptoCurrency = ['JOC', 'JOCT'].includes(currency);
    const fixedPrice = formatLocaleString(price, 1);
    return isCryptoCurrency ? `${fixedPrice} ${currency}` : `${currency}${fixedPrice}`;
  };

  const hasTax = typeof data?.tax === 'number';

  return (
    <CustomDialog
      open={open}
      maxWidthPaper={700}
      dialogTitle={t(title || 'invoice')}
      onClose={isSubmitting ? () => {} : onClose}
      dialogContent={
        <>
          <Stack gap={1} flexDirection="row">
            <Typography flex={1} variant="subtitle1">
              <strong>{t('organization')}:</strong> {selectedOrganization.name}
            </Typography>
            <Typography flex={1} variant="subtitle1">
              <strong>{t('date')}:</strong> {moment().format('YYYY-MM-DD')}
            </Typography>
          </Stack>
          <Divider sx={{ margin: '16px 0' }} />
          <Typography variant="h6">{t('contract_detail')}</Typography>
          <TableContainer className={classes.wrapperTable}>
            <Table aria-label="customized table">
              <TableHead>
                <TableRow>
                  <TableCell>{t('item_name')}</TableCell>
                  <TableCell>{t('quantity')}</TableCell>
                  <TableCell>{t('unit_price')}</TableCell>
                  <TableCell align="right">{t('subtotal')}</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data?.items.map((item, idx) => (
                  <TableRow key={idx}>
                    <TableCell>{t(item.name)}</TableCell>
                    <TableCell>x{formatLocaleString(item.quantity)}</TableCell>
                    <TableCell>{convertPrice(item.price)}</TableCell>
                    <TableCell align="right">{convertPrice(item.total)}</TableCell>
                  </TableRow>
                ))}
                {hasTax && (
                  <TableRow>
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell rowSpan={2} sx={{ borderBottom: 'none' }} />
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {t('tax')}
                    </TableCell>
                    <TableCell align="right" sx={{ borderBottom: 'none' }}>
                      {convertPrice(data.tax || 0)}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow>
                  {!hasTax && (
                    <>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                    </>
                  )}
                  <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                    {t('total')}
                  </TableCell>
                  <TableCell align="right" sx={{ fontWeight: 700, color: colors.primary, borderBottom: 'none' }}>
                    {convertPrice(data?.total || 0)}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </>
      }
      actions={[
        <Button variant="outlined" disabled={isSubmitting} onClick={onClose}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit}
        >
          {t('my_shop.purchase')}
        </Button>,
      ]}
    />
  );
};

export default InvoiceDialog;
