import { FC, useEffect, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import { DialogProps } from '@mui/material/Dialog';
import TextField from '@mui/material/TextField';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../custom-dialog';

import TextEditor from '~/components/TextEditor';
import { formEditCollectionFromShopSchema } from '~/config/yup';
import { MAX_LENGTH } from '~/constants/common';
import { getErrorText } from '~/utils/yup.util';

export interface IEditCollectionFromShopField {
  name: string;
  desc?: string;
  nameJa: string;
  descJa?: string;
}

interface EditNameDialogProps extends DialogProps {
  title: string;
  defaultData: {
    name: string;
    desc: string;
    nameJa: string;
    descJa: string;
  };
  autoFocus?: 'en' | 'ja';
  onClose: () => void;
  onEdit: (data: IEditCollectionFromShopField) => Promise<void>;
}

export interface FormValuesImageNFT extends yup.InferType<typeof formEditCollectionFromShopSchema> {}

const EditCollectionFromShopDialog: FC<EditNameDialogProps> = (props) => {
  const { open, title, autoFocus, defaultData, onEdit, onClose } = props;
  const { t } = useTranslation();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleAction =
    (callback: (data: IEditCollectionFromShopField) => Promise<void>): SubmitHandler<IEditCollectionFromShopField> =>
    async (data) => {
      setIsSubmitting(true);
      await callback({
        name: data.name.trim(),
        nameJa: data.nameJa.trim(),
        desc: (data.desc || '').trim(),
        descJa: (data.descJa || '').trim(),
      });
      setIsSubmitting(false);
    };

  const {
    control,
    formState: { dirtyFields, errors },
    reset,
    handleSubmit,
  } = useForm<FormValuesImageNFT>({
    defaultValues: {
      name: '',
      desc: '',
      nameJa: '',
      descJa: '',
    },
    resolver: yupResolver(formEditCollectionFromShopSchema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    if (open) {
      reset({
        name: defaultData.name,
        desc: defaultData.desc,
        nameJa: defaultData.nameJa,
        descJa: defaultData.descJa,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const autoFocusInput = autoFocus || 'en';

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={onClose}
      dialogTitle={title}
      dialogContent={
        <>
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                spellCheck={false}
                id="outlined-basic"
                disabled={isSubmitting}
                label={t('my_shop.captionEn')}
                error={!!errors.name?.message}
                autoFocus={autoFocusInput === 'en'}
                helperText={getErrorText(errors.name?.message, t)}
                {...field}
              />
            )}
          />
          <Controller
            name="nameJa"
            control={control}
            render={({ field }) => (
              <TextField
                required
                fullWidth
                margin="normal"
                variant="outlined"
                spellCheck={false}
                id="outlined-basic"
                disabled={isSubmitting}
                label={t('my_shop.captionJa')}
                error={!!errors.nameJa?.message}
                autoFocus={autoFocusInput === 'ja'}
                helperText={getErrorText(errors.nameJa?.message, t)}
                {...field}
              />
            )}
          />
          <TextEditor
            name="desc"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_en')}
            error={errors.desc?.message}
            sx={{
              marginTop: '23px',
            }}
          />
          <TextEditor
            name="descJa"
            control={control}
            heightEditor={47}
            maxLength={MAX_LENGTH}
            readOnly={isSubmitting}
            label={t('description_ja')}
            error={errors.descJa?.message}
            sx={{
              marginTop: '23px',
            }}
          />
        </>
      }
      actions={[
        <Button variant="outlined" color="primary" onClick={onClose} disabled={isSubmitting}>
          {t('cancel')}
        </Button>,
        <Button
          variant="contained"
          disabled={!isDirty || isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(handleAction(onEdit!))}
        >
          {t('save')}
        </Button>,
      ]}
    />
  );
};

export default EditCollectionFromShopDialog;
