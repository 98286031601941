import { forwardRef, useImperativeHandle, useMemo } from 'react';

import BlurCircularIcon from '@mui/icons-material/BlurCircular';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import moment from 'moment';
import 'moment/locale/ja';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CollapsibleRow from './CollapsibleRow';

import { INVOICE_ITEM_NAME } from '~/constants/common';
import { InvoiceType } from '~/enum/common';
import useExportCSV from '~/hooks/useExportCSV';
import { CustomInvoiceType, UpcomingInvoiceType } from '~/types/billing';

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiTablePagination-displayedRows': {
      display: 'none',
    },
  },
}));

const UpcomingInvoice: UpcomingInvoiceType = ({ upcomingInvoices }, ref) => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { exportToCSV } = useExportCSV();

  const transactions = useMemo(
    () => {
      const _transactions: CustomInvoiceType[] = [];
      for (let invoice of upcomingInvoices) {
        const type = invoice.id as InvoiceType;
        let name = t(INVOICE_ITEM_NAME[type]);

        if (type === InvoiceType.PurchasePlan) {
          if (!!invoice.description) {
            moment.locale(i18n.language);
            const nextMonth = moment().add(1, 'month').format('MMMM');

            name = t('settings.billing.renew_plan_desc', { planName: invoice.description, month: nextMonth });

            moment.locale('en');
          }
        }
        _transactions.push({
          ...invoice,
          name,
          tax: invoice.taxFee,
          subtotalExcludingTax: invoice.subtotal,
          items: [
            {
              ...invoice,
              description: name,
              amountExcludingTax: invoice.subtotal,
              unitAmountExcludingTax: invoice.price,
            },
          ],
        });
      }
      return _transactions;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n.language]
  );

  useImperativeHandle(
    ref,
    () => ({
      handleExportCurrentPage: () =>
        exportToCSV(
          transactions.map((trans) => ({
            'Invoice Number': trans.number,
            Description: trans.name,
            Status: trans.status,
            Currency: trans.currency,
            Total: trans.total,
            Tax: trans.tax,
            Date: moment(trans.createdAt).format(t('date_time_format')),
          })),
          `upcoming-invoice-${moment().add(1, 'month').format('MM-YYYY')}.csv`
        ),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [transactions]
  );

  const listStatus = useMemo(
    () => ({
      paid: {
        color: 'success',
        label: t('success'),
        icon: <CheckCircleIcon fontSize="small" />,
      },
      open: {
        color: 'warning',
        label: t('pending'),
        icon: <BlurCircularIcon fontSize="small" />,
      },
      draft: {
        color: undefined,
        label: t('draft'),
        icon: <DescriptionOutlinedIcon fontSize="small" />,
      },
      void: {
        color: 'error',
        label: t('failed'),
        icon: <CancelIcon fontSize="small" />,
      },
    }),
    [t]
  );

  return (
    <Box className={classes.wrapper}>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableHead>
            <TableRow>
              <TableCell width="66px" />
              <TableCell>{t('item_name')}</TableCell>
              <TableCell>{t('settings.amount')}</TableCell>
              <TableCell>{t('status')}</TableCell>
              <TableCell>{t('my_shop.payment_methods.payment_method')}</TableCell>
              <TableCell>{t('created_at')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!transactions.length ? (
              <TableRow sx={{ height: '200px' }}>
                <TableCell colSpan={6} align="center">
                  {t('no_data_available')}
                </TableCell>
              </TableRow>
            ) : (
              transactions.map((row, idx) => <CollapsibleRow key={idx} row={row} listStatus={listStatus} />)
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default forwardRef(UpcomingInvoice);
