import { Dispatch, FC, SetStateAction, createContext } from 'react';

import Box from '@mui/material/Box';
import { useWatch } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import MemberSiteScreen from './components/MemberSite/MemberSiteScreen';
import MintedCollectionSection from './components/Shop/Minted/CollectionSection';
import CollectionSection from './components/Shop/MintOnPurchase/CollectionSection';

import LoaderCenter from '~/components/loader-center';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import {
  ListMyShopCollectionsQuery,
  MyShopCollectionQueryKey,
  OrderBy,
  QueryOperator,
  useListMyShopCollectionsQuery,
} from '~/graphql/member/types';
import { useEditShop } from '~/pages/edit-shop';
import { NFT_SHOP_TYPE, SHOP_TYPE } from '~/types/my-shop';

export type MyShopCollectionResponse = ListMyShopCollectionsQuery['listMyShopCollections']['items'][0];

export interface LazyCollectionPreview {
  id: string;
  name: string;
  remainingNumber: number;
  description: string;
  imageUrl: string[];
}

export interface ShopContentContextValue {
  setCountSections: Dispatch<SetStateAction<number>>;
  setListSectionsNotExist: Dispatch<SetStateAction<string[]>>;
}

const useStyles = makeStyles<{ spacing: number; spacingSm: number }>()(({ breakpoints }, { spacing, spacingSm }) => {
  const sm = breakpoints.up('sm').replace('@media', '@container');
  return {
    wrapperContent: {
      margin: 'auto',
      maxWidth: '1180px',
    },
    wrapperCollections: {
      display: 'flex',
      minHeight: '200px',
      borderRadius: '16px',
      gap: spacingSm + 'px',
      paddingBottom: '40px',
      flexDirection: 'column',
      backgroundColor: 'transparent',
      [sm]: {
        gap: spacing + 'px',
      },
    },
  };
});

export const ShopContentContext = createContext<ShopContentContextValue | undefined>(undefined);

const ShopContent: FC = () => {
  const { control } = useEditShop();
  const { data: myShop, loading: loadingMyShop, isMemberSite } = useShopDetail();

  const [spacing, spacingSm] = useWatch({ control, name: ['style.spacing', 'style.spacingSm'] });

  const { classes } = useStyles({ spacing, spacingSm });

  const isMintedShop = myShop?.siteSetting?.category === NFT_SHOP_TYPE.MINTED;

  const { data: listMyShopCollections, loading: loadingMyShopCollections } = useListMyShopCollectionsQuery({
    fetchPolicy: 'no-cache',
    variables: {
      sortBy:
        (myShop?.shopInformation?.sortBy as MyShopCollectionQueryKey) ?? MyShopCollectionQueryKey.CollectionCreatedAt,
      orderBy: (myShop?.shopInformation?.orderBy as OrderBy) ?? undefined,
      where: {
        fields: [
          {
            operator: QueryOperator.Equals,
            value: [myShop?.uuid ?? ''],
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });

  return (
    <Box className={classes.wrapperContent}>
      {loadingMyShop || loadingMyShopCollections ? (
        <LoaderCenter />
      ) : isMemberSite ? (
        <MemberSiteScreen />
      ) : (
        <Box className={classes.wrapperCollections}>
          {listMyShopCollections?.listMyShopCollections.items?.map((item) =>
            isMintedShop ? (
              <MintedCollectionSection key={item.uuid} collectionSection={item} />
            ) : (
              <CollectionSection key={item.uuid} collectionSection={item} />
            )
          )}
        </Box>
      )}
    </Box>
  );
};

export default ShopContent;
