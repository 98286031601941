import { useEffect, useRef } from 'react';

import { useSnackbar } from 'notistack';

import ScreenLoading from '../screen-loading';

import { GetMeDocument, useCreateOrganizationMutation, User } from '~/graphql/member/types';
interface Props {
  user: User;
}

const CreateFirstOrganization: React.FC<Props> = (props) => {
  const { user } = props;
  const { enqueueSnackbar } = useSnackbar();
  const isCalled = useRef(false);
  const [createOrganization] = useCreateOrganizationMutation({
    refetchQueries: [GetMeDocument],
  });

  useEffect(() => {
    if (!isCalled.current) {
      isCalled.current = true;
      createOrganization({
        variables: {
          input: {
            region: 'SG',
            language: 'en-US',
            contactEmail: user.email,
            name: `${user.displayName}'s organization`,
          },
        },
      }).catch((e) => enqueueSnackbar(e.message, { variant: 'error' }));
    }
  }, [createOrganization, enqueueSnackbar, user]);

  return <ScreenLoading />;
};

export default CreateFirstOrganization;
