import { forwardRef, useMemo } from 'react';

import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { useWatch } from 'react-hook-form';
import { I18nextProvider } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import ShopContent from './components/ShopContent';
import ShopHeader from './components/ShopHeader';

import { colors } from '~/constants/colors';
import { MyShop } from '~/graphql/member/types';
import i18nShop from '~/i18n/shop.i18n';
import { ITheme, useEditShop } from '~/pages/edit-shop';
import { theme as customTheme } from '~/styles/theme';

export interface ShopLandingPageContextValue {
  theme: ITheme;
  shopData: MyShop;
}

const useStyles = makeStyles<{ fontFamily: string }>()(({ breakpoints }, { fontFamily }) => ({
  wrapper: {
    flex: 1,
    width: '100%',
    minHeight: '100vh',
    containerType: 'inline-size',
    '.quillPreview': {
      fontFamily,
      p: {
        margin: 0,
        fontFamily,
      },
      a: {
        fontFamily,
      },
    },
  },
  wrapperContent: {
    padding: '0 16px',
    [breakpoints.up('sm').replace('@media', '@container')]: {
      padding: '0 24px',
    },
  },
}));

const defaultColor = (color: string) => color || colors.black;

const ShopLandingPage = forwardRef((_, ref) => {
  const { control } = useEditShop();

  const [
    font,
    textColor,
    titleColor,
    buttonColor,
    backgroundColor,
    descriptionColor,
    collectionTitleColor,
    nftCardTextColor,
    nftCardBackgroundColor,
    collectionBorderColor,
  ] = useWatch({
    control,
    name: [
      'style.font',
      'style.textColor',
      'style.titleColor',
      'style.buttonColor',
      'style.backgroundColor',
      'style.descriptionColor',
      'style.collectionTitleColor',
      'style.nftCardTextColor',
      'style.nftCardBackgroundColor',
      'style.collectionBorderColor',
    ],
  });

  const fontFamily = [font, 'sans-serif'].join(',');

  const { classes } = useStyles({ fontFamily });

  const themeMUI = useMemo(() => {
    const textColorCustom = defaultColor(textColor);
    const buttonColorCustom = defaultColor(buttonColor);
    const textCss = {
      fontFamily,
      color: textColorCustom,
    };
    return createTheme(customTheme, {
      palette: {
        textColor: textColorCustom,
        buttonColor: buttonColorCustom,
        titleColor: defaultColor(titleColor),
        descriptionColor: defaultColor(descriptionColor),
        collectionTitleColor: defaultColor(collectionTitleColor),
        nftCardTextColor: defaultColor(nftCardTextColor),
        nftCardBackgroundColor: defaultColor(nftCardBackgroundColor),
        collectionBorderColor: defaultColor(collectionBorderColor),
      },
      colors: {
        white: '#FFFFFF',
      },
      typography: {
        h1: textCss,
        h2: textCss,
        h3: textCss,
        h4: textCss,
        h5: textCss,
        h6: textCss,
        caption: textCss,
        subtitle1: textCss,
        subtitle2: textCss,
        body1: textCss,
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              fontFamily,
            },
            text: {
              color: buttonColorCustom,
            },
            containedPrimary: {
              backgroundColor: buttonColorCustom,
              ':hover': {
                backgroundColor: buttonColorCustom,
                filter: 'brightness(85%)',
              },
            },
            outlinedPrimary: {
              color: buttonColorCustom,
              borderColor: `${buttonColorCustom}80`,
              '&:not(.Mui-disabled)': {
                '&:hover': {
                  borderColor: `${buttonColorCustom}80`,
                  backgroundColor: `${buttonColorCustom}0A`,
                },
              },
            },
          },
        },
      },
    });
  }, [
    textColor,
    titleColor,
    fontFamily,
    buttonColor,
    descriptionColor,
    collectionTitleColor,
    nftCardTextColor,
    nftCardBackgroundColor,
    collectionBorderColor,
  ]);

  return (
    <ThemeProvider theme={themeMUI}>
      <I18nextProvider i18n={i18nShop} defaultNS="translation">
        <Box
          ref={ref}
          className={`${classes.wrapper} wrapper-shop`}
          sx={{ backgroundColor: backgroundColor || colors.white }}
        >
          <ShopHeader />
          <Box className={classes.wrapperContent}>
            <ShopContent />
          </Box>
        </Box>
      </I18nextProvider>
    </ThemeProvider>
  );
});

export default ShopLandingPage;
