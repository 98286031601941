import { FC, MouseEventHandler, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { makeStyles } from 'tss-react/mui';

import { CardData } from '~/types/my-shop';

interface CustomCardProps {
  data: CardData;
  color?: string | null;
  isMenu?: boolean;
  isShopScreen?: boolean;
  isCollectionNFT?: boolean;
  isCollectionScreen?: boolean;
  onClick?: (info: CardData) => void;
  onClickMenu?: MouseEventHandler<HTMLButtonElement>;
}

const useStyles = makeStyles()(() => ({
  card: {
    boxShadow: 'none',
    borderRadius: '12px',
    border: '1px solid #D7D7D7',
    backgroundColor: 'transparent',
    '& > .MuiCardContent-root': {
      padding: '12px',
      paddingBottom: '12px !important',
    },
  },
  wrapperImage: {
    cursor: 'pointer',
    position: 'relative',
    paddingTop: '100%',
    overflow: 'hidden',
  },
  imgContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
    display: 'block',
  },
  nftName: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '.network': {
      width: '24px',
    },
  },
  title: {
    fontWeight: 700,
    fontSize: '12px',
    color: '#1E1E1E',
    overflow: 'hidden',
    lineHeight: '24px',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  tokenId: {
    fontWeight: 400,
    fontSize: '12px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}));

const CustomCard: FC<CustomCardProps> = ({ data, onClick }) => {
  const { name, url, tokenId, networkSymbol } = data;
  const { classes } = useStyles();

  const [loading, setLoading] = useState(true);

  const handleClick = (info: CardData) => {
    if (!!onClick) {
      onClick(info);
    }
  };

  const handleLoadImage = () => {
    setLoading(false);
  };

  return (
    <Card className={classes.card}>
      <Box className={classes.wrapperImage} onClick={() => handleClick(data)}>
        {loading && (
          <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', zIndex: 1 }}>
            <CircularProgress size="50px" />
          </Box>
        )}
        <Box className={classes.imgContainer}>
          <CardMedia
            component="img"
            image={url || ''}
            alt="Thumbnails"
            loading="lazy"
            className={classes.image}
            onLoad={handleLoadImage}
          />
        </Box>
      </Box>
      <CardContent>
        <Box className={classes.titleBox}>
          {!!name && (
            <Tooltip
              title={name}
              placement="bottom"
              arrow
              PopperProps={{
                modifiers: [
                  {
                    name: 'offset',
                    options: {
                      offset: [0, -5],
                    },
                  },
                ],
              }}
            >
              <Typography variant="h5" className={classes.title}>
                {name}
              </Typography>
            </Tooltip>
          )}
          {!!networkSymbol && <img src={networkSymbol} alt="Network Symbol" className="network" />}
        </Box>
        {!!tokenId && (
          <Box>
            <Typography variant="h5" className={classes.tokenId}>
              #{tokenId}
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
  );
};

export default CustomCard;
