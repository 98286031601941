import { FC, forwardRef, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Control, Controller, FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import 'react-quill/dist/quill.snow.css';

import { useValidLinkQuill } from '~/components/TextEditor';
import { useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { MemberSiteFormEnum } from '~/enum/pages/my-shop';
import { GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { steps } from '~/pages/my-shop/create-member-site';

const useStyles = makeStyles<{ isCreate?: boolean; isEditing: boolean }>()((_, { isCreate, isEditing }) => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      minHeight: '52px',
      padding: '0px 16px',
      borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardContent-root': {
      padding: '16px 16px 0',
      '.wrapperTextEditor': {
        width: '100%',
        p: {
          margin: 0,
          padding: 0,
        },
        ...(isCreate || isEditing
          ? {
              '&:not(:last-of-type)': {
                marginBottom: '16px',
              },
            }
          : {
              minHeight: '150px',
              padding: '12px 16px',
              borderBottom: '1px solid #D7D7D7',
              '.ql-editor': {
                padding: 0,
              },
            }),
        '.MuiTypography-subtitle2': {
          fontWeight: 500,
          fontSize: '10px',
          lineHeight: '16px',
          color: '#00000099',
          letterSpacing: '1.5px',
          marginBottom: isCreate || isEditing ? '8px' : '0',
        },
        '.quill': {
          '.ql-toolbar.ql-snow': {
            borderRadius: '4px 4px 0 0',
          },
          '.ql-container.ql-snow': {
            fontSize: '1rem',
            borderRadius: '0 0 4px 4px',
            fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
            '.ql-editor': {
              minHeight: '150px',
              maxHeight: '150px',
            },
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: isCreate ? 'space-between' : 'flex-end',
    },
  },
}));

const schema = yup.object({
  policy: yup.string().required(),
});

interface MemberTermsProps {
  isCreate?: boolean;
}

export interface FormMemberTermsValues extends yup.InferType<typeof schema> {}

const formats = ['align', 'bold', 'italic', 'underline', 'size', 'color', 'link'];

const toolbarOptions = [
  [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ['bold', 'italic', 'underline'], // toggled buttons

  [{ color: [] }],

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  ['link'],

  ['clean'], // remove formatting button
];

const MemberTerms: FC<MemberTermsProps> = ({ isCreate }) => {
  const { editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handleNextStep, handlePreviousStep } = useMyShopFormWrapper();
  const [submitting, setSubmitting] = useState(false);

  const isEditing = editingAt === MemberSiteFormEnum.TERMS;

  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles({ isCreate, isEditing });
  const { enqueueSnackbar } = useSnackbar();
  const MAX_LENGTH = 10000;

  const refPolicyEditor = useRef<ReactQuill>(null);
  useValidLinkQuill(refPolicyEditor);

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const defaultValues = useMemo(
    () => ({
      policy: formData?.shopInformation?.policy || '',
    }),
    [formData]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting },
    setError,
  } = useForm<FormMemberTermsValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleChangeToEdit = () => {
    handleEditingAt(MemberSiteFormEnum.TERMS);
  };
  const handleCancel = () => {
    if (isCreate) {
      handlePreviousStep();
    } else {
      reset(defaultValues);
      handleEditingAt(undefined);
    }
  };

  const onSubmit = async (data: FormMemberTermsValues) => {
    setSubmitting(true);

    const policyLength = refPolicyEditor.current?.getEditor().getLength()!;
    if (policyLength > MAX_LENGTH) {
      setError('policy', { message: t('my_shop.message.errors.max', { max: MAX_LENGTH }) });
      setSubmitting(false);
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      shopInformation: data,
    }));
    if (isCreate) {
      handleNextStep(steps(t, isSubmitting).length);
      setSubmitting(false);
      return;
    }
    try {
      await updateMyShop({
        variables: {
          input: {
            uuid: id!,
            shopInformation: {
              policy: data.policy,
            },
          },
        },
      });
      handleEditingAt(undefined);
      setSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={t('member_site.member_terms')}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <TextEditor
            name="policy"
            ref={refPolicyEditor}
            errors={errors}
            control={control}
            isEditing={isCreate || isEditing}
          />
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting || submitting}>
                {t(isCreate ? 'back' : 'cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && (!isDirty || isSubmitting)) || submitting}
                onClick={handleSubmit(onSubmit)}
                endIcon={(isSubmitting || submitting) && <CircularProgress size={20} color="inherit" />}
              >
                {t(isCreate ? 'next' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

const TextEditor = forwardRef<
  ReactQuill,
  {
    isEditing: boolean;
    name: 'policy';
    control: Control<FormMemberTermsValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        policy: string;
      }>
    >;
  }
>(({ errors, control, isEditing }, ref) => {
  const { t } = useTranslation();

  const handleChange = (callback: (value: string) => void) => (value: string) => {
    let newValue = value;
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      newValue = '';
    }
    callback(newValue);
  };

  return (
    <Box className="wrapperTextEditor">
      <Typography variant="subtitle2">
        {t('member_site.member_terms')}
        {isEditing ? ' *' : ''}
      </Typography>
      <Controller
        name="policy"
        control={control}
        render={({ field }) =>
          isEditing ? (
            <ReactQuill
              ref={ref}
              theme="snow"
              modules={{
                toolbar: toolbarOptions,
              }}
              formats={formats}
              value={field.value || ''}
              placeholder={t('member_site.member_terms')}
              bounds=".wrapperTextEditor"
              onChange={handleChange(field.onChange)}
            />
          ) : (
            <Box className="ql-editor" dangerouslySetInnerHTML={{ __html: field.value }} />
          )
        }
      />
      {!!errors?.policy?.message && (
        <Typography variant="caption" color="error">
          {t(errors?.policy?.message || '')}
        </Typography>
      )}
    </Box>
  );
});

export default MemberTerms;
