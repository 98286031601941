import React, { useCallback, useContext, useMemo, useRef, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount, useSigner, useSwitchNetwork, WalletRequired } from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import MenuItem from '@mui/material/MenuItem';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Stepper from '@mui/material/Stepper';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../dialog/custom-dialog';

import FileUploadInput from './FileUploadInput';

import { CODE } from '~/constants/code';
import { MAX_FILE_SIZE, MAX_LENGTH } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { MethodEnum, TemplateEnum } from '~/enum/common';
import { env } from '~/env';
import {
  useUploadNftMutation,
  useUploadNftWithApiKeyMutation,
  useGetCollectionLazyQuery,
} from '~/graphql/member/types';
import { useAccount as useMe } from '~/hooks/with-account';
import { getErrorText } from '~/utils/yup.util';

const schema = yup.object({
  method: yup.mixed<MethodEnum>().oneOf(Object.values(MethodEnum)).required(),
  isSave: yup.boolean().when('method', {
    is: (value: string) => value !== MethodEnum.WithAPIKey,
    then: (schema) => schema.required(),
  }),
  template: yup
    .mixed<TemplateEnum>()
    .oneOf(Object.values(TemplateEnum))
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  name: yup
    .string()
    .max(100)
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  description: yup
    .string()
    .max(255)
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  image: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value?.length) {
          return true;
        }
        return value[0].size < MAX_FILE_SIZE;
      },
    })
    .when('method', {
      is: (value: string) => value !== MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
  mediaAttachment: yup
    .mixed<FileList>()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    })
    .when('template', {
      is: TemplateEnum.Video,
      then: (schema) => schema.required(),
    }),
  metadataUri: yup
    .string()
    .test({
      message: 'form_validation.invalid_ipfs_uri',
      test: async (value) => {
        if (!value) {
          return true;
        }
        const url = value.replace('ipfs://', env.REACT_APP_IPFS_GATEWAY_URL);
        try {
          const result = await fetch(url);
          const metadata = await result.json();

          if (!metadata.image || !metadata.name) {
            return false;
          }
          return true;
        } catch (error) {
          return false;
        }
      },
    })
    .when('method', {
      is: MethodEnum.MetadataUri,
      then: (schema) => schema.required(),
    }),
});

export interface FormAddNFTValues extends yup.InferType<typeof schema> {}

interface Props extends DialogProps {
  collectionId: string;
  onClose: (params?: { isCreated?: boolean }) => void;
}

const AddNFTDialog: React.FC<Props> = (props) => {
  const { open, onClose, collectionId } = props;

  const supportedNetworksContext = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const signer = useSigner();
  const { t } = useTranslation();
  const { selectedOrganization } = useMe();
  const { switchNetwork } = useSwitchNetwork();
  const { account: accountAddress } = useAccount();

  const [step, setStep] = useState(-1);

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const supportedNetworks = supportedNetworksContext?.supportedNetworks;

  const {
    control,
    reset,
    handleSubmit,
    resetField,
    formState: { errors, isSubmitting },
  } = useForm<FormAddNFTValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: {
      method: MethodEnum.NoAPIKey,
      template: TemplateEnum.Image,
      isSave: false,
      name: '',
      description: '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const [uploadNft] = useUploadNftMutation();
  const [getCollection] = useGetCollectionLazyQuery();
  const [uploadNFTWithApiKey] = useUploadNftWithApiKeyMutation();

  const method = useWatch({
    control,
    name: 'method',
  });
  const template = useWatch({
    control,
    name: 'template',
  });

  const errorMessage = (err: any) => {
    if (err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code === CODE.NOT_OWNER_COLLECTION) {
      return t('you_are_not_the_owner_of_this_collection');
    } else if (err?.code === CODE.CALL_EXCEPTION || err.code === CODE.BAD_DATA) {
      return t('toast_message.network_not_match');
    } else if (err?.code === CODE.ACTION_REJECTED) {
      return t('form_validation.user_denied_signature');
    } else if (err?.graphQLErrors?.[0]?.extensions?.exception?.action === 'estimateGas') {
      return t('toast_message.insufficient_funds_for_gas');
    }
    return err?.error?.message || err.shortMessage || err.message || t('my_shop.message.error');
  };

  const handleClose = (isCreated = false) => {
    onClose({ isCreated });
    if (isCreated) {
      reset();
      setStep(-1);
    }
  };

  const onSubmit = useCallback(
    async (data: FormAddNFTValues) => {
      try {
        setStep(0);
        const collectionResponse = await getCollection({
          fetchPolicy: 'cache-and-network',
          variables: {
            collectionUuid: collectionId,
          },
        });
        const collection = collectionResponse?.data?.getCollection;
        if (!collection) {
          throw new Error(t('collection_screen.collection_not_found'));
        }
        await switchNetwork(Number(supportedNetworks?.[collection.network]?.chainId));
        await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
        if (!refSigner.current) {
          throw new Error(t('you_are_not_the_owner_of_this_collection'));
        }
        const contract = ERC721G__factory.connect(collection.contractAddress, refSigner.current);
        const owner = await contract.owner();
        const ownerLowerCase = owner.toLowerCase();
        const currentWalletLowerCase = accountAddress.toLowerCase();
        const masterWalletLowerCase = selectedOrganization.masterWalletAddress?.toLowerCase();
        if (![currentWalletLowerCase, masterWalletLowerCase].includes(ownerLowerCase)) {
          throw new Error(t('you_are_not_the_owner_of_this_collection'));
        }

        let metadataUrl: string = data.metadataUri ?? '';
        if (data.method === MethodEnum.NoAPIKey) {
          const { data: nftResource } = await uploadNft({
            variables: {
              input: {
                name: data.name ?? '',
                description: data.description ?? '',
                image: data.image?.[0] ?? null,
                mediaAttachment: data.mediaAttachment?.[0],
              },
            },
          });
          metadataUrl = nftResource?.uploadNFT?.metadataUrl ?? '';
        }
        if (!metadataUrl) return;

        setStep(1);
        await (await contract['safeMint(address,string)'](accountAddress, metadataUrl)).wait();
        setStep(2);
      } catch (e: any) {
        enqueueSnackbar(errorMessage(e), { variant: 'error' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      collectionId,
      accountAddress,
      supportedNetworks,
      selectedOrganization.uuid,
      selectedOrganization.masterWalletAddress,
      t,
      uploadNft,
      switchNetwork,
      getCollection,
      enqueueSnackbar,
      uploadNFTWithApiKey,
    ]
  );

  const methodOptions = useMemo(
    () =>
      [
        {
          value: MethodEnum.NoAPIKey,
          label: t('method_options.no_api_key'),
        },
        {
          value: MethodEnum.MetadataUri,
          label: t('method_options.metadata_uri'),
        },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [t]
  );

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={handleClose}
      dialogTitle={t('add_nft_dialog.title')}
      dialogContent={
        step >= 0 ? (
          <>
            <Stepper activeStep={step} orientation="vertical">
              {method !== MethodEnum.MetadataUri && (
                <Step>
                  <StepLabel error={!isSubmitting && step === 0} optional={t('update_nft_step.upload.description')}>
                    {t('update_nft_step.upload.title')}
                  </StepLabel>
                </Step>
              )}
              <Step>
                <StepLabel error={!isSubmitting && step === 1} optional={t('update_nft_step.mint.description')}>
                  {t('update_nft_step.mint.title')}
                </StepLabel>
              </Step>
            </Stepper>
          </>
        ) : (
          <>
            {method !== MethodEnum.MetadataUri && (
              <>
                <Box height="156px" margin="16px 0 8px">
                  <FileUploadInput
                    type="image"
                    name="image"
                    control={control}
                    label={template === TemplateEnum.Video ? 'Cover' : undefined}
                    error={!!errors.image?.message}
                    helperText={t(errors.image?.message as any, {
                      size: 10,
                    })}
                  />
                </Box>
                {template === TemplateEnum.Video && (
                  <FileUploadInput
                    type="video"
                    control={control}
                    name="mediaAttachment"
                    disabled={isSubmitting}
                    error={!!errors.mediaAttachment?.message}
                    helperText={t(errors.mediaAttachment?.message as any, {
                      size: 10,
                    })}
                  />
                )}
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      fullWidth
                      label={t('image_name')}
                      variant="outlined"
                      margin="normal"
                      disabled={isSubmitting}
                      error={!!errors.name?.message}
                      helperText={getErrorText(errors.name?.message, t)}
                      {...field}
                    />
                  )}
                />
              </>
            )}
            <Controller
              name="method"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  label={t('method')}
                  fullWidth
                  margin="normal"
                  error={!!errors.method?.message}
                  helperText={t(errors.method?.message as any)}
                  required
                  disabled={isSubmitting}
                  {...field}
                  onChange={(e) => {
                    resetField('isSave');
                    field.onChange(e);
                  }}
                >
                  {methodOptions}
                </TextField>
              )}
            />
            {method !== MethodEnum.MetadataUri && (
              <>
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      rows={3}
                      fullWidth
                      multiline
                      margin="normal"
                      variant="outlined"
                      disabled={isSubmitting}
                      error={!!errors.description?.message}
                      helperText={getErrorText(errors.description?.message, t)}
                      label={`${t('description')} (${field?.value?.length || 0}/${MAX_LENGTH})`}
                      required
                      {...field}
                    />
                  )}
                />
              </>
            )}
            {method === MethodEnum.MetadataUri && (
              <Controller
                name="metadataUri"
                control={control}
                render={({ field }) => (
                  <TextField
                    fullWidth
                    label={t('metadataUri')}
                    variant="outlined"
                    margin="normal"
                    error={!!errors.metadataUri?.message}
                    helperText={t(errors.metadataUri?.message as any)}
                    required
                    {...field}
                  />
                )}
              />
            )}
          </>
        )
      }
      actions={
        isSubmitting || step < 0
          ? [
              <Button color="primary" variant="outlined" onClick={() => handleClose()} disabled={isSubmitting}>
                {t('cancel')}
              </Button>,
              <WalletRequired>
                <Button
                  color="primary"
                  variant="contained"
                  disabled={isSubmitting}
                  endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
                  onClick={handleSubmit(onSubmit)}
                >
                  {t('create')}
                </Button>
              </WalletRequired>,
            ]
          : step === 2
          ? [
              <Button disabled={isSubmitting} color="primary" variant="contained" onClick={() => handleClose(true)}>
                {t('done')}
              </Button>,
            ]
          : [
              <Button disabled={isSubmitting} color="primary" variant="contained" onClick={() => setStep(-1)}>
                {t('back')}
              </Button>,
            ]
      }
    />
  );
};

export default AddNFTDialog;
