import './utils/yup.util';
import { createRef, useEffect, useMemo } from 'react';

import { IpfsGatewaysProvider } from '@gu-corp/react-ipfs-media';
import {
  createClient,
  GUWalletConnectorProvider,
  InjectedConnector,
  TorusConnector,
  WalletConnectConnector,
} from '@gusdk/gu-wallet-connector';
import CloseIcon from '@mui/icons-material/Close';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import * as locales from '@mui/material/locale';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { SnackbarKey, SnackbarProvider } from 'notistack';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { BrowserRouter } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import { ConfirmationDialogProvider } from './contexts/ConfirmationDialogProvider';
import { AppRouteEnum } from './enum/AppRouteEnum';
import { initFirebaseAuth } from './hooks/with-firebase-auth';
import i18nMain from './i18n';
import i18nShop from './i18n/shop.i18n';
import AppRouter from './router/routerConfig';
import { theme } from './styles/theme';

import { env } from '~/env';

initFirebaseAuth({
  firebaseConfig: JSON.parse(env.REACT_APP_FIREBASE_CLIENT_CREDENTIALS),
  needEmailVerified: true,
  whenAuthed: AppRouteEnum.Home,
  whenUnauthed: AppRouteEnum.SignIn,
});

const notistackRef = createRef<SnackbarProvider>();
const onClickDismiss = (key: SnackbarKey) => () => {
  notistackRef.current?.closeSnackbar(key);
};

const client = createClient({
  connectors: [
    new InjectedConnector(),
    new WalletConnectConnector({
      options: {
        projectId: env.REACT_APP_PROJECT_ID_WALLET_CONNECT,
        qrModalOptions: {
          themeVariables: {
            '--wcm-z-index': '1301',
          },
        },
      },
    }),
    ...(env.REACT_APP_ENABLE_TORUS === 'TRUE'
      ? [
          new TorusConnector({
            options: {
              constructorArgs: {},
              loginArgs: {},
            },
          }),
        ]
      : []),
  ],
});

const useStyles = makeStyles()(() => ({
  snackBar: {
    '.SnackbarItem-message': {
      lineBreak: 'anywhere',
      wordBreak: 'break-word',
    },
  },
}));

const App: React.FC = () => {
  const { classes } = useStyles();
  const { i18n } = useTranslation('translation', { i18n: i18nMain });
  const { i18n: i18nOfShop } = useTranslation('translation', { i18n: i18nShop });

  useEffect(() => {
    const currentShopLanguage = window.localStorage.getItem('shopLanguage');
    if (currentShopLanguage !== i18nOfShop.language) {
      window.localStorage.setItem('shopLanguage', i18nOfShop.language);
    }
  }, [i18nOfShop.language]);

  useEffect(() => {
    const currentLanguage = window.localStorage.getItem('language');
    if (currentLanguage !== i18n.language) {
      window.localStorage.setItem('language', i18n.language);
    }
  }, [i18n.language]);

  const themeWithLocale = useMemo(
    () => createTheme(theme, locales[i18n.language === 'ja' ? 'jaJP' : 'enUS']),
    [i18n.language]
  );

  return (
    <BrowserRouter>
      <ThemeProvider theme={themeWithLocale}>
        <I18nextProvider i18n={i18nMain} defaultNS="translation">
          <CssBaseline />
          <SnackbarProvider
            ref={notistackRef}
            className={classes.snackBar}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            action={(key) => (
              <IconButton onClick={onClickDismiss(key)} size="small" style={{ color: 'white' }}>
                <CloseIcon />
              </IconButton>
            )}
          >
            <GUWalletConnectorProvider client={client} language={i18n.language as 'en' | 'ja'}>
              <IpfsGatewaysProvider
                gateways={env.REACT_APP_IPFS_GATEWAY_URL ? [env.REACT_APP_IPFS_GATEWAY_URL] : undefined}
              >
                <ConfirmationDialogProvider>
                  <AppRouter />
                </ConfirmationDialogProvider>
              </IpfsGatewaysProvider>
            </GUWalletConnectorProvider>
          </SnackbarProvider>
        </I18nextProvider>
      </ThemeProvider>
    </BrowserRouter>
  );
};

export default App;
