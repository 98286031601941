import { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import WrapperTextField from '~/components/WrapperTextField';
import { useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { ShopFormEnum } from '~/enum/pages/my-shop';
import { GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles()(() => ({
  wrapper: {
    '.MuiPaper-root': {
      boxShadow: 'none',
      overflow: 'hidden',
      borderRadius: '8px',
      border: '1px solid #D7D7D7',
    },
    '& > .MuiCard-root > .MuiCardHeader-root': {
      minHeight: '52px',
      padding: '0px 16px',
      borderBottom: '1px solid #D7D7D7',
      '.MuiCardHeader-action': {
        alignSelf: 'center',
      },
    },
    '.MuiCardHeader-title': {
      display: 'unset!important',
    },
    '.MuiCardContent-root': {
      padding: '16px 16px 0',
      display: 'flex',
      '.cardContent': {
        width: '100%',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: 'flex-end',
    },
  },
}));

const schema = yup.object({
  meta: yup.string().nullable().max(1000),
  name: yup.string().nullable().max(100).required(),
  faviconFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  favicon: yup.string().nullable(),
});

export interface FormAdvancedSettingValues extends yup.InferType<typeof schema> {}

const AdvancedSetting = () => {
  const { formData, setFormData } = useMyShopFormWrapper();
  const { editingAt, handleEditingAt } = useShopDetail();
  const [submitting, setSubmitting] = useState(false);

  const isEditing = editingAt === ShopFormEnum.ADVANCED;

  const { id } = useParams();
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const defaultValues = useMemo(
    () => ({
      name: formData?.siteSetting?.name!,
      meta: formData?.siteSetting?.meta,
      favicon: formData?.siteSetting?.favicon,
      faviconFile: formData?.siteSetting?.faviconFile,
    }),
    [formData]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting },
  } = useForm<FormAdvancedSettingValues>({
    mode: 'onChange',
    defaultValues,
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleChangeToEdit = () => {
    handleEditingAt(ShopFormEnum.ADVANCED);
  };
  const handleCancel = () => {
    reset(defaultValues);
    handleEditingAt(undefined);
  };

  const onSubmit = async (data: FormAdvancedSettingValues) => {
    setSubmitting(true);
    setFormData((prevState) => ({
      ...prevState,
      siteSetting: {
        ...prevState.siteSetting,
        ...data,
      },
    }));
    try {
      await updateMyShop({
        variables: {
          input: {
            uuid: id!,
            siteSetting: {
              name: data.name,
              meta: data.meta,
              favicon: data.favicon,
            },
            faviconFile: data.faviconFile?.[0],
          },
        },
      });
      handleEditingAt(undefined);
      setSubmitting(false);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (error: any) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={t('my_shop.advanced_setting')}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          <Box className="cardContent">
            <Box className="wrapperInput">
              <WrapperTextField
                name="name"
                control={control}
                isEditing={isEditing}
                label={t('site_name_on_search_engines')}
                render={(field) => (
                  <TextField
                    fullWidth
                    margin="normal"
                    disabled={isSubmitting}
                    error={!!errors.name?.message}
                    label={t('site_name_on_search_engines')}
                    helperText={getErrorText(errors.name?.message, t)}
                    required
                    {...field}
                  />
                )}
              />
            </Box>
            <Box className="wrapperInput">
              <WrapperTextField
                name="meta"
                control={control}
                isEditing={isEditing}
                label={t('meta_description_on_search_engines')}
                readOnlyBodyStyle={{
                  height: '72px',
                }}
                render={(field) => (
                  <TextField
                    rows={4}
                    fullWidth
                    multiline
                    margin="normal"
                    disabled={isSubmitting}
                    placeholder={t('my_shop.shop_description')}
                    label={t('meta_description_on_search_engines')}
                    error={!!errors.meta?.message}
                    helperText={getErrorText(errors.meta?.message, t)}
                    {...field}
                  />
                )}
              />
            </Box>
          </Box>
        </CardContent>
        <CardActions>
          {isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting || submitting}>
                {t('cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={!isDirty || isSubmitting || submitting}
                onClick={handleSubmit(onSubmit)}
                endIcon={(isSubmitting || submitting) && <CircularProgress size={20} color="inherit" />}
              >
                {t('save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

export default AdvancedSetting;
