import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';

import { WalletConnectorDialog, useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import AddMyShopCollectionNFT from './Minted/AddMyShopCollectionNFT';
import ImageNFTList from './MintOnPurchase/ImageNFTList';
import { ICollectionInfo, useShopCollectionDetail } from './ShopCollectionDetail';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import WrapperWithFab from '~/components/WrapperWithFab';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import ExistingNFTList from '~/pages/my-shop/shop-detail/components/CollectionDetail/Minted/ExistingNFTList';
import { INftInfo, NFT_SHOP_TYPE } from '~/types/my-shop';

interface ContentCollectionDetailProps {
  tokenList: INftInfo[] | undefined;
  onOpenRemoveCollectionDialog: () => void;
  collectionInfo: ICollectionInfo | undefined;
  setTokenList: Dispatch<SetStateAction<INftInfo[] | undefined>>;
}

const useStyles = makeStyles()(() => ({
  wrapperSalesNFT: {
    width: '100%',
  },
}));

const ContentCollectionDetail: FC<ContentCollectionDetailProps> = ({
  tokenList,
  collectionInfo,
  setTokenList,
  onOpenRemoveCollectionDialog,
}) => {
  const { data: myShop } = useShopDetail();
  const { data: myShopCollection } = useShopCollectionDetail();

  const { t } = useTranslation();
  const { classes } = useStyles();
  const { isLocked } = useAccount();
  const { account } = useWalletAccount();
  const { showErrorByKey } = useNotify();

  const [isOpenAddNFT, setIsOpenAddNFT] = useState(false);
  const [openWalletDialog, setOpenWalletDialog] = useState(false);

  const isPreMint = myShop.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;

  const openAddNFTDialog = useCallback(() => {
    setIsOpenAddNFT(true);
  }, []);

  const closeAddNFTDialog = useCallback(() => {
    setIsOpenAddNFT(false);
  }, []);

  const handleOpenAddNFT = () => {
    if (isLocked) {
      showErrorByKey('toast_message.organization_locked');
      return;
    }

    if (account) {
      openAddNFTDialog();
    } else if (!isOpenAddNFT) {
      setOpenWalletDialog(true);
    }
  };

  const handleCloseWalletDialog = () => {
    setOpenWalletDialog(false);
  };

  const menus = [
    ...(isPreMint
      ? []
      : [
          {
            title: t('add_nft'),
            onClick: handleOpenAddNFT,
          },
        ]),
    {
      title: t('my_shop.remove_from_shop'),
      onClick: onOpenRemoveCollectionDialog,
    },
  ];

  const renderContent = () => {
    if (isPreMint) {
      if (!myShopCollection) return null;
      return (
        <CustomCardTable
          cardTitle={collectionInfo?.myShopCollectionName || collectionInfo?.name || '-'}
          headerAction={<HeaderAction menus={menus} />}
          cardContent={<ImageNFTList collectionInfo={collectionInfo} />}
        />
      );
    } else {
      return (
        <WrapperWithFab onClick={handleOpenAddNFT}>
          <CustomCardTable
            cardTitle={collectionInfo?.myShopCollectionName || collectionInfo?.name || '-'}
            headerAction={<HeaderAction menus={menus} />}
            cardContent={
              <ExistingNFTList
                tokenList={tokenList}
                collectionInfo={collectionInfo}
                setTokenList={setTokenList}
                onOpenAddNFT={handleOpenAddNFT}
              />
            }
          />
        </WrapperWithFab>
      );
    }
  };

  return (
    <Box className={classes.wrapperSalesNFT}>
      {renderContent()}
      {account && isOpenAddNFT && <AddMyShopCollectionNFT open={isOpenAddNFT} onClose={closeAddNFTDialog} />}
      <WalletConnectorDialog open={openWalletDialog} onClose={handleCloseWalletDialog} />
    </Box>
  );
};

export default ContentCollectionDetail;
