import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { GridColDef, GridColumnHeaderTitle, GridSortModel, jaJP } from '@mui/x-data-grid-pro';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import CustomGridToolbarSearchByAPI from '~/components/custom-grid-toolbar-search-by-api';
import CustomDataGrid from '~/components/CustomDataGrid';
import CustomDialog from '~/components/dialog/custom-dialog';
import CustomNodata from '~/components/no-data';
import { NumberStringTextField } from '~/components/NumberStringTextField';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { CURRENCY_ICONS, MAX_NUMBER } from '~/constants/common';
import {
  Currency,
  GetInfoUsageDocument,
  LicenseType,
  useGetInfoUsageQuery,
  useUpdateNextMonthLicenseMutation,
} from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { useNotify } from '~/hooks/useNotify';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import i18n from '~/i18n';
import { getErrorText } from '~/utils/yup.util';

const { EDIT } = SCREEN_PERMISSION.SETTING.PLAN_MANAGEMENT;

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiDataGrid-root': {
      border: 'none',
    },
    '.MuiDataGrid-toolbarContainer': {
      padding: '0',
      margin: '0',
    },
    '.actions': {
      gap: '8px',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
    },
    '.wrapperDatePicker': {
      gap: '8px',
      display: 'flex',
      marginBottom: '8px',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  },
}));

interface ILicense {
  id: string;
  title: string;
  type: LicenseType;
  feeNextMonth: number;
  freeQuantity: number;
  quantityThisMonth: number;
  quantityNextMonth: number;
}

const licenseNames = [
  {
    title: 'license.user',
    currentKey: 'userLicenses',
    freeKey: 'numberOfAdminUsers',
    type: LicenseType.AdditionalUserFee,
    feeKey: 'feeOfUserLicensesNextMonth',
    nextKey: 'userLicensesNextMonth',
  },
  {
    title: 'license.shop',
    freeKey: 'numberOfShops',
    currentKey: 'shopLicenses',
    type: LicenseType.AdditionalShopFee,
    feeKey: 'feeOfShopLicensesNextMonth',
    nextKey: 'shopLicensesNextMonth',
  },
  {
    title: 'license.member_site',
    freeKey: 'numberOfMemberSites',
    currentKey: 'memberSiteLicenses',
    nextKey: 'memberSiteLicensesNextMonth',
    type: LicenseType.AdditionalMemberSiteFee,
    feeKey: 'feeOfMemberSiteLicensesNextMonth',
  },
  {
    freeKey: 'numberOfMemberPerSite',
    currentKey: 'memberPerSiteLicenses',
    type: LicenseType.AdditionalMemberFee,
    feeKey: 'feeOfMemberLicensesNextMonth',
    title: 'license.member_per_member_site',
    nextKey: 'memberPerSiteLicensesNextMonth',
  },
];

const schema = yup.object({
  quantity: yup.string().maxNumber(MAX_NUMBER).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const LicenseStatements = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();
  const { plan: currentPlan } = useAccount();
  const [editable] = useCheckPermissions([EDIT]);

  const [search, setSearch] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedItem, setSelectedItem] = useState<ILicense>();
  const [sortModel, setSortModel] = useState<GridSortModel>([]);

  const { data: infoUsageRes, loading: loadingInfoUsage } = useGetInfoUsageQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [updateNextMonthLicense] = useUpdateNextMonthLicenseMutation({
    refetchQueries: [GetInfoUsageDocument],
  });

  const infoUsage = infoUsageRes?.getInfoUsage;

  const {
    control,
    reset,
    formState: { errors, dirtyFields, isSubmitting },
    handleSubmit,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  const debounceValue = useDebounce(search, 1000);
  const rows = useMemo(() => {
    if (!infoUsage) {
      return [];
    }
    return licenseNames
      .filter((item) => {
        const type = t(item.title).toLowerCase() || '';
        return type.includes(debounceValue.toLowerCase() || '');
      })
      .reduce((result, item) => {
        const thisMonth = infoUsage[item.currentKey as 'userLicenses'];
        const nextMonth = infoUsage[item.nextKey as 'userLicensesNextMonth'];
        const feeNextMonth = infoUsage[item.feeKey as 'feeOfUserLicensesNextMonth'];
        const freeQuantity = currentPlan?.[item.freeKey as 'numberOfAdminUsers'] || 0;
        if (!!thisMonth) {
          result.push({
            feeNextMonth,
            freeQuantity,
            id: item.type,
            type: item.type,
            title: t(item.title),
            quantityThisMonth: thisMonth,
            quantityNextMonth: nextMonth,
          });
        }
        return result;
      }, [] as ILicense[]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debounceValue, infoUsage, i18n.language]);

  // Handle Select Type
  const selectType = (item: ILicense) => {
    setSelectedItem(item);
  };
  const clearSelectedItem = () => {
    setSelectedItem(undefined);
  };

  // Handle Open Dialog
  const handleOpenDialog = (row?: ILicense) => {
    reset({ quantity: row?.quantityNextMonth.toString() });
    setOpenDialog(true);
  };
  const handleCloseDialog = () => {
    setOpenDialog(false);
    clearSelectedItem();
    reset({ quantity: '' });
  };

  const handleClickAction = (row: ILicense, callback: (row?: ILicense) => void) => () => {
    selectType(row);
    callback(row);
  };

  const columns: GridColDef<ILicense>[] = useMemo(
    () => [
      {
        width: 300,
        field: 'title',
        headerName: t('type'),
      },
      {
        width: 220,
        field: 'quantityThisMonth',
        valueFormatter: ({ value }) => value.toLocaleString(),
        renderHeader: () => (
          <>
            <GridColumnHeaderTitle label={t('settings.plan_management.this_month_quantity')} columnWidth={0} />
            <Tooltip title={t('settings.plan_management.this_month_quantity_caption')} placement="top">
              <InfoOutlinedIcon color="action" sx={{ fontSize: 16, marginLeft: '4px' }} />
            </Tooltip>
          </>
        ),
      },
      {
        width: 220,
        field: 'quantityNextMonth',
        valueFormatter: ({ value }) => value.toLocaleString(),
        renderHeader: () => (
          <>
            <GridColumnHeaderTitle label={t('settings.plan_management.next_month_quantity')} columnWidth={0} />
            <Tooltip title={t('settings.plan_management.next_month_quantity_caption')} placement="top">
              <InfoOutlinedIcon color="action" sx={{ fontSize: 16, marginLeft: '4px' }} />
            </Tooltip>
          </>
        ),
      },
      {
        width: 220,
        field: 'freeQuantity',
        headerName: t('settings.plan_management.free_quantity'),
        valueFormatter: ({ value }) => value.toLocaleString(),
        renderHeader: () => (
          <>
            <GridColumnHeaderTitle label={t('settings.plan_management.free_quantity')} columnWidth={0} />
            <Tooltip title={t('settings.plan_management.free_quantity_caption')} placement="top">
              <InfoOutlinedIcon color="action" sx={{ fontSize: 16, marginLeft: '4px' }} />
            </Tooltip>
          </>
        ),
      },
      {
        width: 240,
        field: 'feeNextMonth',
        renderHeader: () => (
          <>
            <GridColumnHeaderTitle label={t('settings.plan_management.total_fee_of_next_month')} columnWidth={0} />
            <Tooltip title={t('settings.plan_management.total_fee_of_next_month_caption')} placement="top">
              <InfoOutlinedIcon color="action" sx={{ fontSize: 16, marginLeft: '4px' }} />
            </Tooltip>
          </>
        ),
        valueFormatter: ({ value }) => `${CURRENCY_ICONS[Currency.Usd]}${value.toLocaleString()}`,
      },
      {
        width: 100,
        headerName: '',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        renderCell: ({ row }) =>
          editable && (
            <Button variant="contained" color="primary" onClick={handleClickAction(row, handleOpenDialog)}>
              {t('edit')}
            </Button>
          ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, selectedItem]
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    localStorage.setItem('licenseStatements', JSON.stringify(model));
  };

  const onSubmit = async (data: FormValues) => {
    try {
      if (!selectedItem) return;
      await updateNextMonthLicense({
        variables: {
          input: {
            type: selectedItem.type,
            quantity: parseInt(data.quantity),
          },
        },
      });
      handleCloseDialog();
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err) {
      showError(err);
    }
  };

  const toolbarProps = {
    search,
    searchLabel: t('type'),
    handleSearch,
  };

  useEffect(() => {
    const storedSortModel = localStorage.getItem('licenseStatements');
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  return (
    <Box className={classes.wrapper}>
      <CustomCardTable
        cardTitle={t('license_statements')}
        cardContent={
          <CustomDataGrid
            autoHeight
            rows={rows}
            columns={columns}
            sortModel={sortModel}
            disableVirtualization
            loading={loadingInfoUsage}
            disableRowSelectionOnClick
            onSortModelChange={handleSortModelChange}
            slotProps={{
              toolbar: toolbarProps,
              noRowsOverlay: {
                message: t('no_data_available'),
              },
            }}
            localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
            slots={{
              toolbar: CustomGridToolbarSearchByAPI,
              noRowsOverlay: CustomNodata,
              noResultsOverlay: CustomNodata,
            }}
          />
        }
      />
      <CustomDialog
        width="md"
        open={openDialog}
        dialogTitle={t('settings.plan_management.edit_next_month_quantity')}
        dialogContent={
          <Controller
            name="quantity"
            control={control}
            render={({ field }) => (
              <NumberStringTextField
                fullWidth
                margin="normal"
                variant="outlined"
                disabled={isSubmitting}
                error={!!errors.quantity?.message}
                helperText={getErrorText(errors.quantity?.message, t)}
                label={t('settings.plan_management.next_month_quantity')}
                {...field}
              />
            )}
          />
        }
        actions={[
          <Button variant="outlined" disabled={isSubmitting} onClick={handleCloseDialog}>
            {t('cancel')}
          </Button>,
          <Button
            variant="contained"
            disabled={isSubmitting || !isDirty}
            endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
            onClick={handleSubmit(onSubmit)}
          >
            {t('save')}
          </Button>,
        ]}
        onClose={handleCloseDialog}
      />
    </Box>
  );
};

export default LicenseStatements;
