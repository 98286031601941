import { FC, useEffect, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { JsonRpcProvider } from 'ethers';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import LoaderCenter from '~/components/loader-center';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { Collection } from '~/graphql/member/types';

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    padding: '12px 16px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #D7D7D7',
    '.MuiTypography-subtitle2': {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
      color: '#00000099',
      letterSpacing: '1.5px',
    },
    '.MuiTypography-body2': {
      fontWeight: 400,
      fontSize: '16px',
      overflow: 'auto',
      minHeight: '24px',
      lineHeight: '24px',
      color: '#000000DE',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      letterSpacing: '0.15px',
    },
  },
  lastItemStyle: {
    marginBottom: '16px',
  },
}));

const SmartContract: FC<{ collection: Collection | undefined }> = ({ collection }) => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { supportedNetworks } = useSupportedNetworks();

  const [attributes, setAttributes] = useState({
    paused: false,
    burnable: true,
    ownerAddress: '',
    transferable: true,
    incrementable: true,
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (collection) {
        setIsLoading(true);
        const provider = new JsonRpcProvider(supportedNetworks?.[collection.network]?.rpcUrl);
        const factory = ERC721G__factory.connect(collection.contractAddress, provider);
        const paused = await factory.paused();
        const ownerAddress = await factory.owner();
        const burnable = await factory.isBurnable();
        const transferable = await factory.isTransferable();
        const incrementable = await factory.isIncrementable();
        setAttributes({
          paused,
          burnable,
          ownerAddress,
          transferable,
          incrementable,
        });
        setIsLoading(false);
      }
    })();
  }, [collection, supportedNetworks]);
  const smartContractData = [
    {
      id: 1,
      title: t('smart_contract_info.collection_owner'),
      value: <Typography variant="body2">{attributes.ownerAddress}</Typography>,
    },
    {
      id: 2,
      title: t('smart_contract_info.paused'),
      value: attributes.paused ? (
        <CheckIcon color="primary" fontSize="medium" />
      ) : (
        <CloseIcon color="error" fontSize="medium" />
      ),
    },
    {
      id: 3,
      title: t('smart_contract_info.burnable'),
      value: attributes.burnable ? (
        <CheckIcon color="primary" fontSize="medium" />
      ) : (
        <CloseIcon color="error" fontSize="medium" />
      ),
    },
    {
      id: 4,
      title: t('smart_contract_info.transferable'),
      value: attributes.transferable ? (
        <CheckIcon color="primary" fontSize="medium" />
      ) : (
        <CloseIcon color="error" fontSize="medium" />
      ),
    },
    {
      id: 5,
      title: t('smart_contract_info.incrementable'),
      value: attributes.incrementable ? (
        <CheckIcon color="primary" fontSize="medium" />
      ) : (
        <CloseIcon color="error" fontSize="medium" />
      ),
    },
  ];

  return (
    <Box>
      <CustomCardTable
        cardTitle={t('collection_screen.smart_contract_info')}
        cardContent={
          !!collection && !isLoading ? (
            smartContractData.map((item) => (
              <Box
                key={item.id}
                className={`${classes.wrapper} ${item.id === smartContractData.length ? classes.lastItemStyle : ''}`}
              >
                <Typography variant="subtitle2">{item.title ?? ''}</Typography>
                {item.value}
              </Box>
            ))
          ) : (
            <Box height="200px">
              <LoaderCenter />
            </Box>
          )
        }
      />
    </Box>
  );
};

export default SmartContract;
