import { Dispatch, SetStateAction, useCallback, useContext, useRef } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount as useWalletAccount, useSwitchNetwork, useSigner } from '@gusdk/gu-wallet-connector';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { useAccount } from './with-account';

import { CODE } from '~/constants/code';
import { ROOT_WALLET } from '~/constants/common';
import { SupportedNetworksContext, SupportedNetworksContextValue } from '~/contexts/SupportedNetworksProvider';
import { checkMinter, grantMinterRole } from '~/utils/erc721g.util';

interface Collection {
  id?: string;
  uuid?: string;
  contractAddress: string;
  network: string;
}

type BaseGrant = {
  setOpenWalletConnector: Dispatch<SetStateAction<boolean>>;
};

type GrantMintType = (props: BaseGrant) => [(collection: Collection, approve: boolean) => Promise<boolean | undefined>];
type GrantTransferType = (
  props: BaseGrant
) => [(collection: Collection, tokenId: string, approve: boolean) => Promise<boolean | undefined>];

const useGrantMintPermission: GrantMintType = ({ setOpenWalletConnector }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const signer = useSigner();
  const { t } = useTranslation();
  const { account } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { switchNetwork } = useSwitchNetwork();
  const { selectedOrganization } = useAccount();

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const handleError = useCallback(
    (err: any) => {
      enqueueSnackbar(
        err?.code === CODE.ACTION_REJECTED || err?.wrapped?.name === 'RPCRequestError'
          ? t('form_validation.user_denied_signature')
          : err?.error?.message || err?.message || t('my_shop.message.error'),
        {
          variant: 'error',
        }
      );
    },
    [t, enqueueSnackbar]
  );

  const handleGrantMintPermission = async (collection: Collection, approve: boolean) => {
    try {
      if (!collection) {
        return;
      }
      if (!account) {
        setOpenWalletConnector(true);
        return;
      }
      await switchNetwork(Number(supportedNetworks?.[collection.network]?.chainId));
      await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
      const contract = ERC721G__factory.connect(collection.contractAddress, refSigner.current);
      const owner = await contract.owner();
      const ownerLowerCase = owner.toLowerCase();
      const currentWalletLowerCase = account.toLowerCase();
      const masterWalletLowerCase = selectedOrganization.masterWalletAddress?.toLowerCase();
      if (![currentWalletLowerCase, masterWalletLowerCase].includes(ownerLowerCase)) {
        enqueueSnackbar(t('you_are_not_the_owner_of_this_collection'), { variant: 'error' });
        return;
      }

      try {
        // Check minter
        const isMinter = await checkMinter(contract, selectedOrganization.masterWalletAddress!);
        if ((isMinter && !approve) || (!isMinter && approve)) {
          // Grant for minter role to mint
          const tx = await grantMinterRole(contract, selectedOrganization.masterWalletAddress!, approve);
          await tx.wait();
        }
        return true;
      } catch (err: any) {
        handleError(err);
        return;
      }
    } catch (err: any) {
      enqueueSnackbar(
        err?.code === CODE.ACTION_REJECTED
          ? t('form_validation.user_denied_signature')
          : err?.name === CODE.CHAIN_NOT_CONFIGURED
          ? t('my_shop.message.chain_not_configured')
          : err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code !== CODE.NOT_SUPPORT_COLLECTION
          ? t('contract_is_not_support')
          : err?.error?.message || err?.shortMessage || err?.message || t('my_shop.message.error'),
        {
          variant: 'error',
        }
      );
      throw err;
    }
  };

  return [handleGrantMintPermission];
};

const useGrantTransferPermission: GrantTransferType = ({ setOpenWalletConnector }) => {
  const { supportedNetworks } = useContext(SupportedNetworksContext) as SupportedNetworksContextValue;

  const signer = useSigner();
  const { t } = useTranslation();
  const { account } = useAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { switchNetwork } = useSwitchNetwork();
  const { selectedOrganization } = useAccount();

  const refSigner = useRef(signer);
  refSigner.current = signer;

  const handleError = useCallback(
    (err: any) => {
      enqueueSnackbar(
        err?.code === CODE.ACTION_REJECTED || err?.wrapped?.name === 'RPCRequestError'
          ? t('form_validation.user_denied_signature')
          : err?.error?.message || err?.shortMessage || err?.message || t('my_shop.message.error'),
        {
          variant: 'error',
        }
      );
    },
    [t, enqueueSnackbar]
  );

  const handleGrantTransferPermission = async (collection: Collection, tokenId: string, approve: boolean) => {
    try {
      if (!collection) {
        return;
      }
      if (!account) {
        setOpenWalletConnector(true);
        return;
      }
      await switchNetwork(Number(supportedNetworks?.[collection.network]?.chainId));
      await new Promise((done) => setTimeout(done, 1000)); // Wait for update chainId in refSigner
      const contract = ERC721G__factory.connect(collection.contractAddress, refSigner.current);
      const approvedAddress = await contract.getApproved(tokenId);
      const approvedAddressLowerCase = approvedAddress.toLowerCase();
      const owner = await contract.ownerOf(tokenId);
      const ownerLowerCase = owner.toLowerCase();
      const masterWalletLowerCase = selectedOrganization?.masterWalletAddress?.toLowerCase() || '';
      const isGranted = [approvedAddressLowerCase, ownerLowerCase].includes(masterWalletLowerCase);
      try {
        // Grant for Master Wallet to transfer
        if (!approve && isGranted) {
          const tx = await contract.approve(ROOT_WALLET, tokenId);
          await tx.wait();
        } else if (approve && !isGranted) {
          const tx = await contract.approve(selectedOrganization?.masterWalletAddress!, tokenId);
          await tx.wait();
        }
        return true;
      } catch (err: any) {
        handleError(err);
        return;
      }
    } catch (err: any) {
      enqueueSnackbar(
        err?.code === CODE.ACTION_REJECTED
          ? t('form_validation.user_denied_signature')
          : err?.name === CODE.CHAIN_NOT_CONFIGURED
          ? t('my_shop.message.chain_not_configured')
          : err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code !== CODE.NOT_SUPPORT_COLLECTION
          ? t('contract_is_not_support')
          : err?.error?.message || err?.shortMessage || err?.message || t('my_shop.message.error'),
        {
          variant: 'error',
        }
      );
      throw err;
    }
  };

  return [handleGrantTransferPermission];
};

export { useGrantMintPermission, useGrantTransferPermission };
