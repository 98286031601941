import { createContext, useCallback, useContext, useEffect, useRef } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
  Control,
  FieldErrors,
  SubmitHandler,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormSetValue,
  useForm,
  useWatch,
} from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as yup from 'yup';

import EditShopLayout from '~/components/app-layout/edit-shop-layout';
import ShopLandingPage from '~/components/shop-landing-page';
import { formEditShopSchema } from '~/config/yup';
import { colors } from '~/constants/colors';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';
import { FONT, OBJECT_FIT, TEMPLATE_OPTIONS } from '~/types/my-shop';

export interface EditShopContextValue {
  errors: FieldErrors<FormEditShopValues>;
  control: Control<FormEditShopValues, any>;
  setValue: UseFormSetValue<FormEditShopValues>;
  getValues: UseFormGetValues<FormEditShopValues>;
  handleSubmit: UseFormHandleSubmit<FormEditShopValues>;
}

const EditShopContext = createContext<EditShopContextValue>({} as any);
export const useEditShop = () => useContext(EditShopContext);

export interface ITheme {
  thema?: {
    template?: string | null;
    bannerImage?: string | null;
    bannerImageFile?: File[] | null;
    ratioBannerImage?: string | null;
    ratioBannerImageFile?: File[] | null;
  } | null;
  style?: {
    font?: string | null;
    spacing?: number | null;
    spacingSm?: number | null;
    textColor?: string | null;
    titleColor?: string | null;
    headerColor?: string | null;
    headerTextColor?: string | null;
    buttonColor?: string | null;
    backgroundColor?: string | null;
    descriptionColor?: string | null;
    collectionTitleColor?: string | null;
    nftCardTextColor?: string | null;
    nftCardBackgroundColor?: string | null;
    collectionNftCardImageMode?: string | null;
    collectionBorderColor?: string | null;
  } | null;
  navi?: {
    pageTitle?: string | null;
    pageTitleJa?: string | null;
    description?: string | null;
    descriptionJa?: string | null;
  } | null;
  showLabelSample?: boolean | null;
  positionLabelSample?: number[] | null;
}

export const initFormEditShop: (isMemberSite: boolean) => ITheme = (isMemberSite) => ({
  thema: {
    bannerImageFile: undefined,
    ratioBannerImageFile: undefined,
    template: TEMPLATE_OPTIONS.SIMPLE_SITE,
  },
  style: {
    spacing: 40,
    spacingSm: 20,
    font: FONT.ROBOTO,
    textColor: colors.black,
    titleColor: colors.black,
    buttonColor: colors.black,
    headerColor: colors.white,
    headerTextColor: colors.black,
    backgroundColor: colors.white,
    descriptionColor: colors.black,
    collectionTitleColor: colors.black,
    nftCardTextColor: colors.black,
    nftCardBackgroundColor: colors.white,
    collectionBorderColor: colors.lightGray,
    collectionNftCardImageMode: OBJECT_FIT.COVER,
  },
  navi: {
    pageTitle: isMemberSite ? 'Site name' : 'Shop name',
    pageTitleJa: isMemberSite ? 'Site name' : 'Shop name',
    description: 'description',
    descriptionJa: 'description',
  },
  showLabelSample: true,
  positionLabelSample: [50, 50],
});

export interface FormEditShopValues extends yup.InferType<typeof formEditShopSchema> {}

const EditShop = () => {
  const { data: myShop, isMemberSite, loading: loadingMyShop } = useShopDetail();

  const { id } = useParams();

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const navigate = useNavigate();
  const { isLocked } = useAccount();
  const { showError, showSuccess, showErrorByKey } = useNotify();

  const previewRef = useRef<HTMLDivElement>(null);

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    getValues,
    handleSubmit,
  } = useForm<FormEditShopValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues: initFormEditShop(isMemberSite) as FormEditShopValues,
    resolver: yupResolver(formEditShopSchema),
  });

  const font = useWatch({
    control,
    name: 'style.font',
  });

  const handleClear = useCallback(() => {
    reset(initFormEditShop(isMemberSite) as FormEditShopValues);
  }, [isMemberSite, reset]);

  useEffect(() => {
    if (!loadingMyShop && myShop) {
      const { siteSetting } = myShop;
      const theme = siteSetting?.theme! as ITheme;
      if (!theme) {
        return;
      }
      const { style, navi, thema } = theme;
      reset({
        ...theme,
        style: {
          ...theme.style,
          spacing: style?.spacing || 40,
          font: style?.font || FONT.ROBOTO,
          spacingSm: style?.spacingSm || 20,
          textColor: style?.textColor || colors.black,
          titleColor: style?.titleColor || colors.black,
          buttonColor: style?.buttonColor || colors.black,
          headerColor: style?.headerColor || colors.white,
          headerTextColor: style?.headerTextColor || colors.black,
          backgroundColor: style?.backgroundColor || colors.white,
          descriptionColor: style?.descriptionColor || colors.black,
          collectionTitleColor: style?.collectionTitleColor || colors.black,
          nftCardTextColor: style?.nftCardTextColor || colors.black,
          nftCardBackgroundColor: style?.nftCardBackgroundColor || colors.white,
          collectionBorderColor: style?.collectionBorderColor || colors.lightGray,
          collectionNftCardImageMode: style?.collectionNftCardImageMode || OBJECT_FIT.COVER,
        },
        thema: {
          ...thema!,
          template: thema?.template || TEMPLATE_OPTIONS.SIMPLE_SITE,
          bannerImage: siteSetting?.banner!,
          ratioBannerImage: siteSetting?.ratioBanner!,
        },
        navi: {
          ...navi!,
          pageTitle: navi?.pageTitle || '',
          description: navi?.description || '',
          pageTitleJa: navi?.pageTitleJa || '',
          descriptionJa: navi?.descriptionJa || '',
        },
        showLabelSample: !!theme.showLabelSample,
        positionLabelSample: theme.positionLabelSample || [50, 50],
      } as FormEditShopValues);
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [myShop, loadingMyShop, reset]);

  useEffect(() => {
    return () => {
      handleClear();
    };
  }, [handleClear]);

  const shopLandingPageTheme = createTheme({
    colors: {
      white: '#FFFFFF',
    },
    typography: {
      fontFamily: [font, 'Roboto', 'Arial', 'Helvetica', 'sans-serif'].join(','),
    },
  });

  const handleSave: SubmitHandler<FormEditShopValues> = async (data) => {
    try {
      if (isLocked) {
        showErrorByKey('toast_message.organization_locked');
        return;
      }

      const newPayload = JSON.parse(JSON.stringify(data));
      delete newPayload.thema.bannerImage;
      delete newPayload.thema.bannerImageFile;
      delete newPayload.thema.ratioBannerImage;
      delete newPayload.thema.ratioBannerImageFile;
      await updateMyShop({
        variables: {
          input: {
            uuid: id!,
            siteSetting: {
              theme: newPayload,
            },
            bannerFile: data.thema?.bannerImageFile?.[0],
            ratioBannerFile: data.thema?.ratioBannerImageFile?.[0],
          },
        },
      });
      navigate((isMemberSite ? AppRouteEnum.MemberSiteDetail : AppRouteEnum.ShopDetail).replace(/:id/, id || ''));
      showSuccess('my_shop.message.update_successful');
    } catch (err) {
      showError(err);
    }
  };

  return (
    <EditShopContext.Provider value={{ control, errors, setValue, getValues, handleSubmit }}>
      <EditShopLayout ref={previewRef} isSubmitting={isSubmitting} onSave={handleSubmit(handleSave)}>
        <ThemeProvider theme={shopLandingPageTheme}>
          <Box className="edit-shop-main">
            <ShopLandingPage ref={previewRef} />
          </Box>
        </ThemeProvider>
      </EditShopLayout>
    </EditShopContext.Provider>
  );
};

export default EditShop;
