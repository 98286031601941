import { ChangeEvent, FC, FocusEventHandler, useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import MenuItem from '@mui/material/MenuItem';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';
import { jaJP as jaJPDatePicker, DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/ja';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { Controller, ControllerRenderProps, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { DATE_TIME_FORMAT } from '~/constants/common';
import { prefectures } from '~/constants/prefectures';
import countries from '~/data/countries.json';
import languages from '~/data/languages.json';
import { GetMeDocument, useUpdateOrganizationMutation } from '~/graphql/member/types';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { AddressType, SEX, PageCategoryType } from '~/types/my-shop';
import { getErrorText } from '~/utils/yup.util';

const postal_code = require('japan-postal-code');

export interface FormOperationValues extends yup.InferType<typeof schema> {}

const phoneRegex = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    gap: '16px',
    display: 'flex',
    paddingBottom: '16px',
    flexDirection: 'column',
    '.MuiTypography-h4': {
      margin: 0,
      fontWeight: 500,
      fontSize: '14px',
      lineHeight: '32px',
    },
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    '.MuiFormControl-marginDense': {
      marginBottom: '0px',
    },
    '.MuiFormControlLabel-root': {
      marginTop: '0',
      marginLeft: '-4px',
      '&:not(:last-of-type)': {
        marginRight: '64px',
      },
      '.MuiRadio-root': {
        padding: '2px',
        marginRight: '8px',
      },
    },
  },
  errorDate: {
    fontSize: 12,
    color: '#DF0303',
    margin: '3px 14px 0',
  },
}));

const schema = yup.object({
  name: yup.string().max(100).required(),
  category: yup.mixed<PageCategoryType>().oneOf(Object.values(PageCategoryType)),
  operatorName: yup.string(),
  lastName: yup.string().max(100).label('my_shop.operation.last_name'),
  shopName: yup.string().max(255, 'form_validation.company_name_max_length').label('settings.company_name'),
  shopNameKana: yup
    .string()
    .max(255, 'form_validation.company_name_kana_max_length')
    .label('settings.company_name_kana'),
  firstName: yup.string().max(100).label('my_shop.operation.first_name'),
  lastNameKana: yup.string().max(100).label('my_shop.operation.last_name_kana'),
  firstNameKana: yup.string().max(100).label('my_shop.operation.first_name_kana'),
  lastNameEnglish: yup.string().max(100).label('my_shop.operation.last_name_en'),
  firstNameEnglish: yup.string().max(100).label('my_shop.operation.first_name_en'),
  postalCode: yup.string().max(10).label('my_shop.operation.postal_code'),
  prefecture: yup.string().max(100).label('my_shop.operation.prefecture'),
  address: yup.string().max(255).label('address'),
  phoneNumber: yup
    .string()
    .max(20)
    .test('test-phone', 'my_shop.message.phone_number_not_valid', (value) => !value || phoneRegex.test(value)),
  dateOfBirth: yup.mixed<Dayjs>().nullable(true),
  sex: yup.mixed<SEX>(),
  country: yup.string().required(),
  language: yup.string().required(),
});

const categoriesTitle = (t: TFunction) => ({
  [PageCategoryType.Corporate]: {
    value: PageCategoryType.Corporate,
    label: t('my_shop.operation.corporate'),
  },
  [PageCategoryType.Individual]: {
    value: PageCategoryType.Individual,
    label: t('my_shop.operation.individual'),
  },
});

const regionOptions = countries.map((option) => (
  <MenuItem key={option.code} value={option.code}>
    {option.name}
  </MenuItem>
));

const languageOptions = languages.map((option) => (
  <MenuItem key={option.lang} value={option.lang}>
    {option.label}
  </MenuItem>
));

const Operation: FC = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isInvalidDate, setIsInvalidDate] = useState<string>('');

  const { selectedOrganization } = useAccount();

  const [updateOrganizationMutation] = useUpdateOrganizationMutation({ refetchQueries: [GetMeDocument] });

  const [editable] = useCheckPermissions([SCREEN_PERMISSION.SETTING.GENERAL.EDIT]);

  const representativeNamesList: { label: string; field: keyof FormOperationValues }[] = useMemo(
    () => [
      { label: t('my_shop.operation.last_name'), field: 'lastName' },
      { label: t('my_shop.operation.first_name'), field: 'firstName' },
      { label: t('my_shop.operation.last_name_kana'), field: 'lastNameKana' },
      { label: t('my_shop.operation.first_name_kana'), field: 'firstNameKana' },
      { label: t('my_shop.operation.last_name_en'), field: 'lastNameEnglish' },
      { label: t('my_shop.operation.first_name_en'), field: 'firstNameEnglish' },
    ],
    [t]
  );

  const defaultValues = useMemo(
    () => ({
      name: selectedOrganization?.name,
      category: (selectedOrganization?.operation?.type as PageCategoryType) || PageCategoryType.Individual,
      operatorName: selectedOrganization?.operation?.name || '',
      shopName: selectedOrganization?.operation?.shopName?.name || '',
      shopNameKana: selectedOrganization?.operation?.shopName?.nameKana || '',
      lastName: selectedOrganization?.operation?.detailName?.lastName || '',
      firstName: selectedOrganization?.operation?.detailName?.firstName || '',
      lastNameKana: selectedOrganization?.operation?.detailName?.lastNameKana || '',
      firstNameKana: selectedOrganization?.operation?.detailName?.firstNameKana || '',
      lastNameEnglish: selectedOrganization?.operation?.detailName?.lastNameEn || '',
      firstNameEnglish: selectedOrganization?.operation?.detailName?.firstNameEn || '',
      postalCode: selectedOrganization?.operation?.address?.postalCode || '',
      prefecture: selectedOrganization?.operation?.address?.prefecture || '',
      address: selectedOrganization?.operation?.address?.address || '',
      phoneNumber: selectedOrganization?.operation?.contact?.phoneNumber || '',
      publicPhoneNumber: selectedOrganization?.operation?.contact
        ? !!selectedOrganization?.operation?.contact?.publish
        : true,
      dateOfBirth: selectedOrganization?.operation?.dayOfBirth
        ? dayjs(selectedOrganization?.operation?.dayOfBirth)
        : null,
      country: selectedOrganization?.region || '',
      language: selectedOrganization?.language || '',
      sex: (selectedOrganization?.operation?.sex as SEX) || '',
    }),
    [selectedOrganization]
  );

  const {
    control,
    setValue,
    getValues,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<FormOperationValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const isDirty = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    const value = getValues();
    if (JSON.stringify(value) !== JSON.stringify(defaultValues)) reset(defaultValues);
  }, [defaultValues, getValues, reset]);

  const isIndividual =
    useWatch({
      control,
      name: 'category',
    }) === PageCategoryType.Individual;

  const prefectureOptions = useMemo(
    () =>
      (Object.keys(prefectures.en) as (keyof typeof prefectures.en)[]).map((option) => (
        <MenuItem key={option} value={option}>
          {prefectures[i18n.language as 'en' | 'ja'][option]}
        </MenuItem>
      )),
    [i18n.language]
  );

  const sexOptions = useMemo(
    () =>
      [
        {
          label: t('my_shop.operation.male'),
          value: SEX.MALE,
        },
        {
          label: t('my_shop.operation.female'),
          value: SEX.FEMALE,
        },
      ].map((option) => (
        <MenuItem key={option.value} value={option.value}>
          {option.label}
        </MenuItem>
      )),
    [t]
  );

  const onSubmit = async (data: FormOperationValues) => {
    try {
      if (isInvalidDate) {
        return;
      }
      await updateOrganizationMutation({
        variables: {
          input: {
            name: data.name,
            region: data.country,
            language: data.language,
            operation: {
              type: data.category,
              name: data.operatorName,
              shopName: {
                name: data.shopName,
                nameKana: data.shopNameKana,
              },
              detailName: {
                lastName: data.lastName,
                firstName: data.firstName,
                lastNameKana: data.lastNameKana,
                firstNameKana: data.firstNameKana,
                lastNameEn: data.lastNameEnglish,
                firstNameEn: data.firstNameEnglish,
              },
              address: {
                postalCode: data.postalCode,
                prefecture: data.prefecture,
                address: data.address,
              },
              contact: {
                phoneNumber: data.phoneNumber,
              },
              sex: data.sex,
              dayOfBirth: data.dateOfBirth,
            },
          },
        },
      });
      reset(data);
      enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
    } catch (err: any) {
      enqueueSnackbar(err.message, { variant: 'error' });
    }
  };

  const handlePostalCodeBlur = (field: ControllerRenderProps<FormOperationValues, 'postalCode'>) =>
    ((e) => {
      field.onBlur();
      if (!errors.postalCode?.message) {
        const zipCode = e.target.value;
        postal_code.get(zipCode, function (address: AddressType) {
          if (address) {
            const { city, cityEn, area, areaEn, street, streetEn } = address;
            const addressJa = city + area + street;
            const addressEn = [];
            if (cityEn) addressEn.push(cityEn);
            if (areaEn) addressEn.push(areaEn);
            if (streetEn) addressEn.push(streetEn);
            setValue('address', i18n.language === 'ja' ? addressJa : addressEn.join(', '));
            setValue('prefecture', address.prefectureEn);
          }
        });
      }
    }) as FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>;

  const handleChangeCategory =
    (field: ControllerRenderProps<FormOperationValues, 'category'>) =>
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      field.onChange(event);
      reset({
        ...defaultValues,
        category: value as PageCategoryType,
      });
    };

  const handleChangePhone = (callback: (newValue: string) => void) => (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value.replace(/\s/g, '');
    callback(newValue);
  };

  return (
    <CustomCardTable
      cardTitle={t('settings.general')}
      cardContent={
        <Box className={classes.wrapper}>
          <Box className={classes.section}>
            <Typography variant="h4">{t('organization_name')}</Typography>
            <Grid container spacing="16px">
              <Grid item xs={12}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      required
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      error={!!errors.name?.message}
                      label={t('organization_name')}
                      inputProps={{ maxLength: 255 }}
                      helperText={getErrorText(errors.name?.message, t)}
                      {...field}
                      disabled={!editable || isSubmitting}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Typography variant="h4">{t('my_shop.category')}</Typography>
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <FormControl>
                  <RadioGroup row {...field} onChange={handleChangeCategory(field)}>
                    {Object.values(categoriesTitle(t)).map((category) => (
                      <FormControlLabel
                        control={<Radio />}
                        key={category.value}
                        value={category.value}
                        label={category.label}
                        disabled={!editable || isSubmitting}
                      />
                    ))}
                  </RadioGroup>
                </FormControl>
              )}
            />
          </Box>
          {!isIndividual && (
            <Box className={classes.section}>
              <Typography variant="h4">{t('settings.company_name')}</Typography>
              <Grid container spacing="16px">
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="shopName"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label={t('settings.company_name')}
                        variant="outlined"
                        error={!!errors.shopName?.message}
                        helperText={getErrorText(errors.shopName?.message, t)}
                        {...field}
                        disabled={!editable || isSubmitting}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="shopNameKana"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label={t('settings.company_name_kana')}
                        variant="outlined"
                        error={!!errors.shopNameKana?.message}
                        helperText={getErrorText(errors.shopNameKana?.message, t)}
                        {...field}
                        disabled={!editable || isSubmitting}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Box className={classes.section}>
            <Typography variant="h4">{t('my_shop.operation.name')}</Typography>
            <Grid container spacing="16px">
              {representativeNamesList.map((name, key) => (
                <Grid item xs={6} key={key}>
                  <Controller
                    key={name.field}
                    name={name.field}
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        margin="dense"
                        label={name.label}
                        variant="outlined"
                        error={!!errors[name.field]?.message}
                        helperText={getErrorText(errors[name.field]?.message, t)}
                        {...field}
                        disabled={!editable || isSubmitting}
                      />
                    )}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
          {isIndividual && (
            <Box className={classes.section}>
              <Typography variant="h4">{t('my_shop.operation.birth_and_sex')}</Typography>
              <Grid container spacing="16px">
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="dateOfBirth"
                    control={control}
                    render={({ field }) => (
                      <LocalizationProvider
                        dateAdapter={AdapterDayjs}
                        adapterLocale={i18n.language}
                        localeText={jaJPDatePicker.components.MuiLocalizationProvider.defaultProps.localeText}
                      >
                        <DatePicker
                          sx={{ width: '100%' }}
                          format={DATE_TIME_FORMAT}
                          label={t('my_shop.operation.date_of_birth')}
                          {...field}
                          disableFuture
                          slotProps={{
                            field: {
                              clearable: true,
                            },
                            textField: {
                              margin: 'dense',
                              error: !!isInvalidDate,
                              helperText: t(isInvalidDate),
                            },
                          }}
                          maxDate={dayjs()}
                          disabled={!editable || isSubmitting}
                          onError={(error) => {
                            setIsInvalidDate(
                              error === 'invalidDate'
                                ? 'form_validation.isValidDate'
                                : error === 'disableFuture'
                                ? 'my_shop.operation.invalid_date'
                                : ''
                            );
                          }}
                        />
                      </LocalizationProvider>
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Controller
                    name="sex"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        select
                        fullWidth
                        margin="dense"
                        label={t('my_shop.operation.sex')}
                        variant="outlined"
                        error={!!errors.sex?.message}
                        helperText={t(errors.sex?.message as any)}
                        {...field}
                        disabled={!editable || isSubmitting}
                      >
                        {sexOptions}
                      </TextField>
                    )}
                  />
                </Grid>
              </Grid>
            </Box>
          )}
          <Box className={classes.section}>
            <Typography variant="h4">{t('address')}</Typography>
            <Grid container spacing="16px">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="postalCode"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      margin="dense"
                      label={t('my_shop.operation.postal_code')}
                      variant="outlined"
                      error={!!errors.postalCode?.message}
                      helperText={getErrorText(errors.postalCode?.message, t)}
                      {...field}
                      onBlur={handlePostalCodeBlur(field)}
                      disabled={!editable || isSubmitting}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="prefecture"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      label={t('my_shop.operation.prefecture')}
                      variant="outlined"
                      error={!!errors.prefecture?.message}
                      helperText={t(errors.prefecture?.message as any)}
                      {...field}
                      disabled={!editable || isSubmitting}
                    >
                      {prefectureOptions}
                    </TextField>
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="address"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      margin="dense"
                      label={t('my_shop.operation.city_ward_town')}
                      variant="outlined"
                      error={!!errors.address?.message}
                      helperText={getErrorText(errors.address?.message, t)}
                      {...field}
                      disabled={!editable || isSubmitting}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Typography variant="h4">{t('my_shop.operation.language')}</Typography>
            <Grid container spacing="16px">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="language"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      error={!!errors.language?.message}
                      label={t('my_shop.operation.language')}
                      helperText={t(errors.language?.message as any)}
                      {...field}
                      disabled={!editable || isSubmitting}
                    >
                      {languageOptions}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Typography variant="h4">{t('my_shop.operation.country')}</Typography>
            <Grid container spacing="16px">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      fullWidth
                      margin="dense"
                      variant="outlined"
                      error={!!errors.country?.message}
                      label={t('my_shop.operation.country')}
                      helperText={t(errors.country?.message as any)}
                      {...field}
                      disabled={!editable || isSubmitting}
                    >
                      {regionOptions}
                    </TextField>
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box className={classes.section}>
            <Typography variant="h4">{t('my_shop.operation.contact_information')}</Typography>
            <Grid container spacing="16px">
              <Grid item xs={12} sm={6}>
                <Controller
                  name="phoneNumber"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      fullWidth
                      margin="dense"
                      label={t('my_shop.operation.phone_number')}
                      variant="outlined"
                      InputProps={{
                        type: 'tel',
                      }}
                      error={!!errors.phoneNumber?.message}
                      helperText={getErrorText(errors.phoneNumber?.message, t)}
                      {...field}
                      onChange={handleChangePhone(field.onChange)}
                      disabled={!editable || isSubmitting}
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', width: '100%' }}>
            <Button
              variant="contained"
              disabled={!editable || isSubmitting || !isDirty}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('update')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default Operation;
