import React, { useCallback, useEffect } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount } from '@gusdk/gu-wallet-connector';
import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import { isAddress, JsonRpcProvider } from 'ethers';
import { useSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../dialog/custom-dialog';

import CreateCollectionForm from './CreateCollectionForm';

import { CODE } from '~/constants/code';
import { MAX_FILE_SIZE } from '~/constants/common';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import {
  Collection,
  ListCollectionsDocument,
  useCreateCollectionMutation,
  useCreateCollectionWithOrgWalletMutation,
  useUploadFileMutation,
} from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { COLLECTION_TYPE } from '~/types/my-shop';
import { checkMinter } from '~/utils/erc721g.util';

interface Props extends DialogProps {
  onClose: (params?: { newCollection?: Collection }) => void;
}

const schema = yup.object({
  option: yup.mixed<'create' | 'import'>().oneOf(['create', 'import']).required(),
  name: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  ownerAddress: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) =>
        schema.required().test('isAddress', 'form_validation.isEthAddress', (value) => isAddress(value)),
      otherwise: (schema) => schema.optional(),
    }),
  symbol: yup
    .string()
    .max(100)
    .when('option', {
      is: 'create',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  desc: yup.string(),
  descJa: yup.string(),
  network: yup.string().required(),
  contractAddress: yup
    .string()
    .ethAddress()
    .when('option', {
      is: 'import',
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.optional(),
    }),
  storage: yup.string(),
  images: yup.array().of(yup.string()),
  imageFiles: yup
    .mixed<FileList>()
    .test('required', 'form_validation.isNotEmpty', (value) => (value ? Array.from(value).length !== 0 : false))
    .test('fileSize', 'form_validation.max_file_size', (value) => {
      if (value) {
        const files = Array.from(value);
        return files.every((file) => file.size < MAX_FILE_SIZE);
      }
      return true;
    }),
  imageMock: yup.string().notRequired().nullable(),
});

export interface FormValues extends yup.InferType<typeof schema> {}

const CreateEditCollectionDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation();
  const { account } = useAccount();
  const { showError } = useNotify();
  const [uploadFile] = useUploadFileMutation();
  const { supportedNetworks } = useSupportedNetworks();
  const [createCollectionWithOrgWallet] = useCreateCollectionWithOrgWalletMutation();
  const [createCollection] = useCreateCollectionMutation({
    refetchQueries: [ListCollectionsDocument],
  });

  const {
    control,
    formState: { errors, isSubmitting },
    reset,
    setValue,
    handleSubmit,
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      desc: '',
      descJa: '',
      symbol: '',
      images: [],
      storage: 'ipfs',
      option: 'create',
      contractAddress: '',
      imageMock: undefined,
      imageFiles: undefined,
      ownerAddress: account,
      network: supportedNetworks ? Object.values(supportedNetworks)?.[0]?.chainId : '',
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const errorMessage = (err: any) => {
    if (err?.error?.code === CODE.INTERNAL_ERROR && err?.error?.data?.code === CODE.NOT_SUPPORT_COLLECTION) {
      return t('contract_is_not_support');
    } else if (err.code === CODE.ACTION_REJECTED) {
      return err.reason;
    } else if (err.code === CODE.CALL_EXCEPTION || err.code === CODE.BAD_DATA) {
      return t('toast_message.network_not_match');
    } else if (err.code === CODE.NETWORK_ERROR) {
      return t('my_shop.message.error');
    } else if (err?.graphQLErrors?.[0]?.extensions?.exception?.code === CODE.INSUFFICIENT_FUNDS) {
      return t('toast_message.insufficient_funds_for_gas');
    }
    return err?.error?.message || err?.message || t('my_shop-message.error');
  };

  const handleClose = (params?: { newCollection?: Collection }) => {
    onClose(params);
    reset();
  };

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        let contractAddress = data.contractAddress || '';
        let params = {};

        const uploadedImagesList = await uploadFile({
          variables: {
            files: data.imageFiles,
          },
        });
        if (uploadedImagesList) {
          params = {
            ...params,
            images: uploadedImagesList.data?.uploadFile ?? [],
          };
        }

        if (data.option === 'create') {
          const newCollectionRes = await createCollectionWithOrgWallet({
            variables: {
              input: {
                desc: data.desc,
                name: data.name!,
                descJa: data.descJa,
                symbol: data.symbol!,
                network: data.network,
                type: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
                ownerAddress: data.ownerAddress || '',
                ...params,
              },
            },
          });
          handleClose({ newCollection: newCollectionRes.data?.createCollectionWithOrgWallet });
        } else {
          const provider = new JsonRpcProvider(supportedNetworks?.[data.network]?.rpcUrl);
          const factory = ERC721G__factory.connect(contractAddress, provider);
          const ownerAddress = await factory.owner();
          await checkMinter(factory, ownerAddress);
          const newCollectionRes = await createCollection({
            variables: {
              input: {
                desc: data.desc,
                contractAddress,
                descJa: data.descJa,
                network: data.network!,
                type: COLLECTION_TYPE.DIGITAL_COLLECTIVE,
                ownerAddress,
                ...params,
              },
            },
          });
          handleClose({ newCollection: newCollectionRes.data?.createCollection });
        }
      } catch (err: any) {
        showError(err, errorMessage(err));
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [supportedNetworks, t, uploadFile, createCollection, enqueueSnackbar]
  );

  useEffect(() => {
    setValue('ownerAddress', account || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={handleClose}
      dialogTitle={t('create_collection_dialog.title')}
      dialogContent={<CreateCollectionForm control={control} isSubmitting={isSubmitting} errors={errors} />}
      actions={[
        <Button color="primary" variant="outlined" disabled={isSubmitting} onClick={() => handleClose()}>
          {t('cancel')}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('create')}
        </Button>,
      ]}
    />
  );
};

export default CreateEditCollectionDialog;
