import { FC, MouseEventHandler, useCallback, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { FormValuesImageNFT } from './LazyMintCollectionDetail';

import NoResultsOverlay from '~/components/custom-list-toolbar-filter';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import MintOnPurchaseNFTDialog from '~/components/dialog/mint-on-puchase-nft-dialog';
import { GalleryData } from '~/components/gallery-list';
import ListTable, { ListTablePagination } from '~/components/list-table';
import PermissionsView from '~/components/PermissionsView';
import SquareImage from '~/components/SquareImage';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { API_MEDIA, ITEMS_PER_PAGE } from '~/constants/common';
import {
  CollectionImagesQueryKey,
  QueryOperator,
  useCreateCollectionImageMutation,
  useRemoveCollectionImageMutation,
  useUpdateCollectionImageMutation,
  useListCollectionImagesQuery,
  ListCollectionImagesDocument,
} from '~/graphql/member/types';
import { useCheckPermissions } from '~/hooks/with-account';
import { ImageNFTListProps, TableImageListCollection } from '~/interfaces/collection';
import ImageNFTDialog from '~/pages/my-shop/shop-detail/components/ImageNFTDialog';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

const { MINT_ON_PURCHASE } = SCREEN_PERMISSION.COLLECTIONS;

const useStyles = makeStyles()(() => ({
  imageListBox: {
    width: '100%',
    '.image-grid': {
      marginBottom: '24px',
    },
    '.MuiTypography-subtitle1': {
      fontSize: '20px',
      fontWeight: 400,
      lineHeight: '24px',
      letterSpacing: '0.5px',
      marginBottom: '16px',
    },
    '.MuiTypography-caption': {
      fontWeight: 400,
      fontSize: '16px',
      lineHeight: '22px',
      marginBottom: '16px',
      display: 'inline-block',
      letterSpacing: '0.17px',
    },
    '.is-not-owner': {
      opacity: '0.5',
    },
    '.MuiSelect-standard': {
      backgroundColor: 'transparent!important',
    },
  },
  noPriceSet: {
    cursor: 'pointer',
    textDecoration: 'underline',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const ImageNFTList: FC<ImageNFTListProps> = (props) => {
  const {
    reset,
    errors,
    isDirty,
    control,
    isEditImage,
    handleSubmit,
    collectionInfo,
    openImageDialog,
    setIsEditImage,
    onOpenImageDialog,
    setOpenImageListDialog,
  } = props;

  const [selectedItem, setSelectedItem] = useState<TableImageListCollection>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [isOpenNFTDialog, setIsOpenNFTDialog] = useState<boolean>(false);
  const [customAnchorElCol, setCustomAnchorElCol] = useState<{
    info: GalleryData;
    anchorEl: null | HTMLElement;
  } | null>(null);

  const { classes } = useStyles();

  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [collectionImagesQuery, setCollectionImagesQuery] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(CollectionImagesQueryKey, getLocalStorage('collection_images_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage('collection_images_list_order')),
    where: {
      fields: [
        {
          operator: QueryOperator.Equals,
          value: [collectionInfo?.uuid ?? ''],
          key: CollectionImagesQueryKey.CollectionUuid,
        },
      ],
    },
  });

  const updateCollectionImagesQuery = (newValue: any) =>
    setCollectionImagesQuery((value: any) => ({ ...value, ...newValue }));

  useEffect(() => {
    setLocalStorageItems({
      collection_images_list_sort: collectionImagesQuery?.sortBy,
      collection_images_list_order: collectionImagesQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionImagesQuery?.orderBy, collectionImagesQuery?.sortBy]);

  const { data: dataListCollectionImages, loading } = useListCollectionImagesQuery({
    fetchPolicy: 'cache-and-network',
    variables: collectionImagesQuery,
    skip: !collectionInfo?.uuid,
  });

  const { items, pagination } = useMemo(() => {
    const items = dataListCollectionImages?.listCollectionImages?.items || [];

    const pagination: ListTablePagination = dataListCollectionImages?.listCollectionImages?.pagination || {};

    const _items = items.map(
      (item) =>
        ({
          id: item.uuid,
          maxMint: item.maxMint,
          name: item.name,
          currentMint: item.currentMint,
          originalName: item.name,
          createAt: item.createdAt,
          url: API_MEDIA + (item.image || ''),
          // networkSymbol: SUPPORTED_NETWORKS[collectionInfo?.network as SupportedNetwork].networkSymbol,

          [CollectionImagesQueryKey.Name]: item.name,
          [CollectionImagesQueryKey.CurrentMint]: item.currentMint,
          [CollectionImagesQueryKey.MaxMint]: item.maxMint,
          [CollectionImagesQueryKey.CreatedAt]: item.createdAt,
        } as TableImageListCollection)
    );

    return { items: _items, pagination };
  }, [dataListCollectionImages]);

  const [createCollectionImage] = useCreateCollectionImageMutation({
    refetchQueries: [ListCollectionImagesDocument],
    onCompleted: () => updateCollectionImagesQuery({ page: 1 }),
  });
  const [updateCollectionImage] = useUpdateCollectionImageMutation({
    refetchQueries: [ListCollectionImagesDocument],
  });
  const [removeCollectionImage] = useRemoveCollectionImageMutation({
    refetchQueries: [ListCollectionImagesDocument],
    onCompleted: () => updateCollectionImagesQuery({ page: 1 }),
  });

  const handleClickMoreMenuCollection = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>, row: TableImageListCollection) => {
      setAnchorEl(event.currentTarget);
      setSelectedItem(row);
    },
    []
  );

  const onOpenImageDetail = useCallback(
    (id: string) => {
      setSelectedItem(items.find((row) => row.id === id));
      setAnchorEl(null);
      setIsOpenNFTDialog(true);
    },
    [items]
  );

  const onCloseImageDetailDialog = () => {
    setIsOpenNFTDialog(false);
  };

  const imageListCollectionColumns: GridColDef<TableImageListCollection>[] = useMemo(
    () => [
      {
        width: 80,
        field: 'url',
        sortable: false,
        resizable: false,
        headerName: t('image'),
        renderCell: ({ value, row }) => {
          return (
            <Box width="64px">
              <SquareImage isNFT src={value} onClick={() => onOpenImageDetail(row.id)} />
            </Box>
          );
        },
      },
      {
        width: 200,
        field: CollectionImagesQueryKey.Name,
        headerName: t('my_shop.image_name'),
      },
      {
        width: 200,
        field: CollectionImagesQueryKey.MaxMint,
        headerName: t('collection_screen.max_mint'),
        valueFormatter: ({ value }) => Number(value).toLocaleString(),
      },
      {
        width: 200,
        field: CollectionImagesQueryKey.CurrentMint,
        headerName: t('collection_screen.current_mint_count'),
        valueFormatter: ({ value }) => Number(value).toLocaleString(),
      },
      {
        width: 150,
        type: 'date',
        headerName: t('created_at'),
        field: CollectionImagesQueryKey.CreatedAt,
        valueFormatter: ({ value }) => (value ? moment(value).format(t('date_format')) : '-'),
      },
      {
        width: 70,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) => [
          <IconButton onClick={(event) => handleClickMoreMenuCollection(event, row)}>
            <MoreVertIcon />
          </IconButton>,
        ],
      },
    ],
    [t, handleClickMoreMenuCollection, onOpenImageDetail]
  );

  const onOpenEditImageDialog = useCallback(
    (galleryItem?: GalleryData) => {
      if (galleryItem) {
        setSelectedItem(items.find((row) => row.id === galleryItem.id));
      }

      if (!!setIsEditImage && !!setOpenImageListDialog) {
        setOpenImageListDialog(true);
        setIsEditImage(true);
      }
      const currentImageData = items.find((item) => item.id === (galleryItem?.id || selectedItem?.id));
      reset({
        imageMock: currentImageData?.url || '',
        imageName: currentImageData?.name || '',
        maxMint: currentImageData?.maxMint?.toString() || '',
      });
      setAnchorEl(null);
      setCustomAnchorElCol(null);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [items, selectedItem, reset]
  );

  const onCloseImageDialog = useCallback(() => {
    reset({
      maxMint: '',
      imageName: '',
      imageMock: '',
    });
    if (!!setOpenImageListDialog) {
      setOpenImageListDialog(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reset]);

  const handleCloseMenu = () => {
    setAnchorEl(null);
    setCustomAnchorElCol(null);
  };

  const onAddImage = useCallback(
    async (data: FormValuesImageNFT) => {
      try {
        await createCollectionImage({
          variables: {
            input: {
              collectionUuid: collectionInfo?.uuid || '',
              name: data.imageName,
              maxMint: Number(data.maxMint),
              imageFile: data?.imageFiles![0],
            },
          },
        });
        onCloseImageDialog();
        reset();
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    },
    [collectionInfo?.uuid, reset, enqueueSnackbar, createCollectionImage, onCloseImageDialog]
  );

  const onEditImage = useCallback(
    async (data: FormValuesImageNFT) => {
      try {
        await updateCollectionImage({
          variables: {
            input: {
              uuid: selectedItem?.id || '',
              collectionUuid: collectionInfo?.uuid || '',
              name: data.imageName,
              maxMint: Number(data.maxMint),
              imageFile: data?.imageFiles![0],
            },
          },
        });
        reset();
        onCloseImageDialog();
      } catch (e: any) {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    },
    [selectedItem, collectionInfo?.uuid, reset, enqueueSnackbar, onCloseImageDialog, updateCollectionImage]
  );

  const handleDeleteImage = async () => {
    try {
      setAnchorEl(null);
      await removeCollectionImage({ variables: { uuid: selectedItem?.id || '' } });
      onCloseDeleteDialog();
      setSelectedItem(undefined);
    } catch (e: any) {
      enqueueSnackbar(e.message, { variant: 'error' });
    }
  };

  const onOpenDeleteDialog = () => {
    setAnchorEl(null);
    setCustomAnchorElCol(null);
    setOpenDeleteDialog(true);
  };

  const onCloseDeleteDialog = async () => {
    setOpenDeleteDialog(false);
  };

  const handleClickMenuCollection =
    (info: GalleryData): MouseEventHandler<HTMLButtonElement> =>
    (event) => {
      const newAnchorEl = { info, anchorEl: event.currentTarget };
      setCustomAnchorElCol(newAnchorEl);
      const newSelectedItem = items.find((row) => row.id === newAnchorEl.info.id);
      setSelectedItem(newSelectedItem);
    };

  const [isCanViewBtnAddAndEdit] = useCheckPermissions([MINT_ON_PURCHASE.ADD]);

  return (
    <Box className={classes.imageListBox}>
      <ListTable
        isMenu
        noBorder
        rows={items}
        rowHeight={84}
        editMode="row"
        isLoading={loading}
        disableRowSelectionOnClick
        tableName="collection_premint"
        searchLabel={t('my_shop.image_name')}
        noRowsMessage={t('my_shop.message.no_image')}
        columns={imageListCollectionColumns}
        slots={{ noResultsOverlay: NoResultsOverlay }}
        noDataProps={
          !!onOpenImageDialog
            ? {
                title: t('create_new_image'),
                description: t('my_shop.message.no_image'),
                buttonTitle: t('collection_screen.add_image'),
                onClick: isCanViewBtnAddAndEdit ? onOpenImageDialog : undefined,
              }
            : undefined
        }
        onClickMenu={handleClickMenuCollection}
        onClickImage={!isCanViewBtnAddAndEdit ? (i) => onOpenImageDetail(i?.id || '') : onOpenEditImageDialog}
        // new
        search={collectionImagesQuery.searchText}
        onSearch={(v) => updateCollectionImagesQuery({ page: 1, searchText: v || '' })}
        paginationData={pagination}
        onPagination={updateCollectionImagesQuery}
        sort={{
          sortBy: collectionImagesQuery.sortBy,
          orderBy: collectionImagesQuery.orderBy,
        }}
        onSort={(value = {}) => updateCollectionImagesQuery(value)}
      />

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleCloseMenu}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <PermissionsView roles={MINT_ON_PURCHASE.EDIT}>
          <MenuItem onClick={() => onOpenEditImageDialog()}>{t('edit')}</MenuItem>
        </PermissionsView>
        <PermissionsView roles={MINT_ON_PURCHASE.DELETE}>
          <MenuItem onClick={onOpenDeleteDialog}>{t('delete')}</MenuItem>
        </PermissionsView>
        <MenuItem onClick={() => onOpenImageDetail(selectedItem?.id!)}>{t('show_details')}</MenuItem>
      </Menu>
      <Menu open={!!customAnchorElCol} anchorEl={customAnchorElCol?.anchorEl} onClose={handleCloseMenu}>
        <PermissionsView roles={MINT_ON_PURCHASE.EDIT}>
          <MenuItem onClick={() => onOpenEditImageDialog()}>{t('edit')}</MenuItem>
        </PermissionsView>
        <PermissionsView roles={MINT_ON_PURCHASE.DELETE}>
          <MenuItem onClick={onOpenDeleteDialog}>{t('delete')}</MenuItem>
        </PermissionsView>
      </Menu>

      <ImageNFTDialog
        errors={errors}
        control={control}
        isDirty={!!isDirty}
        isEdit={isEditImage}
        isDialogOpen={!!openImageDialog}
        onAddImage={onAddImage}
        onEditImage={onEditImage}
        handleSubmit={handleSubmit}
        onCloseDialog={onCloseImageDialog}
      />

      <ConfirmationDialog
        open={openDeleteDialog}
        title={t('delete_new_issue_image_nft')}
        content={t('do_you_want_to_delete_it')}
        onClose={onCloseDeleteDialog}
        onConfirm={handleDeleteImage}
      />
      <MintOnPurchaseNFTDialog
        open={isOpenNFTDialog}
        nftInfo={selectedItem!}
        onClose={onCloseImageDetailDialog}
        isCollectionScreen
      />
    </Box>
  );
};

export default ImageNFTList;
