import { makeStyles } from 'tss-react/mui';

import { colors } from '~/constants/colors';

export const useSelectOrganizationStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#F8F8F8',
  },
  paper: {
    width: '100%',
    overflow: 'hidden',
    padding: '24px 16px',
    maxWidth: theme.breakpoints.values.sm,
  },
  header: {
    textAlign: 'center',
    marginBottom: '24px',
  },
  selectOrgIcon: {
    width: '48px',
    height: '48px',
    display: 'flex',
    borderRadius: '50%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: colors.ulg.hover,
    border: `1px dashed ${colors.primary}`,
    '.MuiSvgIcon-root': {
      color: colors.primary,
    },
  },
  orgCard: {
    cursor: 'pointer',
    boxShadow: 'none',
    borderRadius: '8px',
    border: `1px solid ${colors.divider}`,
    '.MuiCardContent-root': {
      gap: '16px',
      display: 'flex',
      minHeight: '88px',
      padding: '16px 20px',
      alignItems: 'center',
      flexDirection: 'row',
      '&:last-child': {
        paddingBottom: '16px',
      },
    },
  },
}));
