import { FC } from 'react';

import { Box, Link, SxProps, Theme, Typography } from '@mui/material';
import { Control, Controller, ControllerRenderProps, FieldValues, Path } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

type Props<T extends FieldValues> = {
  name: Path<T>;
  label?: string;
  isEditing: boolean;
  control: Control<T>;
  showBorderBottom?: boolean;
  readOnlyBodyStyle?: SxProps<Theme>;
  render: (field: ControllerRenderProps<T, Path<T>>) => JSX.Element;
  formatValue?: (field: ControllerRenderProps<T, Path<T>>) => string;
};

interface IWrapperTextFieldNoEdit {
  label?: string;
  value?: string;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    display: 'flex',
    padding: '12px 16px',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    borderBottom: '1px solid #D7D7D7',
    '.MuiTypography-subtitle2': {
      fontWeight: 500,
      fontSize: '10px',
      lineHeight: '16px',
      color: '#00000099',
      letterSpacing: '1.5px',
    },
    '.MuiTypography-body2': {
      fontWeight: 400,
      fontSize: '16px',
      overflow: 'auto',
      minHeight: '24px',
      lineHeight: '24px',
      color: '#000000DE',
      whiteSpace: 'pre-wrap',
      wordBreak: 'break-word',
      letterSpacing: '0.15px',
    },
  },
}));

export const WrapperTextFieldNoEdit: FC<IWrapperTextFieldNoEdit> = ({ label, value }) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapper}>
      <Typography variant="subtitle2">{label ?? ''}</Typography>
      <Typography variant="body2">{value}</Typography>
    </Box>
  );
};

const WrapperTextField = <T extends FieldValues>({
  name,
  label,
  control,
  isEditing,
  readOnlyBodyStyle,
  showBorderBottom = false,
  render,
  formatValue,
}: Props<T>) => {
  const { classes } = useStyles();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => {
        const value = !!formatValue ? formatValue(field) : field.value;
        return !isEditing ? (
          <Box className={classes.wrapper}>
            <Typography variant="subtitle2">{label ?? ''}</Typography>
            {/^http/.test(value) ? (
              <Link href={value} target="_blank">
                <Typography variant="body2" sx={readOnlyBodyStyle}>
                  {value}
                </Typography>
              </Link>
            ) : (
              <Typography variant="body2" sx={readOnlyBodyStyle}>
                {value}
              </Typography>
            )}
          </Box>
        ) : showBorderBottom ? (
          <Box className={classes.wrapper}>
            <Typography variant="subtitle2">{label ?? ''}</Typography>
            {render(field)}
          </Box>
        ) : (
          render(field)
        );
      }}
    />
  );
};

export default WrapperTextField;
