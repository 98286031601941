import { FC, memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { ERC721G__factory } from '@gusdk/erc721g';
import { useAccount as useWalletAccount } from '@gusdk/gu-wallet-connector';
import { JsonRpcProvider } from 'ethers';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import AddCollectionDialog from './AddCollectionDialog';

import { ListTablePagination } from '~/components/list-table';
import { CODE } from '~/constants/code';
import { ITEMS_PER_PAGE } from '~/constants/common';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { env } from '~/env';
import {
  CollectionQueryKey,
  MyShopCollectionQueryKey,
  QueryOperator,
  useListCollectionIdsInShopQuery,
  useListCollectionsQuery,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';
import { CollectionData } from '~/pages/collection';
import { COLLECTION_TYPE, GRANT_PERMISSION, NFT_SHOP_TYPE } from '~/types/my-shop';
import { CancelPromiseResponse, cancelPromise } from '~/utils/cancelPromise';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';
import { checkMinter } from '~/utils/erc721g.util';

let getCollectionPromise: CancelPromiseResponse;

interface WrapperAddCollectionDialogProps {
  open: boolean;
  onClose: () => void;
  refetchMyShopCollections: () => void;
}

const WrapperAddCollectionDialog: FC<WrapperAddCollectionDialogProps> = (props) => {
  const { open } = props;

  const { data: myShop } = useShopDetail();
  const { supportedNetworks } = useSupportedNetworks();

  const { id } = useParams();
  const { t } = useTranslation();
  const { account } = useWalletAccount();
  const { enqueueSnackbar } = useSnackbar();
  const { selectedOrganization } = useAccount();

  const refMasterWallet = useRef(selectedOrganization?.masterWalletAddress);
  refMasterWallet.current = selectedOrganization?.masterWalletAddress;

  const [rows, setRows] = useState<CollectionData[]>([]);
  const [isLoadingCollections, setIsLoadingCollections] = useState(true);

  const isPreMintType = myShop?.siteSetting?.category === NFT_SHOP_TYPE.PRE_MINT;

  const [collectionsQuery, setCollectionsQuery] = useState({
    page: 1,
    limit: ITEMS_PER_PAGE.LIST,
    searchText: '',
    sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('attach_collection_list_sort')),
    orderBy: verifyOrderKey(getLocalStorage('attach_collection_list_order')),
    where: {},
  });

  useEffect(() => {
    if (!open)
      setCollectionsQuery({
        page: 1,
        limit: ITEMS_PER_PAGE.LIST,
        searchText: '',
        sortBy: verifySortKey(CollectionQueryKey, getLocalStorage('attach_collection_list_sort')),
        orderBy: verifyOrderKey(getLocalStorage('attach_collection_list_order')),
        where: {},
      });
  }, [open]);

  const updateCollectionsQuery = (newValue: any) => setCollectionsQuery((value: any) => ({ ...value, ...newValue }));

  useEffect(() => {
    setLocalStorageItems({
      attach_collection_list_sort: collectionsQuery?.sortBy,
      attach_collection_list_order: collectionsQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [collectionsQuery?.orderBy, collectionsQuery?.sortBy]);

  const { data: dataListCollections, loading: loadingListCollections } = useListCollectionsQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: collectionsQuery,
  });
  const { data: dataListShopCollections, loading: loadingListShopCollections } = useListCollectionIdsInShopQuery({
    fetchPolicy: 'cache-and-network',
    skip: !open,
    variables: {
      where: {
        fields: [
          {
            value: [id ?? ''],
            operator: QueryOperator.Contains,
            key: MyShopCollectionQueryKey.ShopUuid,
          },
        ],
      },
    },
  });

  const loadingData = loadingListCollections || loadingListShopCollections || isLoadingCollections;

  const { items, pagination } = useMemo(() => {
    const items = dataListCollections?.listCollections?.items || [];
    const pagination: ListTablePagination = dataListCollections?.listCollections?.pagination || {};
    return { items, pagination };
  }, [dataListCollections]);

  const { items: selectedCollections } = useMemo(() => {
    const items = dataListShopCollections?.listMyShopCollections?.items || [];
    return { items };
  }, [dataListShopCollections]);

  const loadDataGridRows = useCallback(async () => {
    try {
      if (getCollectionPromise?.cancelCallback) {
        getCollectionPromise.cancelCallback();
      }
      getCollectionPromise = cancelPromise(async () => {
        let rejectSwitch = false;
        try {
          setIsLoadingCollections(true);
          let newRows: CollectionData[] = [];
          if (account && open) {
            newRows = await Promise.all(
              await items.reduce(async (resultPromise, item) => {
                const currentNetwork = supportedNetworks?.[item?.network || ''];
                let status = currentNetwork.testMode
                  ? GRANT_PERMISSION.UNAVAILABLE
                  : !isPreMintType
                  ? GRANT_PERMISSION.GRANTED
                  : GRANT_PERMISSION.NOT_PERMISSION;
                const newResult = await resultPromise;
                const provider = new JsonRpcProvider(currentNetwork?.rpcUrl);
                const contract = ERC721G__factory.connect(item?.contractAddress!, provider as any);
                const ownerAddress = await contract.owner();
                const ownerLowerCase = ownerAddress.toLowerCase();
                const currentWalletLowerCase = account.toLowerCase();
                const masterWalletLowerCase = refMasterWallet.current?.toLowerCase();
                if (isPreMintType && !currentNetwork.testMode) {
                  if (
                    !!refMasterWallet.current &&
                    [currentWalletLowerCase, masterWalletLowerCase].includes(ownerLowerCase)
                  ) {
                    try {
                      const isMinter = await checkMinter(contract, refMasterWallet.current);
                      status = isMinter ? GRANT_PERMISSION.GRANTED : GRANT_PERMISSION.NOT_GRANTED;
                    } catch (err: any) {
                      if (
                        !rejectSwitch &&
                        err?.error?.code !== CODE.INTERNAL_ERROR &&
                        err?.error?.data?.code !== CODE.NOT_SUPPORT_COLLECTION
                      ) {
                        rejectSwitch = false;
                      }
                    }
                  }
                }
                newResult.push({
                  status,
                  id: item.uuid,
                  name: item.name,
                  symbol: item.symbol,
                  network: item.network,
                  createdAt: item.createdAt,
                  type: item.type as COLLECTION_TYPE,
                  contractAddress: item.contractAddress,
                  url: env.REACT_APP_API_MEDIA + '/' + item?.images?.[0],
                  ownerAddress: ownerAddress || item.ownerAddress || undefined,

                  //new key
                  [CollectionQueryKey.Name]: item.name,
                  [CollectionQueryKey.Symbol]: item.symbol,
                  [CollectionQueryKey.Network]: item.network,
                  [CollectionQueryKey.CreatedAt]: item.createdAt,
                  [CollectionQueryKey.ContractAddress]: item.contractAddress,
                  [CollectionQueryKey.OwnerAddress]: ownerAddress || item.ownerAddress || undefined,
                } as CollectionData);
                return newResult;
              }, Promise.resolve([] as CollectionData[]))
            );
          }
          return newRows;
        } catch (err: any) {
          enqueueSnackbar(err.message || t('my_shop.message.error'), { variant: 'error' });
          return [];
        }
      });
      const newRows = await getCollectionPromise.awaitCallback;
      setRows(newRows as CollectionData[]);
      setIsLoadingCollections(false);
    } catch (err: any) {}
  }, [open, account, isPreMintType, items, supportedNetworks, t, enqueueSnackbar]);

  useEffect(() => {
    loadDataGridRows();
  }, [loadDataGridRows]);

  return (
    <AddCollectionDialog
      {...props}
      rows={rows}
      selectedCollections={selectedCollections}
      loadingData={loadingData}
      pagination={pagination}
      queryData={collectionsQuery}
      updateQuery={updateCollectionsQuery}
    />
  );
};

export default memo(WrapperAddCollectionDialog);
