import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { GridColDef, GridSortModel, jaJP } from '@mui/x-data-grid-pro';
import { loadStripe } from '@stripe/stripe-js';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import CustomCardTable from '~/components/custom-card-table';
import HeaderAction from '~/components/custom-card-table/HeaderAction';
import CustomGridToolbarSearchByAPI from '~/components/custom-grid-toolbar-search-by-api';
import CustomDataGrid from '~/components/CustomDataGrid';
import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import CustomNodata from '~/components/no-data';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { CARD_IMAGE } from '~/constants/common';
import {
  Currency,
  GetPaymentMethodsDocument,
  PaymentMethodStripe,
  useCreateCheckoutSessionMutation,
  useDetachPaymentMethodMutation,
  useGetPaymentMethodsQuery,
  useSetPaymentMethodDefaultMutation,
} from '~/graphql/member/types';
import useDebounce from '~/hooks/useDebounce';
import { useCheckPermissions } from '~/hooks/with-account';

const { ADD, EDIT, DELETE } = SCREEN_PERMISSION.SETTING.PAYMENT.PAYMENT_METHODS;

interface IPaymentMethod extends Partial<PaymentMethodStripe> {
  expireDate: string;
  isDefault: boolean;
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    '.MuiDataGrid-root': {
      border: 'none',
    },
    '.MuiDataGrid-toolbarContainer': {
      padding: '0',
      margin: '0',
    },
  },
}));

const schemaCurrency = yup.object({
  baseCurrency: yup.mixed<Currency>().required(),
});

export interface FormBaseCurrencyValues extends yup.InferType<typeof schemaCurrency> {}

const PaymentMethods = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [addable, editable, deletable] = useCheckPermissions([ADD, EDIT, DELETE]);

  const [search, setSearch] = useState('');
  const [menuAnchorEl, setMenuAnchorEl] = useState();
  const [selectedItemId, setSelectedItemId] = useState('');
  const [sortModel, setSortModel] = useState<GridSortModel>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const debounceValue = useDebounce(search, 1000);

  const [createCheckoutSession] = useCreateCheckoutSessionMutation();
  const [setPaymentMethodDefault] = useSetPaymentMethodDefaultMutation({
    refetchQueries: [GetPaymentMethodsDocument],
  });
  const [detachPaymentMethod] = useDetachPaymentMethodMutation({
    refetchQueries: [GetPaymentMethodsDocument],
  });
  const { data: paymentMethodsRes, loading: loadingPaymentMethods } = useGetPaymentMethodsQuery({
    fetchPolicy: 'cache-and-network',
  });

  const rows: IPaymentMethod[] = useMemo(
    () =>
      paymentMethodsRes?.getPaymentMethods
        .filter((i) => (i.data.last4 || '').toLowerCase().includes((debounceValue || '').toLowerCase()))
        .map(({ data: method, isDefault }) => ({
          id: method.id,
          name: method.name,
          last4: method.last4,
          brand: method.brand,
          isDefault: isDefault,
          expireDate: `${method.exp_month}/${method.exp_year}`,
        })) || [],
    [debounceValue, paymentMethodsRes]
  );

  const handleDelete = async () => {
    onCloseMenu();
    if (!selectedItemId) {
      enqueueSnackbar(t('toast_message.deleted_successfully'), { variant: 'error' });
      return;
    }
    await detachPaymentMethod({
      variables: {
        paymentId: selectedItemId,
      },
    });
  };

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };

  // Handle Menu
  const onOpenMenu = (paymentId: string | undefined) => (e: any) => {
    if (!paymentId) {
      return;
    }
    setSelectedItemId(paymentId);
    setMenuAnchorEl(e.currentTarget);
  };
  const onCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const columns = useMemo<GridColDef<IPaymentMethod>[]>(
    () => [
      {
        minWidth: 250,
        field: 'last4',
        headerName: t('card_number'),
        renderCell: ({ value, row }) => {
          const path = CARD_IMAGE[row.brand as keyof typeof CARD_IMAGE];
          return (
            <Box display="flex" gap="8px" alignItems="center">
              {path ? <img width="30px" alt="" src={path} /> : null}
              <Typography>**** **** **** {value}</Typography>
            </Box>
          );
        },
      },
      {
        width: 100,
        field: 'isDefault',
        type: 'boolean',
        headerName: t('default'),
      },
      {
        width: 200,
        field: 'name',
        headerName: t('name.person'),
      },
      {
        width: 100,
        field: 'expireDate',
        headerName: t('member_site.expire_date'),
      },
      {
        width: 100,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('information'),
        getActions: ({ row }) =>
          row.isDefault || !editable
            ? []
            : [
                <IconButton onClick={onOpenMenu(row.id)}>
                  <MoreVertIcon sx={{ color: 'rgba(0, 0, 0, 0.87)' }} />
                </IconButton>,
              ],
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t, editable]
  );

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const toolbarProps = {
    search,
    searchLabel: t('card_number'),
    handleSearch,
  };

  const handleSortModelChange = (model: GridSortModel) => {
    setSortModel(model);
    localStorage.setItem('paymentMethods', JSON.stringify(model));
  };

  const setPaymentMethodAsDefault = async () => {
    try {
      onCloseMenu();
      await setPaymentMethodDefault({
        variables: {
          paymentId: selectedItemId,
        },
      });
      enqueueSnackbar(t('toast_message.updated_successfully'), { variant: 'success' });
    } catch (err: any) {}
  };

  const handleSubmit = async () => {
    const snackbarKey = enqueueSnackbar(`${t('redirecting')}...`, { variant: 'info', persist: true });
    try {
      const { data } = await createCheckoutSession({
        variables: {
          input: {
            locale: i18n.language,
            cancelUrl: window.location.href,
            successUrl: window.location.href,
          },
        },
      });
      const sessionId = data?.createCheckoutSession.sessionId;
      const publishableKey = data?.createCheckoutSession?.publishableKey;
      if (publishableKey && sessionId) {
        const stripe = await loadStripe(publishableKey);
        await stripe?.redirectToCheckout({ sessionId });
      }
    } catch (err: any) {
      closeSnackbar(snackbarKey);
    }
  };

  useEffect(() => {
    const storedSortModel = localStorage.getItem('paymentMethods');
    if (storedSortModel) {
      setSortModel(JSON.parse(storedSortModel));
    }
  }, []);

  return (
    <Box className={classes.wrapper}>
      {(editable || deletable) && (
        <Menu anchorEl={menuAnchorEl} open={!!menuAnchorEl} onClose={onCloseMenu}>
          {editable && <MenuItem onClick={setPaymentMethodAsDefault}>{t('set_as_default')}</MenuItem>}
          {deletable && <MenuItem onClick={handleOpenDeleteDialog}>{t('delete')}</MenuItem>}
        </Menu>
      )}
      <CustomCardTable
        cardTitle={t('my_shop.payment_methods.payment_methods')}
        headerAction={
          addable ? <HeaderAction menus={[{ title: t('add_payment_method'), onClick: handleSubmit }]} /> : undefined
        }
        cardContent={
          <CustomDataGrid
            autoHeight
            pagination
            rows={rows}
            columns={columns}
            loading={loadingPaymentMethods}
            initialState={{
              pagination: {
                paginationModel: { pageSize: 5, page: 0 },
              },
            }}
            sortModel={sortModel}
            onSortModelChange={handleSortModelChange}
            pageSizeOptions={[5]}
            disableVirtualization
            disableRowSelectionOnClick
            slotProps={{
              toolbar: toolbarProps,
              noRowsOverlay: {
                message: t('no_data_available'),
              },
            }}
            localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
            slots={{
              toolbar: CustomGridToolbarSearchByAPI,
              noRowsOverlay: CustomNodata,
              noResultsOverlay: CustomNodata,
            }}
          />
        }
      />
      <ConfirmationDialog
        open={openDeleteDialog}
        confirmTitle={t('delete')}
        title={t('delete_payment_method')}
        onConfirm={handleDelete}
        onClose={handleCloseDeleteDialog}
      />
    </Box>
  );
};

export default PaymentMethods;
