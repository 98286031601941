import { FC } from 'react';

import { Box, CardContent, Typography } from '@mui/material';
import Card from '@mui/material/Card';
import { useTranslation } from 'react-i18next';

import { useSelectOrganizationStyles } from './styles';

import { OrganizationRoleUser } from '~/graphql/member/types';

interface IOrganizationCard {
  organization: OrganizationRoleUser;
  onSelectedOrganization: (uuid: string, isCreatingOrg?: boolean) => void;
}

const OrganizationCard: FC<IOrganizationCard> = ({ organization, onSelectedOrganization }) => {
  const { t } = useTranslation();
  const { classes } = useSelectOrganizationStyles();
  return (
    <Card
      className={classes.orgCard}
      sx={{ ':not(:first-of-type)': { marginTop: '20px' } }}
      onClick={() => onSelectedOrganization(organization.uuid)}
    >
      <CardContent>
        <Box flex="1">
          <Typography variant="h6">{organization.name}</Typography>
          <Typography variant="body1">{organization.contactEmail}</Typography>
        </Box>
        <Box>
          <Typography variant="body2">{`${organization.totalMember} ${t('members')}`}</Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default OrganizationCard;
