import { ReactNode, useCallback, useMemo } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { DialogProps } from '@mui/material/Dialog';
import Link from '@mui/material/Link';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { Trans, useTranslation } from 'react-i18next';
import * as yup from 'yup';

import CustomDialog from '../dialog/custom-dialog';

import { MEMBER_ACTIONS } from '~/config/roleConfig';
import { CODE } from '~/constants/code';
import {
  OrganizationUserRole,
  ListOrganizationUsersDocument,
  useAddOrganizationUserMutation,
} from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

export const initRoleOptions = (t: TFunction) => [
  {
    value: OrganizationUserRole.Owner,
    label: t('role_options.owner'),
  },
  {
    value: OrganizationUserRole.Admin,
    label: t('role_options.admin'),
  },
  {
    value: OrganizationUserRole.Member,
    label: t('role_options.member'),
  },
  {
    value: OrganizationUserRole.Viewer,
    label: t('role_options.viewer'),
  },
];

interface Props extends DialogProps {
  onClose: () => void;
}

const schema = yup.object({
  email: yup.string().email('form_validation.invalid_email').required(),
  role: yup.mixed<OrganizationUserRole>().oneOf(Object.values(OrganizationUserRole)).required(),
});

interface FormValues extends yup.InferType<typeof schema> {}

const AddUserDialog: React.FC<Props> = (props) => {
  const { open, onClose } = props;

  const { t } = useTranslation();
  const { selectedOrganization } = useAccount();

  const [addOrganizationUser] = useAddOrganizationUserMutation({
    refetchQueries: [ListOrganizationUsersDocument],
  });

  const {
    control,
    reset,
    setError,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      email: '',
      role: MEMBER_ACTIONS.ADD[selectedOrganization.role][0] || OrganizationUserRole.Member,
    },
    resolver: yupResolver(schema),
  });

  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    reset();
    onClose();
  };

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        await addOrganizationUser({
          variables: {
            input: {
              email: data.email,
              role: data.role,
            },
          },
        });
        enqueueSnackbar(t('toast_message.add_member_successfully'), { variant: 'success' });
        handleClose();
      } catch (error: any) {
        if (error.message === CODE.USER_NEED_REGISTER_FIREBASE) {
          setError('email', { message: 'form_validation.email_not_registered_gu_account' });
        } else {
          enqueueSnackbar(
            error.message === CODE.USER_ALREADY_ADDED ? t('toast_message.this_member_added') : error.message,
            { variant: 'error' }
          );
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [addOrganizationUser, enqueueSnackbar, t, setError]
  );

  const roleOptions = useMemo(
    () =>
      initRoleOptions(t).reduce((result, option) => {
        if (MEMBER_ACTIONS.ADD[selectedOrganization.role]?.includes(option.value)) {
          result.push(
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          );
        }
        return result;
      }, [] as ReactNode[]),
    [selectedOrganization.role, t]
  );

  return (
    <CustomDialog
      width="md"
      open={open}
      onClose={handleClose}
      dialogContent={
        <>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label={t('email_address')}
                variant="outlined"
                margin="dense"
                InputProps={{
                  type: 'email',
                }}
                error={!!errors.email?.message}
                helperText={
                  errors.email?.message ? (
                    <Trans
                      i18nKey={t(errors.email?.message)}
                      components={{
                        lnk: <Link sx={{ color: '#1098FC' }} href="https://account.gu.net/" target="_blank" />,
                      }}
                    />
                  ) : (
                    ''
                  )
                }
                disabled={isSubmitting}
                {...field}
              />
            )}
          />
          <Controller
            name="role"
            control={control}
            render={({ field }) => (
              <TextField
                fullWidth
                label={t('role')}
                variant="outlined"
                margin="normal"
                error={!!errors.role?.message}
                helperText={t(errors.role?.message as any)}
                {...field}
                select
                disabled={isSubmitting}
              >
                {roleOptions}
              </TextField>
            )}
          />
        </>
      }
      dialogTitle={t('add_member_dialog.title')}
      actions={[
        <Button disabled={isSubmitting} color="primary" variant="outlined" onClick={handleClose}>
          {t('cancel')}
        </Button>,
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('add')}
        </Button>,
      ]}
    />
  );
};

export default AddUserDialog;
