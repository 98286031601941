import { FC } from 'react';

import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useWatch } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

import SquareImage from '~/components/SquareImage';
import { useSupportedNetworks } from '~/contexts/SupportedNetworksProvider';
import { ReactComponent as QrIcon } from '~/icons/images/qr_code.svg';
import { useEditShop } from '~/pages/edit-shop';
import { OBJECT_FIT } from '~/types/my-shop';

interface NFTQRCodeProps {
  nftInfo: NFTInfo;
}

export interface NFTInfo {
  id: string;
  name: string;
  image: string;
  tokenId: string;
  network: string;
  expireDate?: string;
  purchaseDate?: string;
  contractAddress?: string;
}

const useStyles = makeStyles<{
  nftCardTextColor: string;
  nftCardBackgroundColor: string;
  collectionNftCardImageMode: OBJECT_FIT;
}>()((_, { nftCardTextColor, nftCardBackgroundColor, collectionNftCardImageMode }) => ({
  NFTQRCode: {
    border: '1px solid #D7D7D7',
    backgroundColor: nftCardBackgroundColor || 'transparent',
    '.MuiCardContent-root': {
      padding: '12px 12px 8px',
      display: 'flex',
      flexDirection: 'column',
      '.nft-info': {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '.network': {
          width: '24px',
        },
      },
      '.nft-name': {
        fontWeight: 700,
        fontSize: '12px',
        lineHeight: '24px',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        letterSpacing: '0.15px',
        color: nftCardTextColor,
        textOverflow: 'ellipsis',
      },
      '.box-date': {
        display: 'flex',
        justifyContent: 'center',
        '.title': {
          marginRight: 8,
        },
      },
    },
    img: {
      objectFit: collectionNftCardImageMode || OBJECT_FIT.COVER,
    },
    '.MuiCardActions-root': {
      padding: '0 12px 12px',
      justifyContent: 'space-between',
      img: {
        width: '24px',
        cursor: 'pointer',
      },
      '.MuiTypography-subtitle2': {
        fontWeight: 400,
        fontSize: '12px',
        lineHeight: '24px',
        color: nftCardTextColor,
        letterSpacing: '0.15px',
      },
    },
  },
  qrDialog: {
    '.qr-code': {
      display: 'flex',
      justifyContent: 'center',
    },
    '.contract-address': {
      marginTop: '8px',
      display: 'flex',
      alignItems: 'center',
    },
  },
}));

const NFTQRCode: FC<NFTQRCodeProps> = ({ nftInfo }) => {
  const { control } = useEditShop();
  const { supportedNetworks } = useSupportedNetworks();

  const [nftCardTextColor, nftCardBackgroundColor, collectionNftCardImageMode] = useWatch({
    control,
    name: ['style.nftCardTextColor', 'style.nftCardBackgroundColor', 'style.collectionNftCardImageMode'],
  });

  const { classes } = useStyles({
    nftCardTextColor,
    nftCardBackgroundColor,
    collectionNftCardImageMode: collectionNftCardImageMode as OBJECT_FIT,
  });

  const networkSymbolUrl = supportedNetworks?.[nftInfo?.network]?.icon;

  return (
    <Card className={classes.NFTQRCode}>
      <SquareImage src={nftInfo.image} />
      <CardContent>
        <Box className="nft-info">
          <Typography variant="subtitle1" className="nft-name">
            {nftInfo.name}
          </Typography>
          {!!networkSymbolUrl && <img src={networkSymbolUrl} alt="Network Symbol" className="network" />}
        </Box>
      </CardContent>
      <CardActions>
        <Typography variant="subtitle2" className="token-id">
          #{nftInfo.tokenId}
        </Typography>
        <QrIcon />
      </CardActions>
    </Card>
  );
};

export default NFTQRCode;
