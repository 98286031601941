import { FC } from 'react';

import Box from '@mui/material/Box';
import { makeStyles } from 'tss-react/mui';

import { RightSide } from '../custom-grid-toolbar-search-by-api';

import { IGridToolbar } from '~/interfaces/common';

const useStyles = makeStyles()(({ breakpoints }) => ({
  wrapperToolbar: {
    gap: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    [breakpoints.down('md')]: {
      justifyContent: 'space-between',
    },
  },
}));

const CustomHeaderGallery: FC<IGridToolbar> = (props) => {
  const { classes } = useStyles();

  return (
    <Box className={classes.wrapperToolbar}>
      <RightSide {...props} />
    </Box>
  );
};

export default CustomHeaderGallery;
