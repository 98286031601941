import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import LinkButton from '../link-button';
import PermissionsView from '../PermissionsView';

import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { colors } from '~/constants/colors';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { useGetNextPlanQuery } from '~/graphql/member/types';
import { useAccount } from '~/hooks/with-account';

const { PLANS } = SCREEN_PERMISSION;

const CurrentPlanBox = () => {
  const { t } = useTranslation();
  const { plan: currentPlan } = useAccount();

  const { data: getNextPlan } = useGetNextPlanQuery({
    fetchPolicy: 'cache-and-network',
  });
  const nextPlan = getNextPlan?.getNextPlan;
  const isPlanChanged = currentPlan?.uuid !== nextPlan?.uuid;

  return (
    <Box sx={{ margin: '20px 0', padding: '16px', borderRadius: '8px', backgroundColor: colors.ulg.hover }}>
      <Box gap="8px" display="flex">
        <Box color={colors.primary}>
          <VerifiedOutlinedIcon />
        </Box>
        <Box>
          <Typography variant="body1" fontWeight={500} color={colors.primary}>{`${currentPlan?.planName || '-'} ${t(
            'plan'
          )}`}</Typography>
          <Typography variant="caption" whiteSpace="pre-wrap">
            {isPlanChanged ? (
              <Trans
                i18nKey="change_plan_desc"
                values={{
                  planName: nextPlan?.planName || '-',
                  startDate: moment(nextPlan?.startAt || '').format(t('date_time_format')),
                }}
                components={{
                  b: <strong />,
                }}
              />
            ) : (
              t('unlock_all_feature_on_our_system')
            )}
          </Typography>
        </Box>
      </Box>
      <PermissionsView roles={PLANS.VIEW}>
        <LinkButton to={AppRouteEnum.Plans} fullWidth variant="contained" sx={{ marginTop: '16px' }}>
          {t('upgrade')}
        </LinkButton>
      </PermissionsView>
    </Box>
  );
};

export default CurrentPlanBox;
