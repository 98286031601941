import { useState } from 'react';

import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import moment from 'moment';
import { Trans, useTranslation } from 'react-i18next';

import LicenseStatements from './LicenseStatements';
import SummaryInformation from './SummaryInformation';

import ConfirmationDialog from '~/components/dialog/confirmation-dialog';
import { GetNextPlanDocument, useCancelFuturePlanMutation, useGetNextPlanQuery } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount } from '~/hooks/with-account';

const PlanManagement = () => {
  const { t } = useTranslation();
  const { showError, showSuccess } = useNotify();
  const { plan: currentPlan } = useAccount();

  const [openCancel, setOpenCancel] = useState(false);

  const { data: getNextPlan } = useGetNextPlanQuery({
    fetchPolicy: 'cache-and-network',
  });
  const [cancelFuturePlan] = useCancelFuturePlanMutation({
    refetchQueries: [GetNextPlanDocument],
  });
  const nextPlan = getNextPlan?.getNextPlan;
  const isPlanChanged = currentPlan && nextPlan && currentPlan.uuid !== nextPlan.uuid;

  const handleOpenCancel = () => {
    setOpenCancel(true);
  };
  const handleCloseCancel = () => {
    setOpenCancel(false);
  };

  const handleCancel = async () => {
    try {
      await cancelFuturePlan();
      showSuccess('toast_message.canceled_successfully');
    } catch (error) {
      showError(error);
    }
  };

  return (
    <Box gap="24px" display="flex" flexDirection="column">
      {isPlanChanged && (
        <Alert
          severity="warning"
          action={
            <Button variant="contained" color="warning" onClick={handleOpenCancel}>
              {t('cancel')}
            </Button>
          }
        >
          <AlertTitle>{t('settings.plan_management.downgrade_is_processing')}</AlertTitle>
          <Trans
            i18nKey="change_plan_desc"
            values={{
              planName: nextPlan?.planName || '-',
              startDate: moment(nextPlan?.startAt || '').format(t('date_time_format')),
            }}
            components={{
              b: <strong />,
            }}
          />
        </Alert>
      )}
      <SummaryInformation />
      <LicenseStatements />
      <ConfirmationDialog
        open={openCancel}
        confirmTitle={t('confirm')}
        title={t('settings.plan_management.cancel_plan_change')}
        content={t('settings.plan_management.cancel_plan_change_desc')}
        onConfirm={handleCancel}
        onClose={handleCloseCancel}
      />
    </Box>
  );
};

export default PlanManagement;
