import { createContext, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { useTranslation } from 'react-i18next';

import CreateFirstOrganization from '~/components/create-first-organization';
import ScreenLoading from '~/components/screen-loading';
import SelectOrganization from '~/components/select-organization';
import TermsOfUse from '~/components/terms-of-use';
import {
  GetCurrentPlanQuery,
  GetMeDocument,
  OrganizationRoleUser,
  OrganizationUserRole,
  UserOrs,
  useGetCurrentPlanQuery,
  useGetIsLockedShopQuery,
  useGetMeQuery,
  useListAllOpenInvoicesQuery,
  useUpdateUserMutation,
} from '~/graphql/member/types';

const AccountContext = createContext<{
  account: UserOrs;
  isLocked: boolean;
  lockTime: Date | undefined;
  hasUnpaidInvoices: boolean;
  selectedOrganization: OrganizationRoleUser;
  plan?: GetCurrentPlanQuery['getCurrentPlan'] | null;
  setSelectedOrganization: (uuid: string, isCreatingOrg?: boolean) => void;
}>({} as any);

export const useAccount = () => useContext(AccountContext);

export const useCheckPermissions = (
  rolesGroups: ((role: OrganizationUserRole) => Array<OrganizationUserRole[]>) | Array<OrganizationUserRole[]>
) => {
  const { selectedOrganization } = useAccount();
  const role = selectedOrganization.role;
  const result = [];
  if (typeof rolesGroups === 'function') {
    rolesGroups = rolesGroups(role);
  }
  for (let roles of rolesGroups) {
    result.push((roles || []).includes(role));
  }
  return result;
};

export const WithAccount: React.FC<React.PropsWithChildren> = (props) => {
  const { i18n } = useTranslation();

  const [selectedOrganizationUuid, setSelectedOrganizationUuid] = useState(() => {
    return localStorage.getItem('selectedOrganization') || '';
  });

  const isCreatingNewOrg = useRef(false);

  const [updateUser] = useUpdateUserMutation({
    refetchQueries: [GetMeDocument],
  });
  const { data: getIsLocked, loading: loadingGetIsLocked } = useGetIsLockedShopQuery({
    fetchPolicy: 'no-cache',
    skip: !selectedOrganizationUuid,
    context: {
      selectedOrganizationUuid,
    },
  });
  const { data: listOpenInvoicesRes, loading: loadingOpenInvoices } = useListAllOpenInvoicesQuery({
    fetchPolicy: 'no-cache',
    skip: !selectedOrganizationUuid,
    context: {
      selectedOrganizationUuid,
    },
  });
  const { data: currentPlanRes, loading: loadingCurrentPlan } = useGetCurrentPlanQuery({
    fetchPolicy: 'no-cache',
    skip: !selectedOrganizationUuid,
    context: {
      selectedOrganizationUuid,
    },
  });
  const { data: getMeRes, loading: loadingMe } = useGetMeQuery({
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => {
      const userLanguage = data.getMe?.language as 'ja' | 'en' | undefined;
      if (!userLanguage) {
        updateUser({
          variables: {
            input: {
              language: i18n.language,
            },
          },
        });
        return;
      }
      if (userLanguage !== i18n.language) {
        i18n.changeLanguage(userLanguage);
      }
    },
  });

  const myInfo = getMeRes?.getMe;
  const lockInfo = getIsLocked?.getIsLockedShop;
  const currentPlan = currentPlanRes?.getCurrentPlan;
  const hasUnpaidInvoices = !!listOpenInvoicesRes?.listAllOpenInvoices.length;

  const onSelectedOrganization = useCallback(async (uuid: string, isCreatingOrg = false) => {
    localStorage.setItem('selectedOrganization', uuid || '');
    isCreatingNewOrg.current = isCreatingOrg;
    setSelectedOrganizationUuid(uuid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const organizationsObject = useMemo(() => {
    if (!myInfo?.organizations) {
      return undefined;
    }
    return new Map(myInfo.organizations.map((org) => [org.uuid, org]));
  }, [myInfo]);

  const selectedOrganization = organizationsObject?.get(selectedOrganizationUuid!);

  useEffect(() => {
    if (myInfo && selectedOrganization) {
      localStorage.setItem('organizationUuid', selectedOrganization.uuid);
    }
  }, [myInfo, selectedOrganization]);

  if ((!myInfo && loadingMe) || (!currentPlan && loadingCurrentPlan) || loadingOpenInvoices || loadingGetIsLocked) {
    return <ScreenLoading />;
  }

  if (!myInfo) {
    return <TermsOfUse />;
  }

  if (!myInfo.organizations.length) {
    return <CreateFirstOrganization user={myInfo} />;
  }

  if (!selectedOrganization) {
    return <SelectOrganization organizations={myInfo.organizations} onSelectedOrganization={onSelectedOrganization} />;
  }

  return (
    <AccountContext.Provider
      value={{
        account: myInfo,
        plan: currentPlan,
        hasUnpaidInvoices,
        selectedOrganization,
        lockTime: lockInfo?.lockTime,
        isLocked: !!lockInfo?.locked,
        setSelectedOrganization: onSelectedOrganization,
      }}
    >
      {props.children}
    </AccountContext.Provider>
  );
};
