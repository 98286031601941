import { useEffect, memo, useMemo, FC } from 'react';

import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import LinkButton from '~/components/link-button';
import PermissionsView from '~/components/PermissionsView';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import { OrganizationUserRole } from '~/graphql/member/types';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const {
  COLLECTIONS: { ADD },
  SETTING: {
    BILLING: { VIEW },
  },
} = SCREEN_PERMISSION;

interface IHeaderAction {
  buttonName?: string;
  onClick?: () => void;
}

interface ICard {
  data: { to: AppRouteEnum; role: OrganizationUserRole[] | null; title: string; description: string };
}

const useStyles = makeStyles()((theme) => ({
  content: {
    height: '100%',
    width: '100%',
  },
  welcomeBox: {
    display: 'flex',
    flexDirection: 'column',
  },
  welcome: {
    fontSize: 24,
    color: '#333333',
  },
  description: {
    marginTop: 8,
    color: '#0000008A',
    fontSize: 16,
  },
  lastLogin: {
    fontSize: 14,
    color: '#0000008A',
    alignSelf: 'end',
    margin: '8px 0 16px 0',
  },
  cardContent: {
    padding: 24,
  },
  contentHeader: {
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
  },
  contentText: {
    fontSize: 16,
    color: '#0000008A',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

const Home: React.FC = () => {
  const { t, i18n } = useTranslation();
  const { classes } = useStyles();
  const firebaseUser = useFirebaseUser();
  const { isLocked, lockTime, hasUnpaidInvoices } = useAccount();

  useEffect(() => {
    document.title = t('home_page.home');
  }, [t]);

  const [creatableCollection, viewableBilling] = useCheckPermissions([ADD, VIEW]);

  const cardsList = useMemo(
    () => [
      {
        role: null,
        to: AppRouteEnum.Collection,
        title: t(creatableCollection ? 'home_page.create_new_collection' : 'home_page.show_collections_list'),
        description: t(creatableCollection ? 'home_page.collection_explain_text' : 'home_page.collection_desc'),
      },
      {
        to: AppRouteEnum.MyShop,
        role: SCREEN_PERMISSION.SHOPS.ADD,
        title: t('home_page.create_shop'),
        description: t('home_page.shop_explain_text'),
      },
      {
        to: AppRouteEnum.MemberSite,
        title: t('home_page.membersite'),
        role: SCREEN_PERMISSION.MEMBER_SITES.ADD,
        description: t('home_page.membersite_explain'),
      },
    ],
    [t, creatableCollection]
  );

  const alertInfo = useMemo(() => {
    const today = moment();
    const startOfMonth = moment().startOf('month');
    const endOfFirstWeek = moment(lockTime);
    const isInFirstSevenDays = today.isSameOrAfter(startOfMonth) && today.isBefore(endOfFirstWeek);
    const daysLeft = endOfFirstWeek.diff(today, 'days');

    const daysText = i18n.language === 'ja' ? `${daysLeft}日` : `${daysLeft} ${daysLeft === 1 ? 'day' : 'days'}`;

    return isLocked
      ? {
          severity: 'error' as 'error',
          description: t('settings.billing.payment_overdue_desc_2'),
        }
      : {
          severity: 'warning' as 'warning',
          description: isInFirstSevenDays
            ? t('settings.billing.unpaid_invoices_alert', { day: daysText })
            : t('settings.billing.unpaid_invoices_alert_2'),
        };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, i18n.language]);

  const unpaidInvoicesAlert = (
    <Alert
      severity={alertInfo.severity}
      sx={{ marginBottom: '16px' }}
      action={
        viewableBilling ? (
          <LinkButton to={`${AppRouteEnum.Setting}?tab=1`} color="inherit" size="small">
            {t('details')}
          </LinkButton>
        ) : undefined
      }
    >
      {alertInfo.description}
    </Alert>
  );

  return (
    <UserLayout>
      <Box className={classes.content}>
        {hasUnpaidInvoices && unpaidInvoicesAlert}
        <Box className={classes.welcomeBox}>
          <Typography className={classes.welcome}>
            {t('home_page.welcome', { name: firebaseUser?.displayName })}
          </Typography>
          <Typography className={classes.description}>{t('home_page.description')}</Typography>
          <Typography className={classes.lastLogin}>
            {t('home_page.last_login')}: {moment(firebaseUser?.metadata.lastSignInTime).format(t('date_format'))}
          </Typography>
        </Box>
        <Grid container columnSpacing={2} rowSpacing={2}>
          {cardsList.map((card) => (
            <Card key={card.to} data={card} />
          ))}
        </Grid>
      </Box>
    </UserLayout>
  );
};

const HeaderAction = (props: IHeaderAction) => {
  const { t } = useTranslation();
  return (
    <Button variant="text" color="primary" style={{ textTransform: 'none' }} onClick={props.onClick}>
      <Typography sx={{ fontSize: 16 }}>{props.buttonName || t('go')}</Typography>
      <ArrowForwardIosIcon sx={{ fontSize: '12px', marginLeft: '8px' }} />
    </Button>
  );
};

const Card: FC<ICard> = ({ data }) => {
  const { classes } = useStyles(undefined, { props: {} });
  const navigate = useNavigate();
  const children = (
    <Grid item xs={12} md={6}>
      <CustomCardTable
        reverse
        headerAction={
          <HeaderAction
            onClick={() => {
              navigate(data.to);
            }}
          />
        }
        cardContent={
          <Box className={classes.cardContent}>
            <Typography className={classes.contentHeader}>{data.title}</Typography>
            <Typography className={classes.contentText}>{data.description}</Typography>
          </Box>
        }
      />
    </Grid>
  );
  return !!data.role ? <PermissionsView roles={data.role}>{children}</PermissionsView> : children;
};

export default memo(Home);
