import { FC, forwardRef, useContext, useEffect, useMemo, useRef, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { TFunction } from 'i18next';
import { useSnackbar } from 'notistack';
import { Control, Controller, FieldErrorsImpl, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import ReactQuill from 'react-quill';
import { useParams } from 'react-router';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';
import 'react-quill/dist/quill.snow.css';

import { useValidLinkQuill } from '~/components/TextEditor';
import { IMyShopFormWrapper, useMyShopFormWrapper } from '~/contexts/MyShopFormWrapper';
import { useShopDetail } from '~/contexts/ShopDetailWrapper';
import { ShopFormEnum } from '~/enum/pages/my-shop';
import { GetMyShopDocument, useUpdateMyShopMutation } from '~/graphql/member/types';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles<{ t: TFunction; isCreate?: boolean; isEditing: boolean }>()(
  (_, { t, isCreate, isEditing }) => ({
    wrapper: {
      '.MuiPaper-root': {
        boxShadow: 'none',
        overflow: 'hidden',
        borderRadius: '8px',
        border: '1px solid #D7D7D7',
      },
      '& > .MuiCard-root > .MuiCardHeader-root': {
        minHeight: '52px',
        padding: '0px 16px',
        borderBottom: isCreate ? 'none' : '1px solid #D7D7D7',
        '.MuiCardHeader-action': {
          alignSelf: 'center',
        },
      },
      '.MuiCardContent-root': {
        padding: '16px 16px 0',
        '.wrapperTextEditor': {
          width: '100%',
          p: {
            margin: 0,
            padding: 0,
          },
          ...(isCreate || isEditing
            ? {
                '&:not(:last-of-type)': {
                  marginBottom: '16px',
                },
              }
            : {
                minHeight: '150px',
                padding: '12px 16px',
                borderBottom: '1px solid #D7D7D7',
                '.ql-editor': {
                  padding: 0,
                },
              }),
          '.MuiTypography-subtitle2': {
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '16px',
            color: '#00000099',
            letterSpacing: '1.5px',
            marginBottom: isCreate || isEditing ? '8px' : '0',
          },
          '.quill': {
            '.ql-toolbar.ql-snow': {
              borderRadius: '4px 4px 0 0',
            },
            '.ql-container.ql-snow': {
              fontSize: '1rem',
              borderRadius: '0 0 4px 4px',
              fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
              '.ql-editor': {
                minHeight: '150px',
                maxHeight: '150px',
              },
              '.ql-tooltip': {
                '&:before': {
                  content: `"${t('link')}"`,
                },
                ':not(.ql-editing)': {
                  '.ql-action:after': {
                    content: `"${t('edit')}"`,
                  },
                  '.ql-remove:before': {
                    content: `"${t('remove')}"`,
                  },
                },
                '&.ql-editing .ql-action:after': {
                  content: `"${t('save')}"`,
                },
              },
            },
          },
        },
      },
      '.MuiCardActions-root': {
        padding: '16px',
        justifyContent: isCreate ? 'space-between' : 'flex-end',
      },
    },
  })
);

const schema = yup.object({
  policy: yup.string().required(),
  terms: yup.string().required(),
  information: yup.string().required(),
});

interface LegalInformationProps {
  isCreate?: boolean;
  onCreate?: (data: IMyShopFormWrapper) => Promise<void>;
}

export interface FormLegalInformationValues extends yup.InferType<typeof schema> {}

const formats = ['align', 'bold', 'italic', 'underline', 'size', 'color', 'link'];

const toolbarOptions = [
  [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ['bold', 'italic', 'underline'], // toggled buttons

  [{ color: [] }],

  [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown

  ['link'],

  ['clean'], // remove formatting button
];

const LegalInformation: FC<LegalInformationProps> = ({ isCreate, onCreate }, ref) => {
  const { editingAt, handleEditingAt } = useShopDetail();
  const { formData, setFormData, handlePreviousStep } = useMyShopFormWrapper();
  const [submitting, setSubmitting] = useState(false);

  const isEditing = editingAt === ShopFormEnum.LEGAL;

  const { id } = useParams();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const { classes } = useStyles({ t, isCreate, isEditing });

  const [updateMyShop] = useUpdateMyShopMutation({
    refetchQueries: [GetMyShopDocument],
  });

  const refPolicyEditor = useRef<ReactQuill>(null);
  const refTermsEditor = useRef<ReactQuill>(null);
  const refInforEditor = useRef<ReactQuill>(null);

  const MAX_LENGTH = 10000;

  const defaultValues = useMemo(
    () => ({
      policy: formData?.shopInformation?.policy || '',
      terms: formData?.shopInformation?.terms || '',
      information: formData?.shopInformation?.information || '',
    }),
    [formData]
  );

  const {
    control,
    reset,
    handleSubmit,
    formState: { dirtyFields, errors, isSubmitting },
    setError,
  } = useForm<FormLegalInformationValues>({
    defaultValues,
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    resolver: yupResolver(schema),
  });
  const isDirty = !!Object.keys(dirtyFields).length;

  const handleChangeToEdit = () => {
    handleEditingAt(ShopFormEnum.LEGAL);
  };
  const handleCancel = () => {
    if (isCreate) {
      handlePreviousStep();
    } else {
      reset(defaultValues);
      handleEditingAt(undefined);
    }
  };

  const checkError = (length: number, name: 'information' | 'policy' | 'terms') => {
    if (length > MAX_LENGTH) {
      setError(name, { message: t('my_shop.message.errors.max', { max: MAX_LENGTH }) });
    }
  };

  const onSubmit = async (data: FormLegalInformationValues) => {
    setSubmitting(true);
    const infoLength = refInforEditor.current?.getEditor().getLength()!;
    const policyLength = refPolicyEditor.current?.getEditor().getLength()!;
    const termsLength = refTermsEditor.current?.getEditor().getLength()!;

    if (infoLength > MAX_LENGTH || policyLength > MAX_LENGTH || termsLength > MAX_LENGTH) {
      checkError(infoLength, 'information');
      checkError(policyLength, 'policy');
      checkError(termsLength, 'terms');

      setSubmitting(false);
      return;
    }
    setFormData((prevState) => ({
      ...prevState,
      shopInformation: data,
    }));
    const finalizeData = {
      ...formData,
      shopInformation: data,
    };
    if (isCreate) {
      if (onCreate) {
        onCreate(finalizeData);
      }
    } else {
      try {
        await updateMyShop({
          variables: {
            input: {
              uuid: id!,
              shopInformation: {
                policy: data.policy,
                terms: data.terms,
                information: data.information,
              },
            },
          },
        });
        handleEditingAt(undefined);
        setSubmitting(false);
        enqueueSnackbar(t('my_shop.message.update_successful'), { variant: 'success' });
      } catch (error: any) {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  useEffect(() => {
    return () => {
      if (!!handleEditingAt) {
        handleEditingAt(undefined);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (defaultValues) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return (
    <Box className={classes.wrapper}>
      <Card>
        <CardHeader
          title={t('my_shop.shop_information.legal_information')}
          titleTypographyProps={{ fontSize: '16px', fontWeight: 700, letterSpacing: '0.1px', lineHeight: '20px' }}
        />
        <CardContent>
          {(isCreate || isEditing) && (
            <Typography sx={{ marginBottom: '16px', fontSize: '12px', color: '#263238' }}>
              {t('my_shop.legal_information_sub_title')}
            </Typography>
          )}
          <TextEditor
            ref={refTermsEditor}
            name="terms"
            errors={errors}
            control={control}
            isEditing={isCreate || isEditing}
            title={t('my_shop.terms_of_service')}
            placeholder={t('my_shop.terms_of_service')}
          />
          <TextEditor
            ref={refPolicyEditor}
            name="policy"
            errors={errors}
            control={control}
            isEditing={isCreate || isEditing}
            title={t('terms_of_use.privacy_policy')}
            placeholder={t('terms_of_use.privacy_policy')}
          />
          <TextEditor
            ref={refInforEditor}
            errors={errors}
            control={control}
            name="information"
            isEditing={isCreate || isEditing}
            title={t('my_shop.act_on_specified_commercial_transactions')}
            placeholder={t('my_shop.act_on_specified_commercial_transactions')}
          />
        </CardContent>
        <CardActions>
          {isCreate || isEditing ? (
            <>
              <Button variant="outlined" color="primary" onClick={handleCancel} disabled={isSubmitting || submitting}>
                {t(isCreate ? 'back' : 'cancel')}
              </Button>
              <Button
                variant="contained"
                disabled={(!isCreate && (!isDirty || isSubmitting)) || submitting}
                onClick={handleSubmit(onSubmit)}
                endIcon={(isSubmitting || submitting) && <CircularProgress size={20} color="inherit" />}
              >
                {t(isCreate ? 'create' : 'save')}
              </Button>
            </>
          ) : (
            <Button variant="contained" onClick={handleChangeToEdit}>
              {t('edit')}
            </Button>
          )}
        </CardActions>
      </Card>
    </Box>
  );
};

const TextEditor = forwardRef<
  ReactQuill,
  {
    title: string;
    isEditing: boolean;
    placeholder: string;
    name: 'policy' | 'terms' | 'information';
    control: Control<FormLegalInformationValues, any>;
    errors: Partial<
      FieldErrorsImpl<{
        policy: string;
        terms: string;
        information: string;
      }>
    >;
  }
>(({ name, title, errors, control, isEditing, placeholder }, ref) => {
  const { t } = useTranslation();
  useValidLinkQuill(ref);

  const handleChange = (callback: (value: string) => void) => (value: string) => {
    let newValue = value;
    if (value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
      newValue = '';
    }
    callback(newValue);
  };

  return (
    <Box className="wrapperTextEditor">
      <Typography variant="subtitle2">
        {title}
        {isEditing ? ' *' : ''}
      </Typography>
      <Controller
        name={name}
        control={control}
        render={({ field }) =>
          isEditing ? (
            <ReactQuill
              ref={ref}
              theme="snow"
              modules={{
                toolbar: toolbarOptions,
              }}
              formats={formats}
              value={field.value || ''}
              placeholder={placeholder}
              bounds=".wrapperTextEditor"
              onChange={handleChange(field.onChange)}
            />
          ) : (
            <Box className="ql-editor" dangerouslySetInnerHTML={{ __html: field.value }} />
          )
        }
      />
      {!!errors?.[name]?.message && (
        <Typography variant="caption" color="error">
          {getErrorText(errors?.[name]?.message || '', t)}
        </Typography>
      )}
    </Box>
  );
});

export default LegalInformation;
