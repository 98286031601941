import { FC, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import {
  GridCsvExportMenuItem,
  GridExportMenuItemProps,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { useGridApiContext } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { ReactComponent as GridIcon } from '../../icons/images/gallery-thumbnail.svg';
import { ReactComponent as SegmentIcon } from '../../icons/images/list-thumbnail.svg';

import { VIEW_MODE } from '~/enum/common';
import { IGridToolbar, MenuItemProps } from '~/interfaces/common';

const initModes = [
  {
    title: 'List',
    value: VIEW_MODE.LIST,
    icon: (
      <SegmentIcon
        width={25}
        height={25}
        filter="invert(37%) sepia(46%) saturate(1522%) hue-rotate(178deg) brightness(99%) contrast(94%)"
      />
    ),
  },
  {
    title: 'Gallery',
    value: VIEW_MODE.GALLERY,
    icon: (
      <GridIcon
        width={25}
        height={25}
        filter="invert(37%) sepia(46%) saturate(1522%) hue-rotate(178deg) brightness(99%) contrast(94%)"
      />
    ),
  },
];

const useStyles = makeStyles()((theme) => ({
  wrapperToolbar: {
    justifyContent: 'space-between',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
  },
  leftSide: {
    display: 'flex',
    alignItems: 'center',
  },
  rightSide: {
    gap: '8px',
    display: 'flex',
    alignItems: 'start',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      justifyContent: 'space-between',
    },
    '& .MuiInputBase-root.MuiInput-root': {
      lineHeight: '36.5px',
      '& .MuiInputBase-input': {
        height: '36.5px',
        boxSizing: 'border-box',
      },
    },
  },
}));

const ExportAllCsvMenuItem = (props: GridExportMenuItemProps<{ fileName?: string; getAll?: () => Promise<any[]> }>) => {
  const { t } = useTranslation();
  const apiRef = useGridApiContext();

  const { hideMenu, options } = props;
  const columnsList = apiRef.current.getVisibleColumns();

  const exportCsv = async () => {
    if (!options) {
      return;
    }
    const data = await options.getAll?.();
    if (!data) {
      return;
    }
    const exportedColumns = columnsList.reduce((result, column) => {
      if (!column.disableExport) {
        result[column.field] = column.headerName || '';
      }
      return result;
    }, {} as { [key: string]: string });
    const titles = Object.values(exportedColumns) || [];
    if (!(titles.length > 0)) {
      return;
    }
    const results = data.reduce(
      (result: any, item: any) => {
        result.push(Object.keys(exportedColumns)?.map((field) => item[field]));
        return result;
      },
      [titles]
    );
    let csvContent = '';
    results.forEach((row: any[]) => {
      row.forEach((value) => {
        csvContent += '"' + value + '",';
      });
      csvContent += '\n';
    });
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8,' });
    const objUrl = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.setAttribute('href', objUrl);
    link.setAttribute('download', options.fileName + '.csv');
    link.click();
    link.remove();
  };

  return (
    <MenuItem
      onClick={() => {
        exportCsv();
        // Hide the export menu after the export
        hideMenu?.();
      }}
    >
      {t('member_site.download_as_csv_all')}
    </MenuItem>
  );
};

const CustomGridToolbarSearchByAPI: FC<IGridToolbar> = (props) => {
  const { csvOptions, ...others } = props;
  const { classes } = useStyles();

  return (
    <GridToolbarContainer className={classes.wrapperToolbar}>
      <Box className={classes.leftSide}>
        <GridToolbarColumnsButton sx={{ padding: '0 5px' }} />
        {csvOptions && (
          <GridToolbarExportContainer>
            <GridCsvExportMenuItem options={csvOptions} />
            {!!csvOptions.getAll && <ExportAllCsvMenuItem options={csvOptions} />}
          </GridToolbarExportContainer>
        )}
      </Box>
      <Box className={classes.rightSide}>
        <RightSide {...others} />
      </Box>
    </GridToolbarContainer>
  );
};

export const RightSide: FC<IGridToolbar> = ({
  mode,
  search,
  selector,
  menuItems,
  searchLabel,
  isAttachingCollection,
  handleMode,
  handleSearch,
}) => {
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClickMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  return (
    <>
      {!!selector && (
        <FormControl sx={{ minWidth: 120 }} size="small">
          <Select
            id="selector"
            sx={{ height: 38 }}
            value={selector.value}
            label={selector.label}
            onChange={selector.onChange}
          >
            {selector.options.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {!!handleSearch && (
        <TextField
          variant="standard"
          id="standard-basic"
          value={search ?? ''}
          disabled={isAttachingCollection}
          placeholder={searchLabel || t('search')}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchOutlinedIcon />
              </InputAdornment>
            ),
          }}
        />
      )}
      {typeof mode === 'string' &&
        !!handleMode &&
        initModes.map((item) => (
          <Button
            key={item.value}
            sx={{ backgroundColor: mode === item.value ? '#E8F0F9' : 'transparent', minWidth: 0, padding: '0 12px' }}
            onClick={() => handleMode(item.value)}
          >
            {item.icon}
          </Button>
        ))}
      {!!menuItems?.length && (
        <>
          <IconButton onClick={handleClickMenu}>
            <MoreVertIcon fontSize="inherit" />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleCloseMenu}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            {menuItems?.map((item: MenuItemProps, index) => {
              return (
                <MenuItem
                  key={index}
                  onClick={() => {
                    setAnchorEl(null);
                    item?.handleMenuFunction();
                  }}
                >
                  {item.menuTitle}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
};

export default CustomGridToolbarSearchByAPI;
