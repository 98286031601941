import { FC, MouseEventHandler } from 'react';

import Box from '@mui/material/Box';
import TablePagination from '@mui/material/TablePagination';
import Typography from '@mui/material/Typography';
import { GridPaginationModel } from '@mui/x-data-grid-pro';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCard from '../CustomCard';
import LoaderCenter from '../loader-center';

export interface GalleryData {
  uuid?: string;
  price?: number;
  tokenId?: string;
  networkSymbol?: string;
  id: string | null | undefined;
  url: string | null | undefined;
  name: string | null | undefined;
  domain?: string | null | undefined;
  status?: string | null | undefined;
  maxMint?: number | null | undefined;
  currentMint?: number | null | undefined;
}

interface GalleryListProps {
  loading?: boolean;
  notSquare: boolean;
  totalItems: number;
  className?: string;
  data: GalleryData[];
  rowsPerPage: number;
  isSearching?: boolean;
  noRowsMessage?: string;
  paginationModel: GridPaginationModel;
  isMenu?: boolean;
  isShopScreen?: boolean;
  isCollectionScreen?: boolean;
  rowsPerPageOptions?: number[];
  components?: {
    Header: JSX.Element | null | undefined;
  };
  onClickMenu?: (info: GalleryData) => MouseEventHandler<HTMLButtonElement>;
  componentsProps: {
    Card: {
      onClick: (item: GalleryData) => void;
    };
  };
  onPaginationModelChange: (model: GridPaginationModel) => Promise<void>;
}

const useStyles = makeStyles<{ notSquare: boolean }>()((theme, { notSquare }) => {
  const md = theme.breakpoints.up('md');
  const xs = theme.breakpoints.up('xs');
  return {
    wrapper: {
      display: 'flex',
      borderRadius: 8,
      flexDirection: 'column',
      border: '1px solid rgba(21, 21, 21, 0.15)',
      '.MuiTablePagination-root': {
        padding: '0 8px!important',
        [theme.breakpoints.up('sm')]: {
          padding: '0 16px!important',
        },
      },
    },
    grid: {
      flex: 1,
      gap: '16px',
      padding: 16,
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fill, minmax(${notSquare ? '300px' : '140px'}, 1fr))`,
      ...(notSquare
        ? {
            [xs]: {
              gridTemplateColumns: `repeat(auto-fill, minmax(180px, 1fr))`,
            },
          }
        : {}),
      [md]: {
        gridTemplateColumns: `repeat(auto-fill, minmax(${notSquare ? '240px' : '160px'}, 1fr))`,
      },
    },
    pagination: {
      borderTop: '1px solid rgba(0, 0, 0, 0.12)',
    },
    noData: {
      fontSize: 14,
      fontWeight: 500,
      lineHeight: '32px',
      textAlign: 'center',
    },
  };
});

const GalleryList: FC<GalleryListProps> = ({
  data,
  loading,
  isMenu,
  notSquare,
  className,
  totalItems,
  components,
  rowsPerPage,
  isSearching,
  isShopScreen,
  noRowsMessage,
  paginationModel,
  componentsProps,
  isCollectionScreen,
  onClickMenu,
  onPaginationModelChange,
}) => {
  const { t } = useTranslation();
  const { classes } = useStyles({ notSquare });

  const handleChangePage = (_: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    onPaginationModelChange({ page: newPage, pageSize: paginationModel.pageSize });
  };

  const onClickCard = (item: GalleryData) => () => {
    if (!!componentsProps?.Card?.onClick) {
      componentsProps?.Card?.onClick(item);
    }
  };

  return (
    <Box className={`${classes.wrapper} ${className || ''}`}>
      {components?.Header}
      {loading ? (
        <LoaderCenter sx={{ flex: 1, minHeight: '200px' }} />
      ) : data?.length > 0 ? (
        <Box className={classes.grid}>
          {data.map((item) => (
            <Box key={item.id}>
              <CustomCard
                data={{
                  price: item.price,
                  id: item.id || '',
                  url: item.url || '',
                  name: item.name || '',
                  tokenId: item.tokenId,
                  status: item.status || '',
                  description: item.domain || '',
                  networkSymbol: item.networkSymbol,
                }}
                onClick={onClickCard(item)}
                onClickMenu={(event) => {
                  if (onClickMenu) {
                    onClickMenu(item)(event);
                  }
                }}
                isMenu={isMenu}
                isShopScreen={isShopScreen}
                isCollectionScreen={isCollectionScreen}
              />
            </Box>
          ))}
        </Box>
      ) : (
        <Box minHeight="200px" display="flex" alignItems="center" justifyContent="center" padding="16px">
          <Typography className={classes.noData}>
            {isSearching ? t('no_data_available') : noRowsMessage || t('my_shop.message.no_collection')}
          </Typography>
        </Box>
      )}
      <TablePagination
        component="div"
        count={totalItems || 0}
        rowsPerPage={rowsPerPage}
        page={paginationModel.page}
        className={classes.pagination}
        rowsPerPageOptions={[rowsPerPage]}
        onPageChange={handleChangePage}
      />
    </Box>
  );
};

export default GalleryList;
