import Stack from '@mui/material/Stack';

import Balance from './Balance';
import BalanceFluctuation from './BalanceFluctuation';

const Points = () => {
  return (
    <Stack gap="16px">
      <Balance />
      <BalanceFluctuation />
    </Stack>
  );
};

export default Points;
