import { FC, ReactNode, useState } from 'react';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

interface HeaderActionProps {
  menus: Array<{
    title: string;
    onClick: () => void;
  }>;
  icon?: ReactNode;
}

const HeaderAction: FC<HeaderActionProps> = ({ menus, icon }) => {
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement>();

  const handleCloseMenu = () => {
    setMenuAnchorEl(undefined);
  };

  const handleClick = (callback: () => void) => () => {
    handleCloseMenu();
    callback();
  };

  return (
    <>
      <IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>{icon || <MoreVertIcon />}</IconButton>
      <Menu id="basic-menu" open={!!menuAnchorEl} anchorEl={menuAnchorEl} onClose={handleCloseMenu}>
        {menus.map((menu) => (
          <MenuItem key={menu.title} onClick={handleClick(menu.onClick)}>
            {menu.title}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default HeaderAction;
