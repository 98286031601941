import { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { Controller, useForm, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import { ReactComponent as FincodeLogo } from '../../../icons/images/fincode_logo.svg';
import FincodeSetupGuide from '../FincodeSetupGuide';

import CustomCardTable from '~/components/custom-card-table';
import { SCREEN_PERMISSION } from '~/config/roleConfig';
import { GetMeDocument, useUpdateOrganizationMutation } from '~/graphql/member/types';
import { useNotify } from '~/hooks/useNotify';
import { useAccount, useCheckPermissions } from '~/hooks/with-account';

const useStyles = makeStyles()(() => ({
  wrapper: {
    paddingBottom: '16px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  title: {
    padding: 24,
    color: '#111',
    fontWeight: 700,
    fontSize: '20px',
    lineHeight: '20px',
    letterSpacing: '0.1px',
  },
  settingLabel: {
    fontWeight: 500,
    fontSize: '14px',
    marginLeft: '10px',
    lineHeight: '32px',
    color: 'rgba(0, 0, 0, 0.87)',
  },
}));

const urlRegex = /https:\/\/(www.)?[A-Za-z0-9]+(\.[A-Za-z]{2,}){1,3}\/?[^\s]*$/;

const schema = yup.object({
  requiredAcceptTerms: yup.boolean(),
  termsUrl: yup.string().when('requiredAcceptTerms', {
    is: true,
    then: (schema) => schema.matches(urlRegex, { message: 'form_validation.invalid_value_entered' }),
  }),
  policyUrl: yup.string().when('requiredAcceptTerms', {
    is: true,
    then: (schema) => schema.matches(urlRegex, { message: 'form_validation.invalid_value_entered' }),
  }),
  secretKey: yup.string().required(),
  publicKey: yup.string().required(),
});

export interface FormPaymentMethodsValues extends yup.InferType<typeof schema> {}

const Payment = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { showError, showSuccess } = useNotify();

  const [passwordVisibility, setPasswordVisibility] = useState<{
    secretKey: boolean;
    publicKey: boolean;
  }>({
    secretKey: false,
    publicKey: false,
  } as const);

  const { selectedOrganization } = useAccount();

  const [editable] = useCheckPermissions([SCREEN_PERMISSION.SETTING.PAYMENT.EDIT]);

  const [updateOrganizationMutation] = useUpdateOrganizationMutation({ refetchQueries: [GetMeDocument] });

  const defaultValues = useMemo(
    () => ({
      termsUrl: selectedOrganization?.paymentSetting?.termsUrl || '',
      policyUrl: selectedOrganization?.paymentSetting?.policyUrl || '',
      publicKey: selectedOrganization?.paymentSetting?.publicKey || '',
      secretKey: selectedOrganization?.paymentSetting?.secretKey || '',
      requiredAcceptTerms: !!selectedOrganization?.paymentSetting?.requiredAcceptTerms,
    }),
    [selectedOrganization]
  );

  const {
    control,
    handleSubmit,
    getValues,
    reset,
    formState: { errors, isSubmitting, dirtyFields },
  } = useForm<FormPaymentMethodsValues>({
    mode: 'onChange',
    reValidateMode: 'onSubmit',
    criteriaMode: 'firstError',
    defaultValues,
    resolver: yupResolver(schema),
  });

  const requiredAcceptTerms = useWatch({
    control,
    name: 'requiredAcceptTerms',
  });
  const isDirtyPayment = !!Object.keys(dirtyFields).length;

  useEffect(() => {
    const value = getValues();
    if (JSON.stringify(value) !== JSON.stringify(defaultValues)) reset(defaultValues);
  }, [defaultValues, getValues, reset]);

  const handleToggle = (field: 'secretKey' | 'publicKey') => {
    setPasswordVisibility((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const onSubmit = async (payload: FormPaymentMethodsValues) => {
    try {
      await updateOrganizationMutation({
        variables: {
          input: { paymentSetting: payload },
        },
      });
      reset({ ...payload, publicKey: payload.publicKey, secretKey: payload.secretKey });
      showSuccess('my_shop.message.update_successful');
    } catch (err: any) {
      showError(err);
    }
  };
  return (
    <CustomCardTable
      cardTitle={t('my_shop.payment_methods.payment')}
      cardContent={
        <Box className={classes.wrapper}>
          <Grid container rowGap={2} marginBottom="16px">
            <Box className={classes.header}>
              <FincodeLogo />
              <Typography className={classes.settingLabel}>{t('settings.fincode_setting')}</Typography>
            </Box>
          </Grid>
          <Grid container spacing="16px">
            <Grid item xs={12}>
              <Controller
                name="secretKey"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    disabled={!editable || isSubmitting}
                    type={passwordVisibility.secretKey ? 'text' : 'password'}
                    variant="outlined"
                    label={t('my_shop.payment_methods.secret_key')}
                    error={!!errors.secretKey?.message}
                    helperText={t(errors.secretKey?.message as any)}
                    {...field}
                    inputProps={{
                      autoComplete: 'new-password',
                      form: { autoComplete: 'off' },
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={!editable} onClick={() => handleToggle('secretKey')}>
                            {passwordVisibility.secretKey ? (
                              <RemoveRedEyeOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="publicKey"
                control={control}
                render={({ field }) => (
                  <TextField
                    required
                    fullWidth
                    disabled={!editable || isSubmitting}
                    type={passwordVisibility.publicKey ? 'text' : 'password'}
                    variant="outlined"
                    label={t('my_shop.payment_methods.public_key')}
                    error={!!errors.publicKey?.message}
                    helperText={t(errors.publicKey?.message as any)}
                    {...field}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton disabled={!editable} onClick={() => handleToggle('publicKey')}>
                            {passwordVisibility.publicKey ? (
                              <RemoveRedEyeOutlinedIcon />
                            ) : (
                              <VisibilityOffOutlinedIcon />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="requiredAcceptTerms"
                control={control}
                render={({ field }) => (
                  <FormControlLabel
                    disabled={!editable || isSubmitting}
                    control={<Checkbox checked={field.value} {...field} />}
                    label={t('my_shop.payment_methods.confirm_terms_and_privacy')}
                  />
                )}
              />
            </Grid>
            {requiredAcceptTerms && (
              <>
                <Grid item xs={12}>
                  <Controller
                    name="termsUrl"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        disabled={!editable || isSubmitting}
                        placeholder="https://domain.com"
                        error={!!errors.termsUrl?.message}
                        label={t('my_shop.payment_methods.terms_url')}
                        helperText={t(errors.termsUrl?.message as any)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Controller
                    name="policyUrl"
                    control={control}
                    render={({ field }) => (
                      <TextField
                        fullWidth
                        variant="outlined"
                        disabled={!editable || isSubmitting}
                        placeholder="https://domain.com"
                        error={!!errors.policyUrl?.message}
                        label={t('my_shop.payment_methods.policy_url')}
                        helperText={t(errors.policyUrl?.message as any)}
                        {...field}
                      />
                    )}
                  />
                </Grid>
              </>
            )}
          </Grid>
          <FincodeSetupGuide />
          <Box sx={{ display: 'flex', justifyContent: 'right', width: '100%' }}>
            <Button
              variant="contained"
              disabled={!editable || isSubmitting || !isDirtyPayment}
              endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
              onClick={handleSubmit(onSubmit)}
            >
              {t('update')}
            </Button>
          </Box>
        </Box>
      }
    />
  );
};

export default Payment;
